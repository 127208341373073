let data = {
    "srcWellId": "ee67723a-90cd-4091-8d5b-e75bffc40cc0",
    "srcWellBoreId": "ee67723a-90cd-4091-8d5b-e75bffc40cc0",
    "type": "depth",
    "logTypeId": "Depth1",
    "id": "ee67723a-90cd-4091-8d5b-e75bffc40cc0",
    "table": "depth",
    "startDepth": 6435,
    "endDepth": 19814,
    "topicname": "PNRG_Main_W_29_TYRANCHWEST2JM_Depth1",
    "swab_surge_topicname": null,
    "time_zone": "CST6CDT",
    "tags": [
        "sl_res",
        "ad rop",
        "ann_pressure",
        "azimuth",
        "accum_trip_in",
        "accum_trip_out",
        "gas_acetyl",
        "activity",
        "activitycode",
        "air pressure",
        "binghamplasticmodelannularpressureloss",
        "ql_annular_velocity",
        "annularvolume",
        "average joint length",
        "btvd",
        "dbtm",
        "bit rpm",
        "bitsize",
        "bpos",
        "back pressure",
        "basic mse",
        "bhalength",
        "bitpressuredrop",
        "bit_on_btm",
        "bit_dpt_md",
        "bit tvd - wits",
        "hrs_on_bit",
        "block_speed",
        "bttm ann press",
        "anl_tmp",
        "bttm pipe temp",
        "chdc1annularstrokes",
        "chdc1annularvolume",
        "chdc2annularstrokes",
        "chdc2annularvolume",
        "chdpannularstrokes",
        "chdpannularvolume",
        "chhwdpannularstrokes",
        "chhwdpannularvolume",
        "casedholevolume",
        "cirh",
        "gas_co2",
        "casingdc1annularcapacity",
        "casingdc1length",
        "casingdc2annularcapacity",
        "casingdc2length",
        "casingdpannularcapacity",
        "casingdplength",
        "casinghwdpannularcapacity",
        "casinghwdplength",
        "casingid",
        "casinglength",
        "casingod",
        "casingtvd",
        "choke_posn",
        "choke position 2",
        "gas_c1",
        "gas_c3",
        "co. man g/l",
        "consistencyindex",
        "cont az",
        "cont inc",
        "continuous azimuth",
        "continuous inclination",
        "counter a",
        "counter b",
        "drillcuttingconcentration",
        "cyclecount",
        "cycletime",
        "cyclevolume",
        "cyclestrokes",
        "daq version",
        "dba 3d tortuosity index",
        "dba 3d tortuosity index n feet",
        "dba abnormal gain belief",
        "dba abnormal loss belief",
        "dba active pit change volume",
        "dba agg diff press spike index",
        "dba annular press bit est",
        "dba azimuthal tortuosity index",
        "dba bha run number",
        "dba bit depth est",
        "dba bit depth fault alert",
        "dba bit depth fault code",
        "dba bit position heave comp",
        "dba bit rpm est",
        "dba bit wear factor",
        "dba bit weight autozero",
        "dba bit weight est",
        "dba bit weight fault alert",
        "dba bit weight fault code",
        "dba bit weight min hel buckling",
        "dba block height est",
        "dba block height fault alert",
        "dba block height fault code",
        "dba block weight calibrated",
        "dba bottom to surface strokes",
        "dba build rate",
        "dba casing annulus volume",
        "dba casing run number",
        "dba cleansed rop",
        "dba dls",
        "dba dls cumulative",
        "dba delta flow est",
        "dba depth of cut est",
        "dba diff press est",
        "dba diff press spike belief",
        "dba downhole bit aggressiveness",
        "dba downlink belief",
        "dba drilling loss gain volume",
        "dba drillstring drag",
        "dba drillstring torque",
        "dba drillstring volume",
        "dba ecd bit est",
        "dba ecd shoe est",
        "dba ew",
        "dba flag active pit change",
        "dba flag connection flowback",
        "dba flag mud addition",
        "dba flag mud removal",
        "dba flag pump activity",
        "dba flow in rate critical est",
        "dba flow in rate est",
        "dba flow in rate fault alert",
        "dba flow in rate fault code",
        "dba flow out rate est",
        "dba flow out rate fault alert",
        "dba flow out rate fault code",
        "dba frequent tight spots belief",
        "dba gain loss rate",
        "dba gain loss volume",
        "dba hif",
        "dba hsi",
        "dba heartbeat",
        "dba heave compensator amplitude",
        "dba heave compensator period",
        "dba high hole clean eff belief",
        "dba hole depth est",
        "dba hole depth fault alert",
        "dba hole depth fault code",
        "dba hole section",
        "dba hook load est",
        "dba hook load max fault alert",
        "dba hook load max fault code",
        "dba hook load modeled",
        "dba hyd coeff flow in",
        "dba hyd coeff flow out",
        "dba inclination tortuosity index",
        "dba mse motor",
        "dba mse surface",
        "dba mse total",
        "dba mud addition belief",
        "dba mud addition removal volume",
        "dba mud motor torque",
        "dba mud removal belief",
        "dba mud report mud density",
        "dba mud volume connection delta",
        "dba mud volume total est",
        "dba mud volume total fault alert",
        "dba mud volume total fault code",
        "dba ns",
        "dba open hole annulus volume",
        "dba open hole ff",
        "dba overpull amount",
        "dba overpull belief",
        "dba packoff belief",
        "dba pipe rock percentage",
        "dba pipe rock period",
        "dba pipe rock rpm",
        "dba pump activity volume",
        "dba pump failure belief",
        "dba pump press est",
        "dba pump press fault code",
        "dba pump start belief",
        "dba pump stop belief",
        "dba rop cut depth",
        "dba rop instantaneous",
        "dba rigstate",
        "dba rotary bit balling belief",
        "dba rotary bit bounce belief",
        "dba rotary bit stick slip belief",
        "dba rotary cone angle",
        "dba rotary cone offset angle",
        "dba rotary cone radius",
        "dba rotary cone x value",
        "dba rotary cone y value",
        "dba rotary drilling rec",
        "dba rotary opt drill index avg",
        "dba rotary string stick slip belief",
        "dba rotary tot stick slip belief",
        "dba rotary whirl belief",
        "dba slide bit bounce belief",
        "dba slide buckling belief",
        "dba slide cone angle",
        "dba slide cone offset angle",
        "dba slide cone radius",
        "dba slide cone x value",
        "dba slide cone y value",
        "dba slide drilling rec",
        "dba slide high friction belief",
        "dba slide opt drilling index",
        "dba slide poor toolface belief",
        "dba slide stick slip belief",
        "dba standpipe press fault alert",
        "dba static well belief",
        "dba stick slip amp est",
        "dba string torque autozero",
        "dba string weight est",
        "dba subopt bit hydraulics belief",
        "dba suff circ belief",
        "dba suff circ no drilling belief",
        "dba suff pipe rotation belief",
        "dba suff pipe working belief",
        "dba surface bit aggressiveness",
        "dba surface horsepower",
        "dba surface to bit strokes",
        "dba surface to shoe strokes",
        "dba sweep 1 stroke counter",
        "dba sweep 2 stroke counter",
        "dba sweep 3 stroke counter",
        "dba sweep 4 stroke counter",
        "dba tob modeled",
        "dba tvd",
        "dba tvd bit",
        "dba tnd depth",
        "dba tnd ecd bit",
        "dba tnd ecd shoe",
        "dba tnd flow in rate",
        "dba tnd free rotating ff",
        "dba tnd free rotating torque",
        "dba tnd free rotating weight",
        "dba tnd hookload deviation",
        "dba tnd hookload reference",
        "dba tnd overpull belief",
        "dba tnd pick up ff",
        "dba tnd pick up weight",
        "dba tnd slack off ff",
        "dba tnd slack off weight",
        "dba tnd standpipe press",
        "dba tnd top drive rpm",
        "dba tnd top drive torque",
        "dba tnd trip speed",
        "dba tnd underpull belief",
        "dba toolface eff index",
        "dba top drive rpm est",
        "dba top drive rpm fault alert",
        "dba top drive rpm fault code",
        "dba top drive torque est",
        "dba top drive torque fault alert",
        "dba top drive torque fault code",
        "dba trip speed",
        "dba underpull amount",
        "dba underpull belief",
        "dba underpull overpull amount",
        "dba wob modeled",
        "dba walk rate",
        "dba washout belief",
        "dba washout pump fail belief sum",
        "dc1annularcapacity",
        "dc1annularstrokes",
        "dc1annularvolume",
        "dc1capacity",
        "dc1id",
        "dc1length",
        "dc1od",
        "dc1strokes",
        "dc1volume",
        "dc2annularcapacity",
        "dc2annularstrokes",
        "dc2annularvolume",
        "dc2capacity",
        "dc2id",
        "dc2length",
        "dc2od",
        "dc2strokes",
        "dc2volume",
        "diff",
        "dls",
        "dpannularcapacity",
        "dpannularstrokes",
        "dpannularvolume",
        "dpcapacity",
        "dpid",
        "dplength",
        "dpod",
        "dpstrokes",
        "dpvolume",
        "daily_ton_mile",
        "dog leg severity - wits",
        "drillstringvolume",
        "drilling trigger hkld",
        "ecdatbitwithbinghamplasticmodel",
        "ecdatcasingwithbinghamplasticmodel",
        "edms counts",
        "east west horizontal",
        "ecdatcasingwithpowerlawmodel",
        "ecdatbitwithpowerlawmodel",
        "gas_c2",
        "fd heartbeat",
        "fd pump stability",
        "fd svy azimuth",
        "fd svy date",
        "fd svy depth",
        "fd svy inclination",
        "fd svy quality",
        "dateandtime",
        "flow_out",
        "flow_deviation",
        "flowindex",
        "s_flow_out",
        "flow pressure",
        "flow temp",
        "g/l rate - 1 min",
        "g/l rate - 5 min",
        "gamma_ray",
        "gtf",
        "gw air flow",
        "gw balance ratio",
        "gw blowback",
        "gw blowbackon/off",
        "gw char ratio",
        "gw ethane (c2)",
        "gw iso-butane (ic4)",
        "gw iso-pentane (ic5)",
        "gw methane (c1)",
        "gw nor-butane (nc4)",
        "gw nor-pentane (nc5)",
        "gw propane (c3)",
        "gw pump shutdown",
        "gw status",
        "gw total gas",
        "gw vacuum",
        "gw vacuum max",
        "gw wet ratio",
        "gwex agitatorflow",
        "gwex agitatorflowsetpoint",
        "gwex agitatorpressure",
        "gwex agitatorrate",
        "gwex blowbacksetpoint",
        "gwex command",
        "gwex faultmode",
        "gwex h2s",
        "gwex h2salarm",
        "gwex methane",
        "gwex propane",
        "gwex rigairpressure",
        "gwex sampleflow",
        "gwex sampleflowsetpoint",
        "gwex samplevacuum",
        "gwex systemmode",
        "gwex temperature",
        "gwex totalgas",
        "gainloss",
        "gain loss - spare",
        "gamma_ray_depth",
        "gamma_ray_depth_adjusted",
        "gas fault",
        "gas flow rate",
        "gas injection",
        "gear in use",
        "gas_h2s_max",
        "bithydraulichorsepower",
        "dmea",
        "hkl",
        "horsepowerperinch",
        "hwdpannularcapacity",
        "hwdpannularstrokes",
        "hwdpannularvolume",
        "hwdpcapacity",
        "hwdpid",
        "hwdplength",
        "hwdpod",
        "hwdpstrokes",
        "hwdpvolume",
        "hole depth last 24hrs",
        "horn alarm state",
        "hydrostaticpressure",
        "inclination",
        "nozzleimpactforce",
        "gas_iso-c4",
        "iso-hexane",
        "gas_iso-c5",
        "joints per stand",
        "kz base mse",
        "kz bit bounce",
        "kz bit mse",
        "kz cpu overload count",
        "kz command delta p",
        "kz command rpm",
        "kz command swob",
        "kz command toolface grav",
        "kz command toolface mag",
        "kz communication resets",
        "kz corrected delta p",
        "kz delta p correction status",
        "kz delta p/swob focus",
        "kz depth of cut",
        "kz distance to plan",
        "kz downhole model status",
        "kz dynamic window",
        "kz dysfunction indicator",
        "kz engine heartbeat",
        "kz engine in control",
        "kz engine status",
        "kz exploration percent",
        "kz exploration progress",
        "kz mse scatter",
        "kz max dwob",
        "kz max delta p",
        "kz max delta p step",
        "kz max rop",
        "kz max rpm",
        "kz max rpm step",
        "kz max swob",
        "kz max swob based on stress",
        "kz max swob step",
        "kz max torque",
        "kz min delta p",
        "kz min rpm",
        "kz min swob",
        "kz optimum delta p",
        "kz optimum rpm",
        "kz optimum swob",
        "kz response tuning",
        "kz soft speed status",
        "kz static data health",
        "kz stick slip level",
        "kz surface axial osc",
        "kz surface torsional osc",
        "kz twister status",
        "kz whirl level",
        "kz whirl magnitude",
        "kz whirl velocity",
        "kelly down",
        "lag_dpt_md",
        "lagstrokes",
        "lagtime",
        "ton_mile_24hr",
        "liquid flow rate",
        "liquid injection",
        "loop in control",
        "lst elem len",
        "mpd choke pressure",
        "mpd flow out",
        "mpd gas flow",
        "mpd rcd pressure",
        "mse downhole",
        "mse total",
        "mtf",
        "mfi",
        "tv1",
        "tv10",
        "tv11",
        "tv12",
        "tv13",
        "tv14",
        "tv2",
        "tv3",
        "tv4",
        "tv5",
        "tv6",
        "tv7",
        "tv8",
        "tv9",
        "mud density",
        "mud density out",
        "methane (gw)",
        "mud conductivity in",
        "mud conductivity out",
        "mud motor max diff pressure",
        "mud motor max torque",
        "mud motor rpm",
        "mud motor rpm factor",
        "mud motor torque",
        "mud motor torque factor",
        "n2 out",
        "n2 pressure in",
        "gas_neo-c5",
        "gas_nor-c4",
        "nor-hexane",
        "gas_nor-c5",
        "north south horizontal",
        "nozzlevelocity",
        "ohdc1annularcapacity",
        "ohdc1annularstrokes",
        "ohdc1annularvolume",
        "ohdc2annularcapacity",
        "ohdc2annularstrokes",
        "ohdc2annularvolume",
        "ohdpannularcapacity",
        "ohdpannularstrokes",
        "ohdpannularvolume",
        "ohhwdpannularcapacity",
        "ohhwdpannularstrokes",
        "ohhwdpannularvolume",
        "openholevolume",
        "op",
        "openholedc1length",
        "openholedc2length",
        "openholedplength",
        "openholehwdplength",
        "openholelength",
        "optimizedrop",
        "oscillator state",
        "cuttingtransportefficiency",
        "oxygen",
        "ts1",
        "ts2",
        "ts3",
        "pipe id",
        "pipe length",
        "pipe length change",
        "pipe od",
        "pipe rpm",
        "pipe torque",
        "pitgain",
        "plasticviscosity",
        "powerlawmodelannularpressureloss",
        "powerlawmodeldrillstringpressureloss",
        "powerlawmodelstandpipepressureloss",
        "prev day wear",
        "propane (gw)",
        "pump displacement 1",
        "pump displacement 2",
        "pump displacement 3",
        "pump efficiency 1",
        "pump efficiency 2",
        "pump liner 1",
        "pump liner 2",
        "pump liner 3",
        "pumpoutput",
        "pump stroke length 1",
        "pump stroke length 2",
        "pump stroke length 3",
        "pump type 1",
        "pump type 2",
        "pump type 3",
        "pumpedvolume",
        "ql depth delay",
        "ql returns depth",
        "ql start depth",
        "ql stks bt up",
        "ql strokes to surface",
        "ql_tm_to_surf",
        "r/t gear",
        "r600",
        "rop",
        "avg_rop_ft_hr",
        "cut_foot_rop_ft_hr",
        "fast_rop_ft_hr_depth",
        "rpm",
        "remote gain loss alarm",
        "ql_fast_ret_depth",
        "rig_activity",
        "iadc_rig_activity",
        "iadc_rig_activity3",
        "iadc_rig_activity2",
        "rig activity version",
        "rig air press",
        "riservolume",
        "rot_speed",
        "rot_torque",
        "rotary torque factor",
        "sdaq status",
        "slips_stat",
        "slugweight",
        "spm1",
        "spm2",
        "spm3",
        "spp",
        "svy_e_w_position",
        "svy_n_s_position",
        "svy_walk_rate",
        "simplifiedmse",
        "skim flow",
        "slip switch",
        "standpipe pressure 1",
        "standpipe pressure 2",
        "stick slip mitigator on",
        "string length",
        "binghamplasticmodeldrillstringpressureloss",
        "string_speed",
        "stringstrokes",
        "string_torque",
        "string_weight",
        "acc_drill_strks",
        "acc_fill_strks",
        "fill_strokes",
        "strokes 1a",
        "strokes 1b",
        "strokes 2a",
        "strokes 2b",
        "strokes 3a",
        "strokes 3b",
        "subsea tvd",
        "subsea_tvd_3rdparty",
        "surfacepressureloss",
        "surfacetobittime",
        "srv_md",
        "survey_number",
        "td/rt sel flag",
        "temp_in",
        "temp_out",
        "totalflowarea",
        "time",
        "tor",
        "tmv",
        "tot_stk",
        "tot_spm",
        "tt_vol",
        "total_gas3",
        "tt1_vol",
        "tt2_vol",
        "tvd",
        "tvd_3rdparty",
        "tvd - wits",
        "tong length",
        "tong torque",
        "tong torque lp",
        "toolface target",
        "alt_gravity_toolface",
        "toolface threshold",
        "top drv torque - converted",
        "torque diff",
        "gas_total",
        "total_gas_gw",
        "total gas normalized",
        "total gas out",
        "binghamplasticmodelstandpipepressureloss",
        "trigger hkld status",
        "trip_tank_gl",
        "tripping trigger hkld",
        "vslug",
        "wc a/d status",
        "wc active profile",
        "wc_drilling_mode_select",
        "wc bit bounce",
        "wc bit weight",
        "wc_diff_p_enabled",
        "wc drill stop point",
        "wc_drum_engaged",
        "wc_eads_enabled",
        "wc feed forward",
        "wc freq cmd",
        "wc incr distance",
        "wc len to drill",
        "wc lift unit threshold",
        "wc_control_param",
        "wc_motor_enable",
        "wc phoenix flag",
        "wc rop",
        "wc rop safety limit",
        "wc ream speed",
        "wc sensitivity",
        "wc status",
        "wc_target_wob",
        "wc_target_diff_press",
        "wc_target_rop",
        "wc_target_torque",
        "wc torque",
        "wc_torque_enable",
        "wc total drilled",
        "wc wob safety limit",
        "wob",
        "wpda - avg mse",
        "wpda - bit hhp",
        "wpda - dim torque",
        "wpda - hca heartbeat",
        "wpda - heartbeat",
        "wpda - mse",
        "wpda - pipe torq engr",
        "wpda - pseudo-mse",
        "wpda - ref ccs",
        "wpda - ref gamma",
        "wpda - ref mse",
        "wpda - status",
        "wpda - d exponent",
        "wsgd airflow",
        "wsgd gcib bd1 chnl1 te",
        "wsgd gcib bd2 chnl1 te",
        "wsgd_methane",
        "wsgd_propane",
        "wsgd pump shutdown",
        "wsgd total gas",
        "washout factor",
        "water out",
        "water pit",
        "yieldpoint",
        "r300"
    ],
    "units": [
        "",
        "ft/h",
        "psi",
        "dega",
        "bbl",
        "bbl",
        "ppm",
        "unitless",
        "unitless",
        "psi",
        "psi",
        "ft/min",
        "bbls",
        "ft",
        "ft",
        "ft",
        "rpm",
        "inch",
        "ft",
        "psi",
        "kpsi",
        "ft",
        "psi",
        null,
        "ft",
        "ft",
        "hr",
        "ft/s",
        "psi",
        "degF",
        "degF",
        "stks",
        "bbls",
        "stks",
        "bbls",
        "stks",
        "bbls",
        "stks",
        "bbls",
        "bbls",
        "hr",
        "ppm",
        "bbl/ft",
        "ft",
        "bbl/ft",
        "ft",
        "bbl/ft",
        "ft",
        "bbl/ft",
        "ft",
        "inch",
        "ft",
        "inch",
        "ft",
        "%",
        "%",
        "%",
        "%",
        "bbl",
        "unitless",
        "dega",
        "dega",
        "dega",
        "dega",
        "strokes",
        "strokes",
        "%",
        "X",
        "mins",
        "bbls",
        "stks",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "bbl",
        "unitless",
        "psi",
        "unitless",
        "unitless",
        "ft",
        "unitless",
        "unitless",
        "ft",
        "rpm",
        "unitless",
        "klbf",
        "klbf",
        "unitless",
        "unitless",
        "klbf",
        "ft",
        "unitless",
        "unitless",
        "klbf",
        "unitless",
        "deg/100ft",
        "bbl",
        "unitless",
        "ft/h",
        "deg/100ft",
        "dega",
        "galUS/min",
        "in",
        "psi",
        "unitless",
        "unitless",
        "unitless",
        "bbl",
        "klbf",
        "kft.lbf",
        "bbl",
        "lbf/galUS",
        "lbf/galUS",
        "ft",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "galUS/min",
        "galUS/min",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        null,
        "bbl",
        "lbf",
        "hhp/in2",
        "unitless",
        "ft",
        "s",
        "unitless",
        "ft",
        "unitless",
        "unitless",
        "unitless",
        "klbf",
        "unitless",
        "unitless",
        "klbf",
        "unitless",
        "unitless",
        "unitless",
        "psi",
        "psi",
        "psi",
        "unitless",
        "bbl",
        "kft.lbf",
        "unitless",
        "lbf/galUS",
        "bbl",
        "bbl",
        "unitless",
        "unitless",
        "ft",
        "bbl",
        "unitless",
        "klbf",
        "unitless",
        "unitless",
        "%",
        "s",
        "rpm",
        "bbl",
        "unitless",
        "psi",
        "unitless",
        "unitless",
        "unitless",
        "ft/h",
        "ft/h",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "dega",
        "dega",
        "unitless",
        "rpm",
        "klbf",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "dega",
        "dega",
        "unitless",
        "galUS/min",
        "psi",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "kft.lbf",
        "klbf",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        null,
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "kft.lbf",
        "ft",
        "ft",
        "ft",
        "lbf/galUS",
        "lbf/galUS",
        "galUS/min",
        "unitless",
        "kft.lbf",
        "klbf",
        "klbf",
        "klbf",
        "unitless",
        "unitless",
        "klbf",
        "unitless",
        "klbf",
        "psi",
        "rpm",
        "kft.lbf",
        "ft/min",
        "unitless",
        "unitless",
        "rpm",
        "unitless",
        "unitless",
        "kft.lbf",
        "unitless",
        "unitless",
        "ft/min",
        "klbf",
        "unitless",
        "klbf",
        "klbf",
        "deg/100ft",
        "unitless",
        "unitless",
        "bbl/ft",
        "stks",
        "bbls",
        "bbl/ft",
        "inch",
        "ft",
        "inch",
        "stks",
        "bbls",
        "bbl/ft",
        "stks",
        "bbls",
        "bbl/ft",
        "inch",
        "ft",
        "inch",
        "stks",
        "bbls",
        "psi",
        "deg/100ft",
        "bbl/ft",
        "stks",
        "bbls",
        "bbl/ft",
        "inch",
        "ft",
        "inch",
        "stks",
        "bbls",
        null,
        "deg/100ft",
        "bbls",
        "klbf",
        "lbf/galUS",
        "lbf/galUS",
        "EDMS Counts",
        "ft",
        "lbf/galUS",
        "lbf/galUS",
        "ppm",
        "unitless",
        "%",
        "dega",
        "unitless",
        "ft",
        "dega",
        "%",
        null,
        "%",
        "%",
        "unitless",
        "galUS/min",
        "psi",
        "degF",
        null,
        null,
        "API",
        "dega",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "%",
        "%",
        "%",
        "%",
        "%",
        "%",
        "%",
        "unitless",
        "Status",
        "%",
        "inHG",
        "inHG",
        "unitless",
        "galUS/min",
        "galUS/min",
        "psi",
        "rpm",
        "psi",
        "unitless",
        "unitless",
        "%",
        "Status",
        "%",
        "%",
        "psi",
        "galUS/min",
        "galUS/min",
        "psi",
        "unitless",
        "degF",
        "%",
        "bbl",
        "bbl",
        "ft",
        "ft",
        "Status",
        "MCMD",
        "galUS/min",
        "unitless",
        "ppm",
        "hp",
        "ft",
        "klbf",
        "hp/(sq inch)",
        "bbl/ft",
        "stks",
        "bbls",
        "bbl/ft",
        "inch",
        "ft",
        "inch",
        "stks",
        "bbls",
        "ft",
        "unitless",
        "psi",
        "dega",
        "lbf",
        "ppm",
        "ppm",
        "ppm",
        "unitless",
        "kpsi",
        "unitless",
        "kpsi",
        "unitless",
        "psi",
        "rpm",
        "klbf",
        "dega",
        "dega",
        "unitless",
        "psi",
        "Status",
        "unitless",
        "in",
        "ft",
        "Status",
        "unitless",
        "Status",
        "unitless",
        "Status",
        "Status",
        "%",
        "%",
        "kpsi",
        "klbf",
        "psi",
        "psi",
        "ft/h",
        "rpm",
        "rpm",
        "klbf",
        "klbf",
        "klbf",
        "kft.lbf",
        "psi",
        "rpm",
        "klbf",
        "psi",
        "rpm",
        "klbf",
        "unitless",
        "Status",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "Status",
        "unitless",
        "unitless",
        "unitless",
        "ft",
        "ft",
        "stks",
        "mins",
        null,
        "galUS/d",
        "psi",
        "Status",
        "ft",
        "psi",
        "m3/d",
        "m3/d",
        "psi",
        "psi",
        "psi",
        "dega",
        "galUS/min",
        "bbl",
        "bbl",
        "bbl",
        "bbl",
        "bbl",
        "bbl",
        "bbl",
        "bbl",
        "bbl",
        "bbl",
        "bbl",
        "bbl",
        "bbl",
        "bbl",
        "lbf/galUS",
        "lbf/galUS",
        "%",
        "mmho/cm",
        "mmho/cm",
        "psi",
        "kft.lbf",
        "rpm",
        null,
        "kft.lbf",
        null,
        "galUS/min",
        "psi",
        "ppm",
        "ppm",
        "ppm",
        "ppm",
        "ft",
        "ft/sec",
        "bbl/ft",
        "stks",
        "bbls",
        "bbl/ft",
        "stks",
        "bbls",
        "bbl/ft",
        "stks",
        "bbls",
        "bbl/ft",
        "stks",
        "bbls",
        "bbls",
        "klbf",
        "ft",
        "ft",
        "ft",
        "ft",
        "ft",
        "ft/hr",
        "Status",
        "%",
        "ppm",
        "strokes",
        "strokes",
        "strokes",
        "in",
        "ft",
        "unitless",
        "in",
        "rpm",
        "kft.lbf",
        "bbl",
        "cp",
        "psi",
        "psi",
        "psi",
        null,
        "%",
        null,
        null,
        null,
        "%",
        "%",
        "in",
        "in",
        "in",
        "bbl/stk",
        "in",
        "in",
        "in",
        "unitless",
        "unitless",
        "unitless",
        "bbls",
        "ft",
        "ft",
        "ft",
        "strokes",
        "strokes",
        null,
        "unitless",
        "unitless",
        "ft/h",
        "ft/h",
        "ft/h",
        "ft/h",
        "rpm",
        "Status",
        "ft",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "psi",
        "bbls",
        "rpm",
        "A",
        null,
        "Status",
        "Status",
        "lbf/galUS",
        "spm",
        "spm",
        "spm",
        "psi",
        "ft",
        "ft",
        "deg/100ft",
        "psi",
        "%",
        "unitless",
        "psi",
        "psi",
        "Status",
        "ft",
        "psi",
        "ft/min",
        "stks",
        "kft.lbf",
        "klbf",
        "strokes",
        "strokes",
        "strokes",
        "strokes",
        "Count",
        "strokes",
        "Count",
        "strokes",
        "Count",
        "ft",
        "ft",
        "psi",
        "mins",
        "ft",
        "unitless",
        "Status",
        "degF",
        "degF",
        "in^2",
        "s",
        "kft.lbf",
        "bbl",
        "strokes",
        "spm",
        "bbl",
        "%",
        "bbl",
        "bbl",
        "ft",
        "ft",
        "ft",
        "in",
        "kft.lbf",
        "lbf",
        "dega",
        "dega",
        "unitless",
        "kft.lbf",
        "unitless",
        "%",
        "%",
        "%",
        "MCMD",
        "psi",
        "unitless",
        "bbl",
        "klbf",
        "lbf/galUS",
        "Status",
        "Count",
        "unitless",
        "ft",
        "klbf",
        "unitless",
        "ft",
        "unitless",
        "unitless",
        null,
        null,
        "in",
        "ft",
        null,
        "unitless",
        "unitless",
        "unitless",
        "ft/h",
        "ft/h",
        "ft/h",
        "rpm",
        "Status",
        "klbf",
        "psi",
        "ft/h",
        "kft.lbf",
        "kft.lbf",
        "unitless",
        "ft",
        "klbf",
        "klbf",
        "kpsi",
        "hhp/in2",
        "unitless",
        "unitless",
        "unitless",
        "kpsi",
        "kft.lbf",
        "unitless",
        "kpsi",
        "unitless",
        "kpsi",
        "Status",
        "unitless",
        "unitless",
        "unitless",
        "unitless",
        "%",
        "%",
        "unitless",
        "%",
        "unitless",
        "galUS/d",
        "bbl",
        "lbf/100 ft2",
        "unitless"
    ],
    "displayTags": [
        "SL RES",
        "AD ROP",
        "ANNULAR PRESSURE",
        "AZI",
        "Accum Trip In",
        "Accum Trip Out",
        "Acetylene",
        "Activity",
        "Activity Code",
        "Air Pressure",
        "Annular Pressure Loss",
        "Annular Velocity",
        "Annular Volume",
        "Average Joint Length",
        "B TVD",
        "BIT DEPTH",
        "BIT RPM",
        "BIT SIZE",
        "BLOCK POSITION",
        "Back Pressure",
        "Basic MSE",
        "Bha Length",
        "Bit Pressure Loss",
        "Bit Status",
        "Bit TVD",
        "Bit TVD - WITS",
        "Bit Time",
        "Block Velocity",
        "Bttm Ann Press",
        "Bttm Ann Temp",
        "Bttm Pipe Temp",
        "CH DC1 Annular Strokes",
        "CH DC1 Annular Volume",
        "CH DC2 Annular Strokes",
        "CH DC2 Annular Volume",
        "CH DP Annular Strokes",
        "CH DP Annular Volume",
        "CH HWDP Annular Strokes",
        "CH HWDP Annular Volume",
        "CH Volume",
        "CIRCULATING HRS",
        "Carbon Dioxide",
        "Casing DC1 Annular Capacity",
        "Casing DC1 Length",
        "Casing DC2 Annular Capacity",
        "Casing DC2 Length",
        "Casing DP Annular Capacity",
        "Casing DP Length",
        "Casing HWDP Annular Capacity",
        "Casing HWDP Length",
        "Casing ID",
        "Casing Length",
        "Casing OD",
        "Casing TVD",
        "Choke Position 1",
        "Choke Position 2",
        "Chromo Methane",
        "Chromo Propane",
        "Co. Man G/L",
        "Consistency Index",
        "Cont AZ",
        "Cont INC",
        "Continuous Azimuth",
        "Continuous Inclination",
        "Counter A",
        "Counter B",
        "Cuttings to Hole Volume",
        "Cycle Count",
        "Cycle Time",
        "Cycle Volume",
        "CycleStrokes",
        "DAQ Version",
        "DBA 3D Tortuosity Index",
        "DBA 3D Tortuosity Index N Feet",
        "DBA Abnormal Gain Belief",
        "DBA Abnormal Loss Belief",
        "DBA Active Pit Change Volume",
        "DBA Agg Diff Press Spike Index",
        "DBA Annular Press Bit Est",
        "DBA Azimuthal Tortuosity Index",
        "DBA BHA Run Number",
        "DBA Bit Depth Est",
        "DBA Bit Depth Fault Alert",
        "DBA Bit Depth Fault Code",
        "DBA Bit Position Heave Comp",
        "DBA Bit RPM Est",
        "DBA Bit Wear Factor",
        "DBA Bit Weight Autozero",
        "DBA Bit Weight Est",
        "DBA Bit Weight Fault Alert",
        "DBA Bit Weight Fault Code",
        "DBA Bit Weight Min Hel Buckling",
        "DBA Block Height Est",
        "DBA Block Height Fault Alert",
        "DBA Block Height Fault Code",
        "DBA Block Weight Calibrated",
        "DBA Bottom to Surface Strokes",
        "DBA Build Rate",
        "DBA Casing Annulus Volume",
        "DBA Casing Run Number",
        "DBA Cleansed ROP",
        "DBA DLS",
        "DBA DLS Cumulative",
        "DBA Delta Flow Est",
        "DBA Depth Of Cut Est",
        "DBA Diff Press Est",
        "DBA Diff Press Spike Belief",
        "DBA Downhole Bit Aggressiveness",
        "DBA Downlink Belief",
        "DBA Drilling Loss Gain Volume",
        "DBA Drillstring Drag",
        "DBA Drillstring Torque",
        "DBA Drillstring Volume",
        "DBA ECD Bit Est",
        "DBA ECD Shoe Est",
        "DBA EW",
        "DBA Flag Active Pit Change",
        "DBA Flag Connection Flowback",
        "DBA Flag Mud Addition",
        "DBA Flag Mud Removal",
        "DBA Flag Pump Activity",
        "DBA Flow In Rate Critical Est",
        "DBA Flow In Rate Est",
        "DBA Flow In Rate Fault Alert",
        "DBA Flow In Rate Fault Code",
        "DBA Flow Out Rate Est",
        "DBA Flow Out Rate Fault Alert",
        "DBA Flow Out Rate Fault Code",
        "DBA Frequent Tight Spots Belief",
        "DBA Gain Loss Rate",
        "DBA Gain Loss Volume",
        "DBA HIF",
        "DBA HSI",
        "DBA Heartbeat",
        "DBA Heave Compensator Amplitude",
        "DBA Heave Compensator Period",
        "DBA High Hole Clean Eff Belief",
        "DBA Hole Depth Est",
        "DBA Hole Depth Fault Alert",
        "DBA Hole Depth Fault Code",
        "DBA Hole Section",
        "DBA Hook Load Est",
        "DBA Hook Load Max Fault Alert",
        "DBA Hook Load Max Fault Code",
        "DBA Hook Load Modeled",
        "DBA Hyd Coeff Flow In",
        "DBA Hyd Coeff Flow Out",
        "DBA Inclination Tortuosity Index",
        "DBA MSE Motor",
        "DBA MSE Surface",
        "DBA MSE Total",
        "DBA Mud Addition Belief",
        "DBA Mud Addition Removal Volume",
        "DBA Mud Motor Torque",
        "DBA Mud Removal Belief",
        "DBA Mud Report Mud Density",
        "DBA Mud Volume Connection Delta",
        "DBA Mud Volume Total Est",
        "DBA Mud Volume Total Fault Alert",
        "DBA Mud Volume Total Fault Code",
        "DBA NS",
        "DBA Open Hole Annulus Volume",
        "DBA Open Hole FF",
        "DBA Overpull Amount",
        "DBA Overpull Belief",
        "DBA Packoff Belief",
        "DBA Pipe Rock Percentage",
        "DBA Pipe Rock Period",
        "DBA Pipe Rock RPM",
        "DBA Pump Activity Volume",
        "DBA Pump Failure Belief",
        "DBA Pump Press Est",
        "DBA Pump Press Fault Code",
        "DBA Pump Start Belief",
        "DBA Pump Stop Belief",
        "DBA ROP Cut Depth",
        "DBA ROP Instantaneous",
        "DBA RigState",
        "DBA Rotary Bit Balling Belief",
        "DBA Rotary Bit Bounce Belief",
        "DBA Rotary Bit Stick Slip Belief",
        "DBA Rotary Cone Angle",
        "DBA Rotary Cone Offset Angle",
        "DBA Rotary Cone Radius",
        "DBA Rotary Cone X Value",
        "DBA Rotary Cone Y Value",
        "DBA Rotary Drilling Rec",
        "DBA Rotary Opt Drill Index Avg",
        "DBA Rotary String Stick Slip Belief",
        "DBA Rotary Tot Stick Slip Belief",
        "DBA Rotary Whirl Belief",
        "DBA Slide Bit Bounce Belief",
        "DBA Slide Buckling Belief",
        "DBA Slide Cone Angle",
        "DBA Slide Cone Offset Angle",
        "DBA Slide Cone Radius",
        "DBA Slide Cone X Value",
        "DBA Slide Cone Y Value",
        "DBA Slide Drilling Rec",
        "DBA Slide High Friction Belief",
        "DBA Slide Opt Drilling Index",
        "DBA Slide Poor Toolface Belief",
        "DBA Slide Stick Slip Belief",
        "DBA Standpipe Press Fault Alert",
        "DBA Static Well Belief",
        "DBA Stick Slip Amp Est",
        "DBA String Torque Autozero",
        "DBA String Weight Est",
        "DBA Subopt Bit Hydraulics Belief",
        "DBA Suff Circ Belief",
        "DBA Suff Circ No Drilling Belief",
        "DBA Suff Pipe Rotation Belief",
        "DBA Suff Pipe Working Belief",
        "DBA Surface Bit Aggressiveness",
        "DBA Surface Horsepower",
        "DBA Surface to Bit Strokes",
        "DBA Surface to Shoe Strokes",
        "DBA Sweep 1 Stroke Counter",
        "DBA Sweep 2 Stroke Counter",
        "DBA Sweep 3 Stroke Counter",
        "DBA Sweep 4 Stroke Counter",
        "DBA TOB Modeled",
        "DBA TVD",
        "DBA TVD Bit",
        "DBA TnD Depth",
        "DBA TnD ECD Bit",
        "DBA TnD ECD Shoe",
        "DBA TnD Flow in Rate",
        "DBA TnD Free Rotating FF",
        "DBA TnD Free Rotating Torque",
        "DBA TnD Free Rotating Weight",
        "DBA TnD Hookload Deviation",
        "DBA TnD Hookload Reference",
        "DBA TnD Overpull Belief",
        "DBA TnD Pick Up FF",
        "DBA TnD Pick Up Weight",
        "DBA TnD Slack Off FF",
        "DBA TnD Slack Off Weight",
        "DBA TnD Standpipe Press",
        "DBA TnD Top Drive RPM",
        "DBA TnD Top Drive Torque",
        "DBA TnD Trip Speed",
        "DBA TnD Underpull Belief",
        "DBA Toolface Eff Index",
        "DBA Top Drive RPM Est",
        "DBA Top Drive RPM Fault Alert",
        "DBA Top Drive RPM Fault Code",
        "DBA Top Drive Torque Est",
        "DBA Top Drive Torque Fault Alert",
        "DBA Top Drive Torque Fault Code",
        "DBA Trip Speed",
        "DBA Underpull Amount",
        "DBA Underpull Belief",
        "DBA Underpull Overpull Amount",
        "DBA WOB Modeled",
        "DBA Walk Rate",
        "DBA Washout Belief",
        "DBA Washout Pump Fail Belief Sum",
        "DC1 Annular Capacity",
        "DC1 Annular Strokes",
        "DC1 Annular Volume",
        "DC1 Capacity",
        "DC1 ID",
        "DC1 Length",
        "DC1 OD",
        "DC1 Strokes",
        "DC1 Volume",
        "DC2 Annular Capacity",
        "DC2 Annular Strokes",
        "DC2 Annular Volume",
        "DC2 Capacity",
        "DC2 ID",
        "DC2 Length",
        "DC2 OD",
        "DC2 Strokes",
        "DC2 Volume",
        "DIFFERENTIAL PRESSURE",
        "DLS",
        "DP Annular Capacity",
        "DP Annular Strokes",
        "DP Annular Volume",
        "DP Capacity",
        "DP ID",
        "DP Length",
        "DP OD",
        "DP Strokes",
        "DP Volume",
        "Daily Accum Wear",
        "Dog Leg Severity - WITS",
        "Drill String Volume",
        "Drilling Trigger HKLD",
        "ECD @ Bit",
        "ECD @ Casing",
        "EDMS Counts",
        "East West Horizontal",
        "EcdatCasingWithPowerLawModel",
        "EcdatbitWithPowerLawModel",
        "Ethane",
        "FD Heartbeat",
        "FD Pump Stability",
        "FD Svy Azimuth",
        "FD Svy Date",
        "FD Svy Depth",
        "FD Svy Inclination",
        "FD Svy Quality",
        "FD Svy Time",
        "FLOW OUT",
        "Flow Deviation",
        "Flow Index",
        "Flow Out Rate",
        "Flow Pressure",
        "Flow Temp",
        "G/L Rate - 1 Min",
        "G/L Rate - 5 Min",
        "GAMMA RAY",
        "GTF",
        "GW Air Flow",
        "GW Balance Ratio",
        "GW Blowback",
        "GW BlowbackOn/Off",
        "GW Char Ratio",
        "GW Ethane (C2)",
        "GW ISO-Butane (IC4)",
        "GW ISO-Pentane (IC5)",
        "GW Methane (C1)",
        "GW NOR-Butane (NC4)",
        "GW NOR-Pentane (NC5)",
        "GW Propane (C3)",
        "GW Pump Shutdown",
        "GW Status",
        "GW Total Gas",
        "GW Vacuum",
        "GW Vacuum Max",
        "GW Wet Ratio",
        "GWEX AgitatorFlow",
        "GWEX AgitatorFlowSetpoint",
        "GWEX AgitatorPressure",
        "GWEX AgitatorRate",
        "GWEX BlowbackSetpoint",
        "GWEX Command",
        "GWEX FaultMode",
        "GWEX H2S",
        "GWEX H2SAlarm",
        "GWEX Methane",
        "GWEX Propane",
        "GWEX RigAirPressure",
        "GWEX SampleFlow",
        "GWEX SampleFlowSetpoint",
        "GWEX SampleVacuum",
        "GWEX SystemMode",
        "GWEX Temperature",
        "GWEX TotalGas",
        "Gain Loss",
        "Gain Loss - Spare",
        "Gamma Depth",
        "Gamma Depth",
        "Gas Fault",
        "Gas Flow Rate",
        "Gas Injection",
        "Gear In Use",
        "H2S Gas",
        "HHP",
        "HOLE DEPTH",
        "HOOK LOAD",
        "HSI",
        "HWDP Annular Capacity",
        "HWDP Annular Strokes",
        "HWDP Annular Volume",
        "HWDP Capacity",
        "HWDP ID",
        "HWDP Length",
        "HWDP OD",
        "HWDP Strokes",
        "HWDP Volume",
        "Hole Depth Last 24Hrs",
        "Horn Alarm State",
        "Hydrostatic Press",
        "INC",
        "Impact Force",
        "Iso-Butane",
        "Iso-Hexane",
        "Iso-Pentane",
        "Joints Per Stand",
        "KZ Base MSE",
        "KZ Bit Bounce",
        "KZ Bit MSE",
        "KZ CPU Overload Count",
        "KZ Command Delta P",
        "KZ Command RPM",
        "KZ Command SWOB",
        "KZ Command Toolface Grav",
        "KZ Command Toolface Mag",
        "KZ Communication Resets",
        "KZ Corrected Delta P",
        "KZ Delta P Correction Status",
        "KZ Delta P/SWOB Focus",
        "KZ Depth of Cut",
        "KZ Distance to Plan",
        "KZ Downhole Model Status",
        "KZ Dynamic Window",
        "KZ Dysfunction Indicator",
        "KZ Engine Heartbeat",
        "KZ Engine In Control",
        "KZ Engine Status",
        "KZ Exploration Percent",
        "KZ Exploration Progress",
        "KZ MSE Scatter",
        "KZ Max DWOB",
        "KZ Max Delta P",
        "KZ Max Delta P Step",
        "KZ Max ROP",
        "KZ Max RPM",
        "KZ Max RPM Step",
        "KZ Max SWOB",
        "KZ Max SWOB Based on Stress",
        "KZ Max SWOB Step",
        "KZ Max Torque",
        "KZ Min Delta P",
        "KZ Min RPM",
        "KZ Min SWOB",
        "KZ Optimum Delta P",
        "KZ Optimum RPM",
        "KZ Optimum SWOB",
        "KZ Response Tuning",
        "KZ Soft Speed Status",
        "KZ Static Data Health",
        "KZ Stick Slip Level",
        "KZ Surface Axial OSC",
        "KZ Surface Torsional OSC",
        "KZ Twister Status",
        "KZ Whirl Level",
        "KZ Whirl Magnitude",
        "KZ Whirl Velocity",
        "Kelly Down",
        "LAG DEPTH",
        "Lag Strokes",
        "Lag Time",
        "Last 24 Hr Wear",
        "Liquid Flow Rate",
        "Liquid Injection",
        "Loop In Control",
        "Lst Elem Len",
        "MPD Choke Pressure",
        "MPD Flow Out",
        "MPD Gas Flow",
        "MPD RCD Pressure",
        "MSE Downhole",
        "MSE Total",
        "MTF",
        "MUD FLOW IN",
        "MUD TANK 1 VOLUME",
        "MUD TANK 10 VOLUME",
        "MUD TANK 11 VOLUME",
        "MUD TANK 12 VOLUME",
        "MUD TANK 13 VOLUME",
        "MUD TANK 14 VOLUME",
        "MUD TANK 2 VOLUME",
        "MUD TANK 3 VOLUME",
        "MUD TANK 4 VOLUME",
        "MUD TANK 5 VOLUME",
        "MUD TANK 6 VOLUME",
        "MUD TANK 7 VOLUME",
        "MUD TANK 8 VOLUME",
        "MUD TANK 9 VOLUME",
        "MUD WEIGHT IN",
        "MUD WEIGHT OUT",
        "Methane (GW)",
        "Mud Conductivity In",
        "Mud Conductivity Out",
        "Mud Motor Max Diff Pressure",
        "Mud Motor Max Torque",
        "Mud Motor RPM",
        "Mud Motor RPM Factor",
        "Mud Motor Torque",
        "Mud Motor Torque Factor",
        "N2 Out",
        "N2 Pressure In",
        "Neo-Pentane",
        "Nor-Butane",
        "Nor-Hexane",
        "Nor-Pentane",
        "North South Horizontal",
        "Nozzle Velocity",
        "OH DC1 Annular Capacity",
        "OH DC1 Annular Strokes",
        "OH DC1 Annular Volume",
        "OH DC2 Annular Capacity",
        "OH DC2 Annular Strokes",
        "OH DC2 Annular Volume",
        "OH DP Annular Capacity",
        "OH DP Annular Strokes",
        "OH DP Annular Volume",
        "OH HWDP Annular Capacity",
        "OH HWDP Annular Strokes",
        "OH HWDP Annular Volume",
        "OH Volume",
        "OVERPULL",
        "Open Hole DC1 Length",
        "Open Hole DC2 Length",
        "Open Hole DP Length",
        "Open Hole HWDP Length",
        "OpenHole Length",
        "Optimized ROP",
        "Oscillator State",
        "Overall TE",
        "Oxygen",
        "PUMP 1 TOTAL STROKES",
        "PUMP 2 TOTAL STROKES",
        "PUMP 3 TOTAL STROKES",
        "Pipe ID",
        "Pipe Length",
        "Pipe Length Change",
        "Pipe OD",
        "Pipe RPM",
        "Pipe Torque",
        "Pit Gain",
        "Plastic Viscosity",
        "PowerLawModelAnnularPressureLoss",
        "PowerLawModelDrillStringpressureLoss",
        "PowerLawModelstandpipePressureLoss",
        "Prev Day Wear",
        "Propane (GW)",
        "Pump Displacement 1",
        "Pump Displacement 2",
        "Pump Displacement 3",
        "Pump Efficiency 1",
        "Pump Efficiency 2",
        "Pump Liner 1",
        "Pump Liner 2",
        "Pump Liner 3",
        "Pump Output",
        "Pump Stroke Length 1",
        "Pump Stroke Length 2",
        "Pump Stroke Length 3",
        "Pump Type 1",
        "Pump Type 2",
        "Pump Type 3",
        "Pumped Volume",
        "QL Depth Delay",
        "QL Returns Depth",
        "QL Start Depth",
        "QL Stks Bt Up",
        "QL Strokes To Surface",
        "QL Time To Surface",
        "R/T Gear",
        "R600",
        "ROP",
        "ROP - Average",
        "ROP - Cut Unit",
        "ROP - Fast",
        "RPM",
        "Remote Gain Loss Alarm",
        "Returns Depth - Realtime",
        "Rig Activity",
        "Rig Activity Code",
        "Rig Activity Engine",
        "Rig Activity SubCode",
        "Rig Activity Version",
        "Rig Air Press",
        "Riser Volume",
        "Rotary RPM",
        "Rotary Torque",
        "Rotary Torque Factor",
        "SDAQ Status",
        "SLIP STATUS",
        "SLUG WEIGHT",
        "SPM1",
        "SPM2",
        "SPM3",
        "SPP",
        "SVY_E_W_POSITION",
        "SVY_N_S_POSITION",
        "SVY_WALK_RATE",
        "Simplified MSE",
        "Skim Flow",
        "Slip Switch",
        "Standpipe Pressure 1",
        "Standpipe Pressure 2",
        "Stick Slip Mitigator On",
        "String Length",
        "String Pressure Loss",
        "String Speed",
        "String Strokes",
        "String Torque",
        "String Weight",
        "Strks - Acc Drill",
        "Strks - Acc Fill",
        "Strks - Fill",
        "Strokes 1A",
        "Strokes 1B",
        "Strokes 2A",
        "Strokes 2B",
        "Strokes 3A",
        "Strokes 3B",
        "Subsea TVD",
        "Subsea TVD",
        "Surface Press Loss",
        "Surface to Bit Time",
        "Svy Depth",
        "Svy Number",
        "TD/RT Sel Flag",
        "TEMP IN",
        "TEMP OUT",
        "TFA",
        "TIME",
        "TORQUE",
        "TOTAL MUD VOLUME",
        "TOTAL PUMP STROKES",
        "TOTAL SPM",
        "TOTAL TTK VOLUME",
        "TOTAL_GAS3",
        "TTK1 VOLUME",
        "TTK2 VOLUME",
        "TVD",
        "TVD",
        "TVD - WITS",
        "Tong Length",
        "Tong Torque",
        "Tong Torque LP",
        "Toolface Target",
        "Toolface Thresh",
        "Toolface Threshold",
        "Top Drv Torque - Converted",
        "Torque Diff",
        "Total Gas ",
        "Total Gas (GW)",
        "Total Gas Normalized",
        "Total Gas Out",
        "Total Pressure loss",
        "Trigger HKLD Status",
        "Trip Tank GL",
        "Tripping Trigger HKLD",
        "VSLUG",
        "WC A/D Status",
        "WC Active Profile",
        "WC Auto/Time Drill",
        "WC Bit Bounce",
        "WC Bit Weight",
        "WC Diff P Enable",
        "WC Drill Stop Point",
        "WC Drum Engaged",
        "WC EADS Enable",
        "WC Feed Forward",
        "WC Freq Cmd",
        "WC Incr Distance",
        "WC Len To Drill",
        "WC Lift Unit Threshold",
        "WC Loop In Control",
        "WC Motor Enable",
        "WC Phoenix Flag",
        "WC ROP",
        "WC ROP Safety Limit",
        "WC Ream Speed",
        "WC Sensitivity",
        "WC Status",
        "WC Target Bit Weight",
        "WC Target Diff Press",
        "WC Target ROP",
        "WC Target Torque",
        "WC Torque",
        "WC Torque Enable",
        "WC Total Drilled",
        "WC WOB Safety Limit",
        "WOB",
        "WPDA - Avg MSE",
        "WPDA - Bit HHP",
        "WPDA - Dim Torque",
        "WPDA - HCA Heartbeat",
        "WPDA - HeartBeat",
        "WPDA - MSE",
        "WPDA - Pipe Torq Engr",
        "WPDA - Pseudo-MSE",
        "WPDA - Ref CCS",
        "WPDA - Ref Gamma",
        "WPDA - Ref MSE",
        "WPDA - Status",
        "WPDA - d Exponent",
        "WSGD Airflow",
        "WSGD GCIB Bd1 Chnl1 Te",
        "WSGD GCIB Bd2 Chnl1 Te",
        "WSGD Methane",
        "WSGD Propane",
        "WSGD Pump Shutdown",
        "WSGD Total Gas",
        "Washout Factor",
        "Water Out",
        "Water Pit",
        "Yield Point",
        "r300"
    ],
    "description": [
        "Shallow Resistivity",
        "AD ROP",
        "ANN_PRESSURE",
        "SRV_AZI",
        "ACCUM_TRIP_IN",
        "ACCUM_TRIP_OUT",
        "GAS_ACETYL",
        "activity",
        "activitycode",
        "Air Pressure",
        "binghamplasticmodelannularpressureloss",
        "QL_ANNULAR_VELOCITY",
        "annularvolume",
        "Average Joint Length",
        "btvd",
        "BIT_DEPTH",
        "BIT_RPM",
        "bitsize",
        "BLOCK_POS",
        "Back Pressure",
        "Basic MSE",
        "bhalength",
        "bitpressuredrop",
        "BIT_ON_BTM",
        "BIT_DPT_MD",
        "Bit TVD - WITS",
        "HRS_ON_BIT",
        "BLOCK_SPEED",
        "Bttm Ann Press",
        "ANL_TMP",
        "Bttm Pipe Temp",
        "chdc1annularstrokes",
        "chdc1annularvolume",
        "chdc2annularstrokes",
        "chdc2annularvolume",
        "chdpannularstrokes",
        "chdpannularvolume",
        "chhwdpannularstrokes",
        "chhwdpannularvolume",
        "casedholevolume",
        "Circulating Hrs",
        "GAS_CO2",
        "casingdc1annularcapacity",
        "casingdc1length",
        "casingdc2annularcapacity",
        "casingdc2length",
        "casingdpannularcapacity",
        "casingdplength",
        "casinghwdpannularcapacity",
        "casinghwdplength",
        "casingid",
        "casinglength",
        "casingod",
        "casingtvd",
        "CHOKE_POSN",
        "Choke Position 2",
        "GAS_C1",
        "GAS_C3",
        "Co. Man G/L",
        "consistencyindex",
        "Cont AZ",
        "Cont INC",
        "Continuous Azimuth",
        "Continuous Inclination",
        "Counter A",
        "Counter B",
        "drillcuttingconcentration",
        "cyclecount",
        "cycletime",
        "cyclevolume",
        "cyclestrokes",
        "DAQ Version",
        "DBA 3D Tortuosity Index",
        "DBA 3D Tortuosity Index N Feet",
        "DBA Abnormal Gain Belief",
        "DBA Abnormal Loss Belief",
        "DBA Active Pit Change Volume",
        "DBA Agg Diff Press Spike Index",
        "DBA Annular Press Bit Est",
        "DBA Azimuthal Tortuosity Index",
        "DBA BHA Run Number",
        "DBA Bit Depth Est",
        "DBA Bit Depth Fault Alert",
        "DBA Bit Depth Fault Code",
        "DBA Bit Position Heave Comp",
        "DBA Bit RPM Est",
        "DBA Bit Wear Factor",
        "DBA Bit Weight Autozero",
        "DBA Bit Weight Est",
        "DBA Bit Weight Fault Alert",
        "DBA Bit Weight Fault Code",
        "DBA Bit Weight Min Hel Buckling",
        "DBA Block Height Est",
        "DBA Block Height Fault Alert",
        "DBA Block Height Fault Code",
        "DBA Block Weight Calibrated",
        "DBA Bottom to Surface Strokes",
        "DBA Build Rate",
        "DBA Casing Annulus Volume",
        "DBA Casing Run Number",
        "DBA Cleansed ROP",
        "DBA DLS",
        "DBA DLS Cumulative",
        "DBA Delta Flow Est",
        "DBA Depth Of Cut Est",
        "DBA Diff Press Est",
        "DBA Diff Press Spike Belief",
        "DBA Downhole Bit Aggressiveness",
        "DBA Downlink Belief",
        "DBA Drilling Loss Gain Volume",
        "DBA Drillstring Drag",
        "DBA Drillstring Torque",
        "DBA Drillstring Volume",
        "DBA ECD Bit Est",
        "DBA ECD Shoe Est",
        "DBA EW",
        "DBA Flag Active Pit Change",
        "DBA Flag Connection Flowback",
        "DBA Flag Mud Addition",
        "DBA Flag Mud Removal",
        "DBA Flag Pump Activity",
        "DBA Flow In Rate Critical Est",
        "DBA Flow In Rate Est",
        "DBA Flow In Rate Fault Alert",
        "DBA Flow In Rate Fault Code",
        "DBA Flow Out Rate Est",
        "DBA Flow Out Rate Fault Alert",
        "DBA Flow Out Rate Fault Code",
        "DBA Frequent Tight Spots Belief",
        "DBA Gain Loss Rate",
        "DBA Gain Loss Volume",
        "DBA HIF",
        "DBA HSI",
        "DBA Heartbeat",
        "DBA Heave Compensator Amplitude",
        "DBA Heave Compensator Period",
        "DBA High Hole Clean Eff Belief",
        "DBA Hole Depth Est",
        "DBA Hole Depth Fault Alert",
        "DBA Hole Depth Fault Code",
        "DBA Hole Section",
        "DBA Hook Load Est",
        "DBA Hook Load Max Fault Alert",
        "DBA Hook Load Max Fault Code",
        "DBA Hook Load Modeled",
        "DBA Hyd Coeff Flow In",
        "DBA Hyd Coeff Flow Out",
        "DBA Inclination Tortuosity Index",
        "DBA MSE Motor",
        "DBA MSE Surface",
        "DBA MSE Total",
        "DBA Mud Addition Belief",
        "DBA Mud Addition Removal Volume",
        "DBA Mud Motor Torque",
        "DBA Mud Removal Belief",
        "DBA Mud Report Mud Density",
        "DBA Mud Volume Connection Delta",
        "DBA Mud Volume Total Est",
        "DBA Mud Volume Total Fault Alert",
        "DBA Mud Volume Total Fault Code",
        "DBA NS",
        "DBA Open Hole Annulus Volume",
        "DBA Open Hole FF",
        "DBA Overpull Amount",
        "DBA Overpull Belief",
        "DBA Packoff Belief",
        "DBA Pipe Rock Percentage",
        "DBA Pipe Rock Period",
        "DBA Pipe Rock RPM",
        "DBA Pump Activity Volume",
        "DBA Pump Failure Belief",
        "DBA Pump Press Est",
        "DBA Pump Press Fault Code",
        "DBA Pump Start Belief",
        "DBA Pump Stop Belief",
        "DBA ROP Cut Depth",
        "DBA ROP Instantaneous",
        "DBA RigState",
        "DBA Rotary Bit Balling Belief",
        "DBA Rotary Bit Bounce Belief",
        "DBA Rotary Bit Stick Slip Belief",
        "DBA Rotary Cone Angle",
        "DBA Rotary Cone Offset Angle",
        "DBA Rotary Cone Radius",
        "DBA Rotary Cone X Value",
        "DBA Rotary Cone Y Value",
        "DBA Rotary Drilling Rec",
        "DBA Rotary Opt Drill Index Avg",
        "DBA Rotary String Stick Slip Belief",
        "DBA Rotary Tot Stick Slip Belief",
        "DBA Rotary Whirl Belief",
        "DBA Slide Bit Bounce Belief",
        "DBA Slide Buckling Belief",
        "DBA Slide Cone Angle",
        "DBA Slide Cone Offset Angle",
        "DBA Slide Cone Radius",
        "DBA Slide Cone X Value",
        "DBA Slide Cone Y Value",
        "DBA Slide Drilling Rec",
        "DBA Slide High Friction Belief",
        "DBA Slide Opt Drilling Index",
        "DBA Slide Poor Toolface Belief",
        "DBA Slide Stick Slip Belief",
        "DBA Standpipe Press Fault Alert",
        "DBA Static Well Belief",
        "DBA Stick Slip Amp Est",
        "DBA String Torque Autozero",
        "DBA String Weight Est",
        "DBA Subopt Bit Hydraulics Belief",
        "DBA Suff Circ Belief",
        "DBA Suff Circ No Drilling Belief",
        "DBA Suff Pipe Rotation Belief",
        "DBA Suff Pipe Working Belief",
        "DBA Surface Bit Aggressiveness",
        "DBA Surface Horsepower",
        "DBA Surface to Bit Strokes",
        "DBA Surface to Shoe Strokes",
        "DBA Sweep 1 Stroke Counter",
        "DBA Sweep 2 Stroke Counter",
        "DBA Sweep 3 Stroke Counter",
        "DBA Sweep 4 Stroke Counter",
        "DBA TOB Modeled",
        "DBA TVD",
        "DBA TVD Bit",
        "DBA TnD Depth",
        "DBA TnD ECD Bit",
        "DBA TnD ECD Shoe",
        "DBA TnD Flow in Rate",
        "DBA TnD Free Rotating FF",
        "DBA TnD Free Rotating Torque",
        "DBA TnD Free Rotating Weight",
        "DBA TnD Hookload Deviation",
        "DBA TnD Hookload Reference",
        "DBA TnD Overpull Belief",
        "DBA TnD Pick Up FF",
        "DBA TnD Pick Up Weight",
        "DBA TnD Slack Off FF",
        "DBA TnD Slack Off Weight",
        "DBA TnD Standpipe Press",
        "DBA TnD Top Drive RPM",
        "DBA TnD Top Drive Torque",
        "DBA TnD Trip Speed",
        "DBA TnD Underpull Belief",
        "DBA Toolface Eff Index",
        "DBA Top Drive RPM Est",
        "DBA Top Drive RPM Fault Alert",
        "DBA Top Drive RPM Fault Code",
        "DBA Top Drive Torque Est",
        "DBA Top Drive Torque Fault Alert",
        "DBA Top Drive Torque Fault Code",
        "DBA Trip Speed",
        "DBA Underpull Amount",
        "DBA Underpull Belief",
        "DBA Underpull Overpull Amount",
        "DBA WOB Modeled",
        "DBA Walk Rate",
        "DBA Washout Belief",
        "DBA Washout Pump Fail Belief Sum",
        "dc1annularcapacity",
        "dc1annularstrokes",
        "dc1annularvolume",
        "dc1capacity",
        "dc1id",
        "dc1length",
        "dc1od",
        "dc1strokes",
        "dc1volume",
        "dc2annularcapacity",
        "dc2annularstrokes",
        "dc2annularvolume",
        "dc2capacity",
        "dc2id",
        "dc2length",
        "dc2od",
        "dc2strokes",
        "dc2volume",
        "DIFF_PRESS",
        "Dog Leg Severity",
        "dpannularcapacity",
        "dpannularstrokes",
        "dpannularvolume",
        "dpcapacity",
        "dpid",
        "dplength",
        "dpod",
        "dpstrokes",
        "dpvolume",
        "DAILY_TON_MILE",
        "Dog Leg Severity - WITS",
        "drillstringvolume",
        "Drilling Trigger HKLD",
        "ecdatbitwithbinghamplasticmodel",
        "ecdatcasingwithbinghamplasticmodel",
        "EDMS Counts",
        "East West Horizontal",
        "ecdatcasingwithpowerlawmodel",
        "ecdatbitwithpowerlawmodel",
        "GAS_C2",
        "FD Heartbeat",
        "FD Pump Stability",
        "FD Svy Azimuth",
        "FD Svy Date",
        "FD Svy Depth",
        "FD Svy Inclination",
        "FD Svy Quality",
        "DateAndTime",
        "FLOW_OUT_REL",
        "FLOW_DEVIATION",
        "flowindex",
        "FLOW_OUT",
        "Flow Pressure",
        "Flow Temp",
        "G/L Rate - 1 Min",
        "G/L Rate - 5 Min",
        "GAMMA_RAY",
        "SRV_GRA_TF",
        "GW Air Flow",
        "GW Balance Ratio",
        "GW Blowback",
        "GW BlowbackOn/Off",
        "GW Char Ratio",
        "GW Ethane (C2)",
        "GW ISO-Butane (IC4)",
        "GW ISO-Pentane (IC5)",
        "GW Methane (C1)",
        "GW NOR-Butane (NC4)",
        "GW NOR-Pentane (NC5)",
        "GW Propane (C3)",
        "GW Pump Shutdown",
        "GW Status",
        "GW Total Gas",
        "GW Vacuum",
        "GW Vacuum Max",
        "GW Wet Ratio",
        "GWEX AgitatorFlow",
        "GWEX AgitatorFlowSetpoint",
        "GWEX AgitatorPressure",
        "GWEX AgitatorRate",
        "GWEX BlowbackSetpoint",
        "GWEX Command",
        "GWEX FaultMode",
        "GWEX H2S",
        "GWEX H2SAlarm",
        "GWEX Methane",
        "GWEX Propane",
        "GWEX RigAirPressure",
        "GWEX SampleFlow",
        "GWEX SampleFlowSetpoint",
        "GWEX SampleVacuum",
        "GWEX SystemMode",
        "GWEX Temperature",
        "GWEX TotalGas",
        "GAINLOSS",
        "Gain Loss - Spare",
        "GAMMA_RAY_DEPTH",
        "GAMMA_RAY_DEPTH_ADJUSTED",
        "Gas Fault",
        "Gas Flow Rate",
        "Gas Injection",
        "Gear In Use",
        "GAS_H2S_MAX",
        "bithydraulichorsepower",
        "TOT_DPT_MD",
        "HOOKLOAD_MAX",
        "horsepowerperinch",
        "hwdpannularcapacity",
        "hwdpannularstrokes",
        "hwdpannularvolume",
        "hwdpcapacity",
        "hwdpid",
        "hwdplength",
        "hwdpod",
        "hwdpstrokes",
        "hwdpvolume",
        "Hole Depth Last 24Hrs",
        "Horn Alarm State",
        "hydrostaticpressure",
        "SRV_INC",
        "nozzleimpactforce",
        "GAS_ISO-C4",
        "Iso-Hexane",
        "GAS_ISO-C5",
        "Joints Per Stand",
        "KZ Base MSE",
        "KZ Bit Bounce",
        "KZ Bit MSE",
        "KZ CPU Overload Count",
        "KZ Command Delta P",
        "KZ Command RPM",
        "KZ Command SWOB",
        "KZ Command Toolface Grav",
        "KZ Command Toolface Mag",
        "KZ Communication Resets",
        "KZ Corrected Delta P",
        "KZ Delta P Correction Status",
        "KZ Delta P/SWOB Focus",
        "KZ Depth of Cut",
        "KZ Distance to Plan",
        "KZ Downhole Model Status",
        "KZ Dynamic Window",
        "KZ Dysfunction Indicator",
        "KZ Engine Heartbeat",
        "KZ Engine In Control",
        "KZ Engine Status",
        "KZ Exploration Percent",
        "KZ Exploration Progress",
        "KZ MSE Scatter",
        "KZ Max DWOB",
        "KZ Max Delta P",
        "KZ Max Delta P Step",
        "KZ Max ROP",
        "KZ Max RPM",
        "KZ Max RPM Step",
        "KZ Max SWOB",
        "KZ Max SWOB Based on Stress",
        "KZ Max SWOB Step",
        "KZ Max Torque",
        "KZ Min Delta P",
        "KZ Min RPM",
        "KZ Min SWOB",
        "KZ Optimum Delta P",
        "KZ Optimum RPM",
        "KZ Optimum SWOB",
        "KZ Response Tuning",
        "KZ Soft Speed Status",
        "KZ Static Data Health",
        "KZ Stick Slip Level",
        "KZ Surface Axial OSC",
        "KZ Surface Torsional OSC",
        "KZ Twister Status",
        "KZ Whirl Level",
        "KZ Whirl Magnitude",
        "KZ Whirl Velocity",
        "Kelly Down",
        "LAG_DPT_MD",
        "lagstrokes",
        "lagtime",
        "TON_MILE_24HR",
        "Liquid Flow Rate",
        "Liquid Injection",
        "Loop In Control",
        "Lst Elem Len",
        "MPD Choke Pressure",
        "MPD Flow Out",
        "MPD Gas Flow",
        "MPD RCD Pressure",
        "MSE Downhole",
        "MSE Total",
        "SRV_MAG_TF",
        "FLOW_IN",
        "TANK1_VOL",
        "TANK10_VOL",
        "Pit Volume 11",
        "Pit Volume 12",
        "Pit Volume 13",
        "Pit Volume 14",
        "TANK2_VOL",
        "TANK3_VOL",
        "TANK4_VOL",
        "TANK5_VOL",
        "TANK6_VOL",
        "TANK7_VOL",
        "TANK8_VOL",
        "TANK9_VOL",
        "DENS_IN",
        "DENS_OUT",
        "Methane (GW)",
        "Mud Conductivity In",
        "Mud Conductivity Out",
        "Mud Motor Max Diff Pressure",
        "Mud Motor Max Torque",
        "Mud Motor RPM",
        "Mud Motor RPM Factor",
        "Mud Motor Torque",
        "Mud Motor Torque Factor",
        "N2 Out",
        "N2 Pressure In",
        "GAS_NEO-C5",
        "GAS_NOR-C4",
        "Nor-Hexane",
        "GAS_NOR-C5",
        "North South Horizontal",
        "nozzlevelocity",
        "ohdc1annularcapacity",
        "ohdc1annularstrokes",
        "ohdc1annularvolume",
        "ohdc2annularcapacity",
        "ohdc2annularstrokes",
        "ohdc2annularvolume",
        "ohdpannularcapacity",
        "ohdpannularstrokes",
        "ohdpannularvolume",
        "ohhwdpannularcapacity",
        "ohhwdpannularstrokes",
        "ohhwdpannularvolume",
        "openholevolume",
        "Overpull",
        "openholedc1length",
        "openholedc2length",
        "openholedplength",
        "openholehwdplength",
        "openholelength",
        "optimizedrop",
        "Oscillator State",
        "cuttingtransportefficiency",
        "Oxygen",
        "MP1_STK",
        "MP2_STK",
        "MP3_STK",
        "Pipe ID",
        "Pipe Length",
        "Pipe Length Change",
        "Pipe OD",
        "Pipe RPM",
        "Pipe Torque",
        "pitgain",
        "plasticviscosity",
        "powerlawmodelannularpressureloss",
        "powerlawmodeldrillstringpressureloss",
        "powerlawmodelstandpipepressureloss",
        "Prev Day Wear",
        "Propane (GW)",
        "Pump Displacement 1",
        "Pump Displacement 2",
        "Pump Displacement 3",
        "Pump Efficiency 1",
        "Pump Efficiency 2",
        "Pump Liner 1",
        "Pump Liner 2",
        "Pump Liner 3",
        "pumpoutput",
        "Pump Stroke Length 1",
        "Pump Stroke Length 2",
        "Pump Stroke Length 3",
        "Pump Type 1",
        "Pump Type 2",
        "Pump Type 3",
        "pumpedvolume",
        "QL Depth Delay",
        "QL Returns Depth",
        "QL Start Depth",
        "QL Stks Bt Up",
        "QL Strokes To Surface",
        "QL_TM_TO_SURF",
        "R/T Gear",
        "r600",
        "FAST_ROP_FT_HR",
        "AVG_ROP_FT_HR",
        "CUT_FOOT_ROP_FT_HR",
        "FAST_ROP_FT_HR_DEPTH",
        "TD_SPEED",
        "Remote Gain Loss Alarm",
        "QL_FAST_RET_DEPTH",
        "RIG_ACTIVITY",
        "IADC_RIG_ACTIVITY",
        "IADC_RIG_ACTIVITY3",
        "IADC_RIG_ACTIVITY2",
        "Rig Activity Version",
        "Rig Air Press",
        "riservolume",
        "ROT_SPEED",
        "ROT_TORQUE",
        "Rotary Torque Factor",
        "SDAQ Status",
        "SLIPS_STAT",
        "slugweight",
        "MP1_SPM",
        "MP2_SPM",
        "MP3_SPM",
        "STP_PRS_1",
        "SVY_E_W_POSITION",
        "SVY_N_S_POSITION",
        "SVY_WALK_RATE",
        "simplifiedmse",
        "Skim Flow",
        "Slip Switch",
        "Standpipe Pressure 1",
        "Standpipe Pressure 2",
        "Stick Slip Mitigator On",
        "String Length",
        "binghamplasticmodeldrillstringpressureloss",
        "STRING_SPEED",
        "stringstrokes",
        "STRING_TORQUE",
        "STRING_WEIGHT",
        "ACC_DRILL_STRKS",
        "ACC_FILL_STRKS",
        "FILL_STROKES",
        "Strokes 1A",
        "Strokes 1B",
        "Strokes 2A",
        "Strokes 2B",
        "Strokes 3A",
        "Strokes 3B",
        "Subsea TVD",
        "SUBSEA_TVD_3RDPARTY",
        "surfacepressureloss",
        "surfacetobittime",
        "SRV_MD",
        "SURVEY_NUMBER",
        "TD/RT Sel Flag",
        "TEMP_IN",
        "TEMP_OUT",
        "totalflowarea",
        "RIGTIME",
        "TD_TORQUE",
        "TOTAL_VOL",
        "TOT_STK",
        "TOT_SPM",
        "TT_VOL",
        "TOTAL_GAS3",
        "TT1_VOL",
        "TT2_VOL",
        "TVD",
        "TVD_3RDPARTY",
        "TVD - WITS",
        "Tong Length",
        "Tong Torque",
        "Tong Torque LP",
        "Toolface Target",
        "ALT_GRAVITY_TOOLFACE",
        "Toolface Threshold",
        "Top Drv Torque - Converted",
        "Torque Diff",
        "GAS_TOTAL",
        "TOTAL_GAS_GW",
        "Total Gas Normalized",
        "Total Gas Out",
        "binghamplasticmodelstandpipepressureloss",
        "Trigger HKLD Status",
        "TRIP_TANK_GL",
        "Tripping Trigger HKLD",
        "vslug",
        "WC A/D Status",
        "WC Active Profile",
        "WC_DRILLING_MODE_SELECT",
        "WC Bit Bounce",
        "WC Bit Weight",
        "WC_DIFF_P_ENABLED",
        "WC Drill Stop Point",
        "WC_DRUM_ENGAGED",
        "WC_EADS_ENABLED",
        "WC Feed Forward",
        "WC Freq Cmd",
        "WC Incr Distance",
        "WC Len To Drill",
        "WC Lift Unit Threshold",
        "WC_CONTROL_PARAM",
        "WC_MOTOR_ENABLE",
        "WC Phoenix Flag",
        "WC ROP",
        "WC ROP Safety Limit",
        "WC Ream Speed",
        "WC Sensitivity",
        "WC Status",
        "WC_TARGET_WOB",
        "WC_TARGET_DIFF_PRESS",
        "WC_TARGET_ROP",
        "WC_TARGET_TORQUE",
        "WC Torque",
        "WC_TORQUE_ENABLE",
        "WC Total Drilled",
        "WC WOB Safety Limit",
        "WOB",
        "WPDA - Avg MSE",
        "WPDA - Bit HHP",
        "WPDA - Dim Torque",
        "WPDA - HCA Heartbeat",
        "WPDA - HeartBeat",
        "WPDA - MSE",
        "WPDA - Pipe Torq Engr",
        "WPDA - Pseudo-MSE",
        "WPDA - Ref CCS",
        "WPDA - Ref Gamma",
        "WPDA - Ref MSE",
        "WPDA - Status",
        "WPDA - d Exponent",
        "WSGD Airflow",
        "WSGD GCIB Bd1 Chnl1 Te",
        "WSGD GCIB Bd2 Chnl1 Te",
        "WSGD_METHANE",
        "WSGD_PROPANE",
        "WSGD Pump Shutdown",
        "WSGD Total Gas",
        "Washout Factor",
        "Water Out",
        "Water Pit",
        "yieldpoint",
        "r300"
    ],
    "mnemonics": {
        "MISCELLANEOUS": [
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "DBA Drillstring Volume",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Drillstring Volume",
                "src_mnemonic": "DBA Drillstring Volume",
                "src_unit": "bbl",
                "std_curve_description": null,
                "std_mnemonic_displayname": null,
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "DBA Rotary Str Stick Slip Belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Rotary Str Stick Slip Belief",
                "src_mnemonic": "DBA Rotary Str Stick Slip Belief",
                "src_unit": "unitless",
                "std_curve_description": null,
                "std_mnemonic_displayname": null,
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "FD Svy Time",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "FD Svy Time",
                "src_mnemonic": "FD Svy Time",
                "src_unit": "hr",
                "std_curve_description": "FD Svy Time",
                "std_mnemonic_displayname": null,
                "std_unit": "hr"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "KZ Data Health - Static",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Data Health - Static",
                "src_mnemonic": "KZ Data Health - Static",
                "src_unit": "unitless",
                "std_curve_description": null,
                "std_mnemonic_displayname": null,
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "KZ Execution Timer",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Execution Timer",
                "src_mnemonic": "KZ Execution Timer",
                "src_unit": "s",
                "std_curve_description": null,
                "std_mnemonic_displayname": null,
                "std_unit": "s"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "KZ Timer Delta",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Timer Delta",
                "src_mnemonic": "KZ Timer Delta",
                "src_unit": "s",
                "std_curve_description": null,
                "std_mnemonic_displayname": null,
                "std_unit": "s"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "Lst Jnt Time",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Lst Jnt Time",
                "src_mnemonic": "Lst Jnt Time",
                "src_unit": "s",
                "std_curve_description": null,
                "std_mnemonic_displayname": null,
                "std_unit": "s"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "Rig Activity Time Zone",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Rig Activity Time Zone",
                "src_mnemonic": "Rig Activity Time Zone",
                "src_unit": "unitless",
                "std_curve_description": null,
                "std_mnemonic_displayname": null,
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "Run Jnt Time",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Run Jnt Time",
                "src_mnemonic": "Run Jnt Time",
                "src_unit": "s",
                "std_curve_description": null,
                "std_mnemonic_displayname": null,
                "std_unit": "s"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "Time On Bottom",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Time On Bottom",
                "src_mnemonic": "Time On Bottom",
                "src_unit": "hr",
                "std_curve_description": null,
                "std_mnemonic_displayname": null,
                "std_unit": "hr"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "Time On Job",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Time On Job",
                "src_mnemonic": "Time On Job",
                "src_unit": "hr",
                "std_curve_description": null,
                "std_mnemonic_displayname": null,
                "std_unit": "hr"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "Time On Slip",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Time On Slip",
                "src_mnemonic": "Time On Slip",
                "src_unit": "s",
                "std_curve_description": null,
                "std_mnemonic_displayname": null,
                "std_unit": "s"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "WC Time Interval",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Time Interval",
                "src_mnemonic": "WC Time Interval",
                "src_unit": "min",
                "std_curve_description": null,
                "std_mnemonic_displayname": null,
                "std_unit": "min"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "WC Time Tick",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Time Tick",
                "src_mnemonic": "WC Time Tick",
                "src_unit": "Count",
                "std_curve_description": null,
                "std_mnemonic_displayname": null,
                "std_unit": "Count"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "acc_drill_strks",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Strks - Acc Drill",
                "src_mnemonic": "ACC_DRILL_STRKS",
                "src_unit": "strokes",
                "std_curve_description": "Strks - Acc Drill",
                "std_mnemonic_displayname": "Strks - Acc Drill",
                "std_unit": "strokes"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "acc_fill_strks",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Strks - Acc Fill",
                "src_mnemonic": "ACC_FILL_STRKS",
                "src_unit": "strokes",
                "std_curve_description": "Strks - Acc Fill",
                "std_mnemonic_displayname": "Strks - Acc Fill",
                "std_unit": "strokes"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "accum_trip_in",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Accum Trip In",
                "src_mnemonic": "ACCUM_TRIP_IN",
                "src_unit": "bbl",
                "std_curve_description": "Accum Trip In",
                "std_mnemonic_displayname": "Accum Trip In",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "accum_trip_out",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Accum Trip Out",
                "src_mnemonic": "ACCUM_TRIP_OUT",
                "src_unit": "bbl",
                "std_curve_description": "Accum Trip Out",
                "std_mnemonic_displayname": "Accum Trip Out",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ad rop",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "AD ROP",
                "src_mnemonic": "AD ROP",
                "src_unit": "ft/h",
                "std_curve_description": "AD ROP",
                "std_mnemonic_displayname": "AD ROP",
                "std_unit": "ft/h"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "air pressure",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Air Pressure",
                "src_mnemonic": "Air Pressure",
                "src_unit": "psi",
                "std_curve_description": "Air Pressure",
                "std_mnemonic_displayname": "Air Pressure",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "alt_gravity_toolface",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Toolface Thresh",
                "src_mnemonic": "ALT_GRAVITY_TOOLFACE",
                "src_unit": "rad",
                "std_curve_description": "Toolface Thresh",
                "std_mnemonic_displayname": "Toolface Thresh",
                "std_unit": "dega"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "anl_tmp",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Bttm Ann Temp",
                "src_mnemonic": "ANL_TMP",
                "src_unit": "degF",
                "std_curve_description": "Bttm Ann Temp",
                "std_mnemonic_displayname": "Bttm Ann Temp",
                "std_unit": "degF"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "average joint length",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Average Joint Length",
                "src_mnemonic": "Average Joint Length",
                "src_unit": "m",
                "std_curve_description": "Average Joint Length",
                "std_mnemonic_displayname": "Average Joint Length",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "avg_rop_ft_hr",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "ROP - Average",
                "src_mnemonic": "AVG_ROP_FT_HR",
                "src_unit": "ft/h",
                "std_curve_description": "ROP - Average",
                "std_mnemonic_displayname": "ROP - Average",
                "std_unit": "ft/h"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "back pressure",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Back Pressure",
                "src_mnemonic": "Back Pressure",
                "src_unit": "psi",
                "std_curve_description": "Back Pressure",
                "std_mnemonic_displayname": "Back Pressure",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "basic mse",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Basic MSE",
                "src_mnemonic": "Basic MSE",
                "src_unit": "kpsi",
                "std_curve_description": "Basic MSE",
                "std_mnemonic_displayname": "Basic MSE",
                "std_unit": "kpsi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "bit tvd - wits",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Bit TVD - WITS",
                "src_mnemonic": "Bit TVD - WITS",
                "src_unit": "m",
                "std_curve_description": "Bit TVD - WITS",
                "std_mnemonic_displayname": "Bit TVD - WITS",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "bit_dpt_md",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Bit TVD",
                "src_mnemonic": "BIT_DPT_MD",
                "src_unit": "m",
                "std_curve_description": "Bit TVD",
                "std_mnemonic_displayname": "Bit TVD",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "bit_on_btm",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Bit Status",
                "src_mnemonic": "BIT_ON_BTM",
                "src_unit": "Bottom",
                "std_curve_description": "Bit Status",
                "std_mnemonic_displayname": "Bit Status",
                "std_unit": null
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "block_speed",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Block Velocity",
                "src_mnemonic": "BLOCK_SPEED",
                "src_unit": "ft/s",
                "std_curve_description": "Block Velocity",
                "std_mnemonic_displayname": "Block Velocity",
                "std_unit": "ft/s"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "bttm ann press",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Bttm Ann Press",
                "src_mnemonic": "Bttm Ann Press",
                "src_unit": "psi",
                "std_curve_description": "Bttm Ann Press",
                "std_mnemonic_displayname": "Bttm Ann Press",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "bttm pipe temp",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Bttm Pipe Temp",
                "src_mnemonic": "Bttm Pipe Temp",
                "src_unit": "degF",
                "std_curve_description": "Bttm Pipe Temp",
                "std_mnemonic_displayname": "Bttm Pipe Temp",
                "std_unit": "degF"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "choke position 2",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Choke Position 2",
                "src_mnemonic": "Choke Position 2",
                "src_unit": "%",
                "std_curve_description": "Choke Position 2",
                "std_mnemonic_displayname": "Choke Position 2",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "choke_posn",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Choke Position 1",
                "src_mnemonic": "CHOKE_POSN",
                "src_unit": "%",
                "std_curve_description": "Choke Position 1",
                "std_mnemonic_displayname": "Choke Position 1",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "co. man g/l",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Co. Man G/L",
                "src_mnemonic": "Co. Man G/L",
                "src_unit": "bbl",
                "std_curve_description": "Co. Man G/L",
                "std_mnemonic_displayname": "Co. Man G/L",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "cont az",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Cont AZ",
                "src_mnemonic": "Cont AZ",
                "src_unit": "rad",
                "std_curve_description": "Cont AZ",
                "std_mnemonic_displayname": "Cont AZ",
                "std_unit": "dega"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "cont inc",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Cont INC",
                "src_mnemonic": "Cont INC",
                "src_unit": "rad",
                "std_curve_description": "Cont INC",
                "std_mnemonic_displayname": "Cont INC",
                "std_unit": "dega"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "continuous azimuth",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Continuous Azimuth",
                "src_mnemonic": "Continuous Azimuth",
                "src_unit": "rad",
                "std_curve_description": "Continuous Azimuth",
                "std_mnemonic_displayname": "Continuous Azimuth",
                "std_unit": "dega"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "continuous inclination",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Continuous Inclination",
                "src_mnemonic": "Continuous Inclination",
                "src_unit": "rad",
                "std_curve_description": "Continuous Inclination",
                "std_mnemonic_displayname": "Continuous Inclination",
                "std_unit": "dega"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "counter a",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Counter A",
                "src_mnemonic": "Counter A",
                "src_unit": "strokes",
                "std_curve_description": "Counter A",
                "std_mnemonic_displayname": "Counter A",
                "std_unit": "strokes"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "counter b",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Counter B",
                "src_mnemonic": "Counter B",
                "src_unit": "strokes",
                "std_curve_description": "Counter B",
                "std_mnemonic_displayname": "Counter B",
                "std_unit": "strokes"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "cut_foot_rop_ft_hr",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "ROP - Cut Unit",
                "src_mnemonic": "CUT_FOOT_ROP_FT_HR",
                "src_unit": "ft/h",
                "std_curve_description": "ROP - Cut Unit",
                "std_mnemonic_displayname": "ROP - Cut Unit",
                "std_unit": "ft/h"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "daily_ton_mile",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Daily Accum Wear",
                "src_mnemonic": "DAILY_TON_MILE",
                "src_unit": "tonUS·mi",
                "std_curve_description": "Daily Accum Wear",
                "std_mnemonic_displayname": "Daily Accum Wear",
                "std_unit": null
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "daq version",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DAQ Version",
                "src_mnemonic": "DAQ Version",
                "src_unit": "unitless",
                "std_curve_description": "DAQ Version",
                "std_mnemonic_displayname": "DAQ Version",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dateandtime",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "FD Svy Time",
                "src_mnemonic": "DateAndTime",
                "src_unit": null,
                "std_curve_description": "FD Svy Time",
                "std_mnemonic_displayname": "FD Svy Time",
                "std_unit": null
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba 3d tortuosity index",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA 3D Tortuosity Index",
                "src_mnemonic": "DBA 3D Tortuosity Index",
                "src_unit": "unitless",
                "std_curve_description": "DBA 3D Tortuosity Index",
                "std_mnemonic_displayname": "DBA 3D Tortuosity Index",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba 3d tortuosity index n feet",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA 3D Tortuosity Index N Feet",
                "src_mnemonic": "DBA 3D Tortuosity Index N Feet",
                "src_unit": "unitless",
                "std_curve_description": "DBA 3D Tortuosity Index N Feet",
                "std_mnemonic_displayname": "DBA 3D Tortuosity Index N Feet",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba abnormal gain belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Abnormal Gain Belief",
                "src_mnemonic": "DBA Abnormal Gain Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Abnormal Gain Belief",
                "std_mnemonic_displayname": "DBA Abnormal Gain Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba abnormal loss belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Abnormal Loss Belief",
                "src_mnemonic": "DBA Abnormal Loss Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Abnormal Loss Belief",
                "std_mnemonic_displayname": "DBA Abnormal Loss Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba active pit change volume",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Active Pit Change Volume",
                "src_mnemonic": "DBA Active Pit Change Volume",
                "src_unit": "bbl",
                "std_curve_description": "DBA Active Pit Change Volume",
                "std_mnemonic_displayname": "DBA Active Pit Change Volume",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba agg diff press spike index",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Agg Diff Press Spike Index",
                "src_mnemonic": "DBA Agg Diff Press Spike Index",
                "src_unit": "unitless",
                "std_curve_description": "DBA Agg Diff Press Spike Index",
                "std_mnemonic_displayname": "DBA Agg Diff Press Spike Index",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba annular press bit est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Annular Press Bit Est",
                "src_mnemonic": "DBA Annular Press Bit Est",
                "src_unit": "psi",
                "std_curve_description": "DBA Annular Press Bit Est",
                "std_mnemonic_displayname": "DBA Annular Press Bit Est",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba azimuthal tortuosity index",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Azimuthal Tortuosity Index",
                "src_mnemonic": "DBA Azimuthal Tortuosity Index",
                "src_unit": "unitless",
                "std_curve_description": "DBA Azimuthal Tortuosity Index",
                "std_mnemonic_displayname": "DBA Azimuthal Tortuosity Index",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba bha run number",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA BHA Run Number",
                "src_mnemonic": "DBA BHA Run Number",
                "src_unit": "unitless",
                "std_curve_description": "DBA BHA Run Number",
                "std_mnemonic_displayname": "DBA BHA Run Number",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba bit depth est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Bit Depth Est",
                "src_mnemonic": "DBA Bit Depth Est",
                "src_unit": "m",
                "std_curve_description": "DBA Bit Depth Est",
                "std_mnemonic_displayname": "DBA Bit Depth Est",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba bit depth fault alert",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Bit Depth Fault Alert",
                "src_mnemonic": "DBA Bit Depth Fault Alert",
                "src_unit": "unitless",
                "std_curve_description": "DBA Bit Depth Fault Alert",
                "std_mnemonic_displayname": "DBA Bit Depth Fault Alert",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba bit depth fault code",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Bit Depth Fault Code",
                "src_mnemonic": "DBA Bit Depth Fault Code",
                "src_unit": "unitless",
                "std_curve_description": "DBA Bit Depth Fault Code",
                "std_mnemonic_displayname": "DBA Bit Depth Fault Code",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba bit position heave comp",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Bit Position Heave Comp",
                "src_mnemonic": "DBA Bit Position Heave Comp",
                "src_unit": "m",
                "std_curve_description": "DBA Bit Position Heave Comp",
                "std_mnemonic_displayname": "DBA Bit Position Heave Comp",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba bit rpm est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Bit RPM Est",
                "src_mnemonic": "DBA Bit RPM Est",
                "src_unit": "rpm",
                "std_curve_description": "DBA Bit RPM Est",
                "std_mnemonic_displayname": "DBA Bit RPM Est",
                "std_unit": "rpm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba bit wear factor",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Bit Wear Factor",
                "src_mnemonic": "DBA Bit Wear Factor",
                "src_unit": "unitless",
                "std_curve_description": "DBA Bit Wear Factor",
                "std_mnemonic_displayname": "DBA Bit Wear Factor",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba bit weight autozero",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Bit Weight Autozero",
                "src_mnemonic": "DBA Bit Weight Autozero",
                "src_unit": "klb",
                "std_curve_description": "DBA Bit Weight Autozero",
                "std_mnemonic_displayname": "DBA Bit Weight Autozero",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba bit weight est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Bit Weight Est",
                "src_mnemonic": "DBA Bit Weight Est",
                "src_unit": "klb",
                "std_curve_description": "DBA Bit Weight Est",
                "std_mnemonic_displayname": "DBA Bit Weight Est",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba bit weight fault alert",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Bit Weight Fault Alert",
                "src_mnemonic": "DBA Bit Weight Fault Alert",
                "src_unit": "unitless",
                "std_curve_description": "DBA Bit Weight Fault Alert",
                "std_mnemonic_displayname": "DBA Bit Weight Fault Alert",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba bit weight fault code",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Bit Weight Fault Code",
                "src_mnemonic": "DBA Bit Weight Fault Code",
                "src_unit": "unitless",
                "std_curve_description": "DBA Bit Weight Fault Code",
                "std_mnemonic_displayname": "DBA Bit Weight Fault Code",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba bit weight min hel buckling",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Bit Weight Min Hel Buckling",
                "src_mnemonic": "DBA Bit Weight Min Hel Buckling",
                "src_unit": "klb",
                "std_curve_description": "DBA Bit Weight Min Hel Buckling",
                "std_mnemonic_displayname": "DBA Bit Weight Min Hel Buckling",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba block height est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Block Height Est",
                "src_mnemonic": "DBA Block Height Est",
                "src_unit": "m",
                "std_curve_description": "DBA Block Height Est",
                "std_mnemonic_displayname": "DBA Block Height Est",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba block height fault alert",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Block Height Fault Alert",
                "src_mnemonic": "DBA Block Height Fault Alert",
                "src_unit": "unitless",
                "std_curve_description": "DBA Block Height Fault Alert",
                "std_mnemonic_displayname": "DBA Block Height Fault Alert",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba block height fault code",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Block Height Fault Code",
                "src_mnemonic": "DBA Block Height Fault Code",
                "src_unit": "unitless",
                "std_curve_description": "DBA Block Height Fault Code",
                "std_mnemonic_displayname": "DBA Block Height Fault Code",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba block weight calibrated",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Block Weight Calibrated",
                "src_mnemonic": "DBA Block Weight Calibrated",
                "src_unit": "klb",
                "std_curve_description": "DBA Block Weight Calibrated",
                "std_mnemonic_displayname": "DBA Block Weight Calibrated",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba bottom to surface strokes",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Bottom to Surface Strokes",
                "src_mnemonic": "DBA Bottom to Surface Strokes",
                "src_unit": "unitless",
                "std_curve_description": "DBA Bottom to Surface Strokes",
                "std_mnemonic_displayname": "DBA Bottom to Surface Strokes",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba build rate",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Build Rate",
                "src_mnemonic": "DBA Build Rate",
                "src_unit": "dega/100ft",
                "std_curve_description": "DBA Build Rate",
                "std_mnemonic_displayname": "DBA Build Rate",
                "std_unit": "deg/100ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba casing annulus volume",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Casing Annulus Volume",
                "src_mnemonic": "DBA Casing Annulus Volume",
                "src_unit": "bbl",
                "std_curve_description": "DBA Casing Annulus Volume",
                "std_mnemonic_displayname": "DBA Casing Annulus Volume",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba casing run number",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Casing Run Number",
                "src_mnemonic": "DBA Casing Run Number",
                "src_unit": "unitless",
                "std_curve_description": "DBA Casing Run Number",
                "std_mnemonic_displayname": "DBA Casing Run Number",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba cleansed rop",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Cleansed ROP",
                "src_mnemonic": "DBA Cleansed ROP",
                "src_unit": "ft/h",
                "std_curve_description": "DBA Cleansed ROP",
                "std_mnemonic_displayname": "DBA Cleansed ROP",
                "std_unit": "ft/h"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba delta flow est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Delta Flow Est",
                "src_mnemonic": "DBA Delta Flow Est",
                "src_unit": "galUS/min",
                "std_curve_description": "DBA Delta Flow Est",
                "std_mnemonic_displayname": "DBA Delta Flow Est",
                "std_unit": "galUS/min"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba depth of cut est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Depth Of Cut Est",
                "src_mnemonic": "DBA Depth Of Cut Est",
                "src_unit": "in",
                "std_curve_description": "DBA Depth Of Cut Est",
                "std_mnemonic_displayname": "DBA Depth Of Cut Est",
                "std_unit": "in"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba diff press est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Diff Press Est",
                "src_mnemonic": "DBA Diff Press Est",
                "src_unit": "psi",
                "std_curve_description": "DBA Diff Press Est",
                "std_mnemonic_displayname": "DBA Diff Press Est",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba diff press spike belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Diff Press Spike Belief",
                "src_mnemonic": "DBA Diff Press Spike Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Diff Press Spike Belief",
                "std_mnemonic_displayname": "DBA Diff Press Spike Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba dls",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA DLS",
                "src_mnemonic": "DBA DLS",
                "src_unit": "dega/100ft",
                "std_curve_description": "DBA DLS",
                "std_mnemonic_displayname": "DBA DLS",
                "std_unit": "deg/100ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba dls cumulative",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA DLS Cumulative",
                "src_mnemonic": "DBA DLS Cumulative",
                "src_unit": "rad",
                "std_curve_description": "DBA DLS Cumulative",
                "std_mnemonic_displayname": "DBA DLS Cumulative",
                "std_unit": "dega"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba downhole bit aggressiveness",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Downhole Bit Aggressiveness",
                "src_mnemonic": "DBA Downhole Bit Aggressiveness",
                "src_unit": "unitless",
                "std_curve_description": "DBA Downhole Bit Aggressiveness",
                "std_mnemonic_displayname": "DBA Downhole Bit Aggressiveness",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba downlink belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Downlink Belief",
                "src_mnemonic": "DBA Downlink Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Downlink Belief",
                "std_mnemonic_displayname": "DBA Downlink Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba drilling loss gain volume",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Drilling Loss Gain Volume",
                "src_mnemonic": "DBA Drilling Loss Gain Volume",
                "src_unit": "bbl",
                "std_curve_description": "DBA Drilling Loss Gain Volume",
                "std_mnemonic_displayname": "DBA Drilling Loss Gain Volume",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba drillstring drag",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Drillstring Drag",
                "src_mnemonic": "DBA Drillstring Drag",
                "src_unit": "klb",
                "std_curve_description": "DBA Drillstring Drag",
                "std_mnemonic_displayname": "DBA Drillstring Drag",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba drillstring torque",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Drillstring Torque",
                "src_mnemonic": "DBA Drillstring Torque",
                "src_unit": "ft.lbf",
                "std_curve_description": "DBA Drillstring Torque",
                "std_mnemonic_displayname": "DBA Drillstring Torque",
                "std_unit": "kft.lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba drillstring volume",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Drillstring Volume",
                "src_mnemonic": "DBA Drillstring Volume",
                "src_unit": "bbl",
                "std_curve_description": "DBA Drillstring Volume",
                "std_mnemonic_displayname": "DBA Drillstring Volume",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba ecd bit est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA ECD Bit Est",
                "src_mnemonic": "DBA ECD Bit Est",
                "src_unit": "lb/galUS",
                "std_curve_description": "DBA ECD Bit Est",
                "std_mnemonic_displayname": "DBA ECD Bit Est",
                "std_unit": "lbf/galUS"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba ecd shoe est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA ECD Shoe Est",
                "src_mnemonic": "DBA ECD Shoe Est",
                "src_unit": "lb/galUS",
                "std_curve_description": "DBA ECD Shoe Est",
                "std_mnemonic_displayname": "DBA ECD Shoe Est",
                "std_unit": "lbf/galUS"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba ew",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA EW",
                "src_mnemonic": "DBA EW",
                "src_unit": "m",
                "std_curve_description": "DBA EW",
                "std_mnemonic_displayname": "DBA EW",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba flag active pit change",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Flag Active Pit Change",
                "src_mnemonic": "DBA Flag Active Pit Change",
                "src_unit": "unitless",
                "std_curve_description": "DBA Flag Active Pit Change",
                "std_mnemonic_displayname": "DBA Flag Active Pit Change",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba flag connection flowback",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Flag Connection Flowback",
                "src_mnemonic": "DBA Flag Connection Flowback",
                "src_unit": "unitless",
                "std_curve_description": "DBA Flag Connection Flowback",
                "std_mnemonic_displayname": "DBA Flag Connection Flowback",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba flag mud addition",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Flag Mud Addition",
                "src_mnemonic": "DBA Flag Mud Addition",
                "src_unit": "unitless",
                "std_curve_description": "DBA Flag Mud Addition",
                "std_mnemonic_displayname": "DBA Flag Mud Addition",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba flag mud removal",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Flag Mud Removal",
                "src_mnemonic": "DBA Flag Mud Removal",
                "src_unit": "unitless",
                "std_curve_description": "DBA Flag Mud Removal",
                "std_mnemonic_displayname": "DBA Flag Mud Removal",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba flag pump activity",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Flag Pump Activity",
                "src_mnemonic": "DBA Flag Pump Activity",
                "src_unit": "unitless",
                "std_curve_description": "DBA Flag Pump Activity",
                "std_mnemonic_displayname": "DBA Flag Pump Activity",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba flow in rate critical est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Flow In Rate Critical Est",
                "src_mnemonic": "DBA Flow In Rate Critical Est",
                "src_unit": "galUS/min",
                "std_curve_description": "DBA Flow In Rate Critical Est",
                "std_mnemonic_displayname": "DBA Flow In Rate Critical Est",
                "std_unit": "galUS/min"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba flow in rate est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Flow In Rate Est",
                "src_mnemonic": "DBA Flow In Rate Est",
                "src_unit": "galUS/min",
                "std_curve_description": "DBA Flow In Rate Est",
                "std_mnemonic_displayname": "DBA Flow In Rate Est",
                "std_unit": "galUS/min"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba flow in rate fault alert",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Flow In Rate Fault Alert",
                "src_mnemonic": "DBA Flow In Rate Fault Alert",
                "src_unit": "unitless",
                "std_curve_description": "DBA Flow In Rate Fault Alert",
                "std_mnemonic_displayname": "DBA Flow In Rate Fault Alert",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba flow in rate fault code",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Flow In Rate Fault Code",
                "src_mnemonic": "DBA Flow In Rate Fault Code",
                "src_unit": "unitless",
                "std_curve_description": "DBA Flow In Rate Fault Code",
                "std_mnemonic_displayname": "DBA Flow In Rate Fault Code",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba flow out rate est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Flow Out Rate Est",
                "src_mnemonic": "DBA Flow Out Rate Est",
                "src_unit": "unitless",
                "std_curve_description": "DBA Flow Out Rate Est",
                "std_mnemonic_displayname": "DBA Flow Out Rate Est",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba flow out rate fault alert",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Flow Out Rate Fault Alert",
                "src_mnemonic": "DBA Flow Out Rate Fault Alert",
                "src_unit": "unitless",
                "std_curve_description": "DBA Flow Out Rate Fault Alert",
                "std_mnemonic_displayname": "DBA Flow Out Rate Fault Alert",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba flow out rate fault code",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Flow Out Rate Fault Code",
                "src_mnemonic": "DBA Flow Out Rate Fault Code",
                "src_unit": "unitless",
                "std_curve_description": "DBA Flow Out Rate Fault Code",
                "std_mnemonic_displayname": "DBA Flow Out Rate Fault Code",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba frequent tight spots belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Frequent Tight Spots Belief",
                "src_mnemonic": "DBA Frequent Tight Spots Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Frequent Tight Spots Belief",
                "std_mnemonic_displayname": "DBA Frequent Tight Spots Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba gain loss rate",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Gain Loss Rate",
                "src_mnemonic": "DBA Gain Loss Rate",
                "src_unit": "bbl/min",
                "std_curve_description": "DBA Gain Loss Rate",
                "std_mnemonic_displayname": "DBA Gain Loss Rate",
                "std_unit": null
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba gain loss volume",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Gain Loss Volume",
                "src_mnemonic": "DBA Gain Loss Volume",
                "src_unit": "bbl",
                "std_curve_description": "DBA Gain Loss Volume",
                "std_mnemonic_displayname": "DBA Gain Loss Volume",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba heartbeat",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Heartbeat",
                "src_mnemonic": "DBA Heartbeat",
                "src_unit": "unitless",
                "std_curve_description": "DBA Heartbeat",
                "std_mnemonic_displayname": "DBA Heartbeat",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba heave compensator amplitude",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Heave Compensator Amplitude",
                "src_mnemonic": "DBA Heave Compensator Amplitude",
                "src_unit": "m",
                "std_curve_description": "DBA Heave Compensator Amplitude",
                "std_mnemonic_displayname": "DBA Heave Compensator Amplitude",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba heave compensator period",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Heave Compensator Period",
                "src_mnemonic": "DBA Heave Compensator Period",
                "src_unit": "s",
                "std_curve_description": "DBA Heave Compensator Period",
                "std_mnemonic_displayname": "DBA Heave Compensator Period",
                "std_unit": "s"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba hif",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA HIF",
                "src_mnemonic": "DBA HIF",
                "src_unit": "lb",
                "std_curve_description": "DBA HIF",
                "std_mnemonic_displayname": "DBA HIF",
                "std_unit": "lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba high hole clean eff belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA High Hole Clean Eff Belief",
                "src_mnemonic": "DBA High Hole Clean Eff Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA High Hole Clean Eff Belief",
                "std_mnemonic_displayname": "DBA High Hole Clean Eff Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba hole depth est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Hole Depth Est",
                "src_mnemonic": "DBA Hole Depth Est",
                "src_unit": "m",
                "std_curve_description": "DBA Hole Depth Est",
                "std_mnemonic_displayname": "DBA Hole Depth Est",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba hole depth fault alert",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Hole Depth Fault Alert",
                "src_mnemonic": "DBA Hole Depth Fault Alert",
                "src_unit": "unitless",
                "std_curve_description": "DBA Hole Depth Fault Alert",
                "std_mnemonic_displayname": "DBA Hole Depth Fault Alert",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba hole depth fault code",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Hole Depth Fault Code",
                "src_mnemonic": "DBA Hole Depth Fault Code",
                "src_unit": "unitless",
                "std_curve_description": "DBA Hole Depth Fault Code",
                "std_mnemonic_displayname": "DBA Hole Depth Fault Code",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba hole section",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Hole Section",
                "src_mnemonic": "DBA Hole Section",
                "src_unit": "unitless",
                "std_curve_description": "DBA Hole Section",
                "std_mnemonic_displayname": "DBA Hole Section",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba hook load est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Hook Load Est",
                "src_mnemonic": "DBA Hook Load Est",
                "src_unit": "klb",
                "std_curve_description": "DBA Hook Load Est",
                "std_mnemonic_displayname": "DBA Hook Load Est",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba hook load max fault alert",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Hook Load Max Fault Alert",
                "src_mnemonic": "DBA Hook Load Max Fault Alert",
                "src_unit": "unitless",
                "std_curve_description": "DBA Hook Load Max Fault Alert",
                "std_mnemonic_displayname": "DBA Hook Load Max Fault Alert",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba hook load max fault code",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Hook Load Max Fault Code",
                "src_mnemonic": "DBA Hook Load Max Fault Code",
                "src_unit": "unitless",
                "std_curve_description": "DBA Hook Load Max Fault Code",
                "std_mnemonic_displayname": "DBA Hook Load Max Fault Code",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba hook load modeled",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Hook Load Modeled",
                "src_mnemonic": "DBA Hook Load Modeled",
                "src_unit": "klb",
                "std_curve_description": "DBA Hook Load Modeled",
                "std_mnemonic_displayname": "DBA Hook Load Modeled",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba hsi",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA HSI",
                "src_mnemonic": "DBA HSI",
                "src_unit": "hhp/in2",
                "std_curve_description": "DBA HSI",
                "std_mnemonic_displayname": "DBA HSI",
                "std_unit": "hhp/in2"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba hyd coeff flow in",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Hyd Coeff Flow In",
                "src_mnemonic": "DBA Hyd Coeff Flow In",
                "src_unit": "unitless",
                "std_curve_description": "DBA Hyd Coeff Flow In",
                "std_mnemonic_displayname": "DBA Hyd Coeff Flow In",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba hyd coeff flow out",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Hyd Coeff Flow Out",
                "src_mnemonic": "DBA Hyd Coeff Flow Out",
                "src_unit": "unitless",
                "std_curve_description": "DBA Hyd Coeff Flow Out",
                "std_mnemonic_displayname": "DBA Hyd Coeff Flow Out",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba inclination tortuosity index",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Inclination Tortuosity Index",
                "src_mnemonic": "DBA Inclination Tortuosity Index",
                "src_unit": "unitless",
                "std_curve_description": "DBA Inclination Tortuosity Index",
                "std_mnemonic_displayname": "DBA Inclination Tortuosity Index",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba mse motor",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA MSE Motor",
                "src_mnemonic": "DBA MSE Motor",
                "src_unit": "psi",
                "std_curve_description": "DBA MSE Motor",
                "std_mnemonic_displayname": "DBA MSE Motor",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba mse surface",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA MSE Surface",
                "src_mnemonic": "DBA MSE Surface",
                "src_unit": "psi",
                "std_curve_description": "DBA MSE Surface",
                "std_mnemonic_displayname": "DBA MSE Surface",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba mse total",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA MSE Total",
                "src_mnemonic": "DBA MSE Total",
                "src_unit": "psi",
                "std_curve_description": "DBA MSE Total",
                "std_mnemonic_displayname": "DBA MSE Total",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba mud addition belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Mud Addition Belief",
                "src_mnemonic": "DBA Mud Addition Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Mud Addition Belief",
                "std_mnemonic_displayname": "DBA Mud Addition Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba mud addition removal volume",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Mud Addition Removal Volume",
                "src_mnemonic": "DBA Mud Addition Removal Volume",
                "src_unit": "bbl",
                "std_curve_description": "DBA Mud Addition Removal Volume",
                "std_mnemonic_displayname": "DBA Mud Addition Removal Volume",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba mud motor torque",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Mud Motor Torque",
                "src_mnemonic": "DBA Mud Motor Torque",
                "src_unit": "ft.lbf",
                "std_curve_description": "DBA Mud Motor Torque",
                "std_mnemonic_displayname": "DBA Mud Motor Torque",
                "std_unit": "kft.lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba mud removal belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Mud Removal Belief",
                "src_mnemonic": "DBA Mud Removal Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Mud Removal Belief",
                "std_mnemonic_displayname": "DBA Mud Removal Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba mud report mud density",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Mud Report Mud Density",
                "src_mnemonic": "DBA Mud Report Mud Density",
                "src_unit": "lb/galUS",
                "std_curve_description": "DBA Mud Report Mud Density",
                "std_mnemonic_displayname": "DBA Mud Report Mud Density",
                "std_unit": "lbf/galUS"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba mud volume connection delta",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Mud Volume Connection Delta",
                "src_mnemonic": "DBA Mud Volume Connection Delta",
                "src_unit": "bbl",
                "std_curve_description": "DBA Mud Volume Connection Delta",
                "std_mnemonic_displayname": "DBA Mud Volume Connection Delta",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba mud volume total est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Mud Volume Total Est",
                "src_mnemonic": "DBA Mud Volume Total Est",
                "src_unit": "bbl",
                "std_curve_description": "DBA Mud Volume Total Est",
                "std_mnemonic_displayname": "DBA Mud Volume Total Est",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba mud volume total fault alert",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Mud Volume Total Fault Alert",
                "src_mnemonic": "DBA Mud Volume Total Fault Alert",
                "src_unit": "unitless",
                "std_curve_description": "DBA Mud Volume Total Fault Alert",
                "std_mnemonic_displayname": "DBA Mud Volume Total Fault Alert",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba mud volume total fault code",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Mud Volume Total Fault Code",
                "src_mnemonic": "DBA Mud Volume Total Fault Code",
                "src_unit": "unitless",
                "std_curve_description": "DBA Mud Volume Total Fault Code",
                "std_mnemonic_displayname": "DBA Mud Volume Total Fault Code",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba ns",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA NS",
                "src_mnemonic": "DBA NS",
                "src_unit": "m",
                "std_curve_description": "DBA NS",
                "std_mnemonic_displayname": "DBA NS",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba open hole annulus volume",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Open Hole Annulus Volume",
                "src_mnemonic": "DBA Open Hole Annulus Volume",
                "src_unit": "bbl",
                "std_curve_description": "DBA Open Hole Annulus Volume",
                "std_mnemonic_displayname": "DBA Open Hole Annulus Volume",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba open hole ff",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Open Hole FF",
                "src_mnemonic": "DBA Open Hole FF",
                "src_unit": "unitless",
                "std_curve_description": "DBA Open Hole FF",
                "std_mnemonic_displayname": "DBA Open Hole FF",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba overpull amount",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Overpull Amount",
                "src_mnemonic": "DBA Overpull Amount",
                "src_unit": "klb",
                "std_curve_description": "DBA Overpull Amount",
                "std_mnemonic_displayname": "DBA Overpull Amount",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba overpull belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Overpull Belief",
                "src_mnemonic": "DBA Overpull Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Overpull Belief",
                "std_mnemonic_displayname": "DBA Overpull Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba packoff belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Packoff Belief",
                "src_mnemonic": "DBA Packoff Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Packoff Belief",
                "std_mnemonic_displayname": "DBA Packoff Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba pipe rock percentage",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Pipe Rock Percentage",
                "src_mnemonic": "DBA Pipe Rock Percentage",
                "src_unit": "%",
                "std_curve_description": "DBA Pipe Rock Percentage",
                "std_mnemonic_displayname": "DBA Pipe Rock Percentage",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba pipe rock period",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Pipe Rock Period",
                "src_mnemonic": "DBA Pipe Rock Period",
                "src_unit": "s",
                "std_curve_description": "DBA Pipe Rock Period",
                "std_mnemonic_displayname": "DBA Pipe Rock Period",
                "std_unit": "s"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba pipe rock rpm",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Pipe Rock RPM",
                "src_mnemonic": "DBA Pipe Rock RPM",
                "src_unit": "rpm",
                "std_curve_description": "DBA Pipe Rock RPM",
                "std_mnemonic_displayname": "DBA Pipe Rock RPM",
                "std_unit": "rpm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba pump activity volume",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Pump Activity Volume",
                "src_mnemonic": "DBA Pump Activity Volume",
                "src_unit": "bbl",
                "std_curve_description": "DBA Pump Activity Volume",
                "std_mnemonic_displayname": "DBA Pump Activity Volume",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba pump failure belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Pump Failure Belief",
                "src_mnemonic": "DBA Pump Failure Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Pump Failure Belief",
                "std_mnemonic_displayname": "DBA Pump Failure Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba pump press est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Pump Press Est",
                "src_mnemonic": "DBA Pump Press Est",
                "src_unit": "psi",
                "std_curve_description": "DBA Pump Press Est",
                "std_mnemonic_displayname": "DBA Pump Press Est",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba pump press fault code",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Pump Press Fault Code",
                "src_mnemonic": "DBA Pump Press Fault Code",
                "src_unit": "unitless",
                "std_curve_description": "DBA Pump Press Fault Code",
                "std_mnemonic_displayname": "DBA Pump Press Fault Code",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba pump start belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Pump Start Belief",
                "src_mnemonic": "DBA Pump Start Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Pump Start Belief",
                "std_mnemonic_displayname": "DBA Pump Start Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba pump stop belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Pump Stop Belief",
                "src_mnemonic": "DBA Pump Stop Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Pump Stop Belief",
                "std_mnemonic_displayname": "DBA Pump Stop Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba rigstate",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA RigState",
                "src_mnemonic": "DBA RigState",
                "src_unit": "unitless",
                "std_curve_description": "DBA RigState",
                "std_mnemonic_displayname": "DBA RigState",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba rop cut depth",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA ROP Cut Depth",
                "src_mnemonic": "DBA ROP Cut Depth",
                "src_unit": "ft/h",
                "std_curve_description": "DBA ROP Cut Depth",
                "std_mnemonic_displayname": "DBA ROP Cut Depth",
                "std_unit": "ft/h"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba rop instantaneous",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA ROP Instantaneous",
                "src_mnemonic": "DBA ROP Instantaneous",
                "src_unit": "ft/h",
                "std_curve_description": "DBA ROP Instantaneous",
                "std_mnemonic_displayname": "DBA ROP Instantaneous",
                "std_unit": "ft/h"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba rotary bit balling belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Rotary Bit Balling Belief",
                "src_mnemonic": "DBA Rotary Bit Balling Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Rotary Bit Balling Belief",
                "std_mnemonic_displayname": "DBA Rotary Bit Balling Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba rotary bit bounce belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Rotary Bit Bounce Belief",
                "src_mnemonic": "DBA Rotary Bit Bounce Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Rotary Bit Bounce Belief",
                "std_mnemonic_displayname": "DBA Rotary Bit Bounce Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba rotary bit stick slip belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Rotary Bit Stick Slip Belief",
                "src_mnemonic": "DBA Rotary Bit Stick Slip Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Rotary Bit Stick Slip Belief",
                "std_mnemonic_displayname": "DBA Rotary Bit Stick Slip Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba rotary cone angle",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Rotary Cone Angle",
                "src_mnemonic": "DBA Rotary Cone Angle",
                "src_unit": "rad",
                "std_curve_description": "DBA Rotary Cone Angle",
                "std_mnemonic_displayname": "DBA Rotary Cone Angle",
                "std_unit": "dega"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba rotary cone offset angle",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Rotary Cone Offset Angle",
                "src_mnemonic": "DBA Rotary Cone Offset Angle",
                "src_unit": "rad",
                "std_curve_description": "DBA Rotary Cone Offset Angle",
                "std_mnemonic_displayname": "DBA Rotary Cone Offset Angle",
                "std_unit": "dega"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba rotary cone radius",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Rotary Cone Radius",
                "src_mnemonic": "DBA Rotary Cone Radius",
                "src_unit": "unitless",
                "std_curve_description": "DBA Rotary Cone Radius",
                "std_mnemonic_displayname": "DBA Rotary Cone Radius",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba rotary cone x value",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Rotary Cone X Value",
                "src_mnemonic": "DBA Rotary Cone X Value",
                "src_unit": "rpm",
                "std_curve_description": "DBA Rotary Cone X Value",
                "std_mnemonic_displayname": "DBA Rotary Cone X Value",
                "std_unit": "rpm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba rotary cone y value",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Rotary Cone Y Value",
                "src_mnemonic": "DBA Rotary Cone Y Value",
                "src_unit": "klb",
                "std_curve_description": "DBA Rotary Cone Y Value",
                "std_mnemonic_displayname": "DBA Rotary Cone Y Value",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba rotary drilling rec",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Rotary Drilling Rec",
                "src_mnemonic": "DBA Rotary Drilling Rec",
                "src_unit": "unitless",
                "std_curve_description": "DBA Rotary Drilling Rec",
                "std_mnemonic_displayname": "DBA Rotary Drilling Rec",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba rotary opt drill index avg",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Rotary Opt Drill Index Avg",
                "src_mnemonic": "DBA Rotary Opt Drill Index Avg",
                "src_unit": "unitless",
                "std_curve_description": "DBA Rotary Opt Drill Index Avg",
                "std_mnemonic_displayname": "DBA Rotary Opt Drill Index Avg",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba rotary string stick slip belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Rotary String Stick Slip Belief",
                "src_mnemonic": "DBA Rotary String Stick Slip Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Rotary String Stick Slip Belief",
                "std_mnemonic_displayname": "DBA Rotary String Stick Slip Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba rotary tot stick slip belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Rotary Tot Stick Slip Belief",
                "src_mnemonic": "DBA Rotary Tot Stick Slip Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Rotary Tot Stick Slip Belief",
                "std_mnemonic_displayname": "DBA Rotary Tot Stick Slip Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba rotary whirl belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Rotary Whirl Belief",
                "src_mnemonic": "DBA Rotary Whirl Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Rotary Whirl Belief",
                "std_mnemonic_displayname": "DBA Rotary Whirl Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba slide bit bounce belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Slide Bit Bounce Belief",
                "src_mnemonic": "DBA Slide Bit Bounce Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Slide Bit Bounce Belief",
                "std_mnemonic_displayname": "DBA Slide Bit Bounce Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba slide buckling belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Slide Buckling Belief",
                "src_mnemonic": "DBA Slide Buckling Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Slide Buckling Belief",
                "std_mnemonic_displayname": "DBA Slide Buckling Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba slide cone angle",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Slide Cone Angle",
                "src_mnemonic": "DBA Slide Cone Angle",
                "src_unit": "rad",
                "std_curve_description": "DBA Slide Cone Angle",
                "std_mnemonic_displayname": "DBA Slide Cone Angle",
                "std_unit": "dega"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba slide cone offset angle",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Slide Cone Offset Angle",
                "src_mnemonic": "DBA Slide Cone Offset Angle",
                "src_unit": "rad",
                "std_curve_description": "DBA Slide Cone Offset Angle",
                "std_mnemonic_displayname": "DBA Slide Cone Offset Angle",
                "std_unit": "dega"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba slide cone radius",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Slide Cone Radius",
                "src_mnemonic": "DBA Slide Cone Radius",
                "src_unit": "unitless",
                "std_curve_description": "DBA Slide Cone Radius",
                "std_mnemonic_displayname": "DBA Slide Cone Radius",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba slide cone x value",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Slide Cone X Value",
                "src_mnemonic": "DBA Slide Cone X Value",
                "src_unit": "galUS/min",
                "std_curve_description": "DBA Slide Cone X Value",
                "std_mnemonic_displayname": "DBA Slide Cone X Value",
                "std_unit": "galUS/min"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba slide cone y value",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Slide Cone Y Value",
                "src_mnemonic": "DBA Slide Cone Y Value",
                "src_unit": "psi",
                "std_curve_description": "DBA Slide Cone Y Value",
                "std_mnemonic_displayname": "DBA Slide Cone Y Value",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba slide drilling rec",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Slide Drilling Rec",
                "src_mnemonic": "DBA Slide Drilling Rec",
                "src_unit": "unitless",
                "std_curve_description": "DBA Slide Drilling Rec",
                "std_mnemonic_displayname": "DBA Slide Drilling Rec",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba slide high friction belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Slide High Friction Belief",
                "src_mnemonic": "DBA Slide High Friction Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Slide High Friction Belief",
                "std_mnemonic_displayname": "DBA Slide High Friction Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba slide opt drilling index",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Slide Opt Drilling Index",
                "src_mnemonic": "DBA Slide Opt Drilling Index",
                "src_unit": "unitless",
                "std_curve_description": "DBA Slide Opt Drilling Index",
                "std_mnemonic_displayname": "DBA Slide Opt Drilling Index",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba slide poor toolface belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Slide Poor Toolface Belief",
                "src_mnemonic": "DBA Slide Poor Toolface Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Slide Poor Toolface Belief",
                "std_mnemonic_displayname": "DBA Slide Poor Toolface Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba slide stick slip belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Slide Stick Slip Belief",
                "src_mnemonic": "DBA Slide Stick Slip Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Slide Stick Slip Belief",
                "std_mnemonic_displayname": "DBA Slide Stick Slip Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba standpipe press fault alert",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Standpipe Press Fault Alert",
                "src_mnemonic": "DBA Standpipe Press Fault Alert",
                "src_unit": "unitless",
                "std_curve_description": "DBA Standpipe Press Fault Alert",
                "std_mnemonic_displayname": "DBA Standpipe Press Fault Alert",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba static well belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Static Well Belief",
                "src_mnemonic": "DBA Static Well Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Static Well Belief",
                "std_mnemonic_displayname": "DBA Static Well Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba stick slip amp est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Stick Slip Amp Est",
                "src_mnemonic": "DBA Stick Slip Amp Est",
                "src_unit": "unitless",
                "std_curve_description": "DBA Stick Slip Amp Est",
                "std_mnemonic_displayname": "DBA Stick Slip Amp Est",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba string torque autozero",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA String Torque Autozero",
                "src_mnemonic": "DBA String Torque Autozero",
                "src_unit": "ft.lbf",
                "std_curve_description": "DBA String Torque Autozero",
                "std_mnemonic_displayname": "DBA String Torque Autozero",
                "std_unit": "kft.lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba string weight est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA String Weight Est",
                "src_mnemonic": "DBA String Weight Est",
                "src_unit": "klb",
                "std_curve_description": "DBA String Weight Est",
                "std_mnemonic_displayname": "DBA String Weight Est",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba subopt bit hydraulics belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Subopt Bit Hydraulics Belief",
                "src_mnemonic": "DBA Subopt Bit Hydraulics Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Subopt Bit Hydraulics Belief",
                "std_mnemonic_displayname": "DBA Subopt Bit Hydraulics Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba suff circ belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Suff Circ Belief",
                "src_mnemonic": "DBA Suff Circ Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Suff Circ Belief",
                "std_mnemonic_displayname": "DBA Suff Circ Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba suff circ no drilling belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Suff Circ No Drilling Belief",
                "src_mnemonic": "DBA Suff Circ No Drilling Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Suff Circ No Drilling Belief",
                "std_mnemonic_displayname": "DBA Suff Circ No Drilling Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba suff pipe rotation belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Suff Pipe Rotation Belief",
                "src_mnemonic": "DBA Suff Pipe Rotation Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Suff Pipe Rotation Belief",
                "std_mnemonic_displayname": "DBA Suff Pipe Rotation Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba suff pipe working belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Suff Pipe Working Belief",
                "src_mnemonic": "DBA Suff Pipe Working Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Suff Pipe Working Belief",
                "std_mnemonic_displayname": "DBA Suff Pipe Working Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba surface bit aggressiveness",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Surface Bit Aggressiveness",
                "src_mnemonic": "DBA Surface Bit Aggressiveness",
                "src_unit": "unitless",
                "std_curve_description": "DBA Surface Bit Aggressiveness",
                "std_mnemonic_displayname": "DBA Surface Bit Aggressiveness",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba surface horsepower",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Surface Horsepower",
                "src_mnemonic": "DBA Surface Horsepower",
                "src_unit": "Hp",
                "std_curve_description": "DBA Surface Horsepower",
                "std_mnemonic_displayname": "DBA Surface Horsepower",
                "std_unit": null
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba surface to bit strokes",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Surface to Bit Strokes",
                "src_mnemonic": "DBA Surface to Bit Strokes",
                "src_unit": "unitless",
                "std_curve_description": "DBA Surface to Bit Strokes",
                "std_mnemonic_displayname": "DBA Surface to Bit Strokes",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba surface to shoe strokes",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Surface to Shoe Strokes",
                "src_mnemonic": "DBA Surface to Shoe Strokes",
                "src_unit": "unitless",
                "std_curve_description": "DBA Surface to Shoe Strokes",
                "std_mnemonic_displayname": "DBA Surface to Shoe Strokes",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba sweep 1 stroke counter",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Sweep 1 Stroke Counter",
                "src_mnemonic": "DBA Sweep 1 Stroke Counter",
                "src_unit": "unitless",
                "std_curve_description": "DBA Sweep 1 Stroke Counter",
                "std_mnemonic_displayname": "DBA Sweep 1 Stroke Counter",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba sweep 2 stroke counter",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Sweep 2 Stroke Counter",
                "src_mnemonic": "DBA Sweep 2 Stroke Counter",
                "src_unit": "unitless",
                "std_curve_description": "DBA Sweep 2 Stroke Counter",
                "std_mnemonic_displayname": "DBA Sweep 2 Stroke Counter",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba sweep 3 stroke counter",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Sweep 3 Stroke Counter",
                "src_mnemonic": "DBA Sweep 3 Stroke Counter",
                "src_unit": "unitless",
                "std_curve_description": "DBA Sweep 3 Stroke Counter",
                "std_mnemonic_displayname": "DBA Sweep 3 Stroke Counter",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba sweep 4 stroke counter",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Sweep 4 Stroke Counter",
                "src_mnemonic": "DBA Sweep 4 Stroke Counter",
                "src_unit": "unitless",
                "std_curve_description": "DBA Sweep 4 Stroke Counter",
                "std_mnemonic_displayname": "DBA Sweep 4 Stroke Counter",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tnd depth",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TnD Depth",
                "src_mnemonic": "DBA TnD Depth",
                "src_unit": "m",
                "std_curve_description": "DBA TnD Depth",
                "std_mnemonic_displayname": "DBA TnD Depth",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tnd ecd bit",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TnD ECD Bit",
                "src_mnemonic": "DBA TnD ECD Bit",
                "src_unit": "lb/galUS",
                "std_curve_description": "DBA TnD ECD Bit",
                "std_mnemonic_displayname": "DBA TnD ECD Bit",
                "std_unit": "lbf/galUS"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tnd ecd shoe",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TnD ECD Shoe",
                "src_mnemonic": "DBA TnD ECD Shoe",
                "src_unit": "lb/galUS",
                "std_curve_description": "DBA TnD ECD Shoe",
                "std_mnemonic_displayname": "DBA TnD ECD Shoe",
                "std_unit": "lbf/galUS"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tnd flow in rate",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TnD Flow in Rate",
                "src_mnemonic": "DBA TnD Flow in Rate",
                "src_unit": "galUS/min",
                "std_curve_description": "DBA TnD Flow in Rate",
                "std_mnemonic_displayname": "DBA TnD Flow in Rate",
                "std_unit": "galUS/min"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tnd free rotating ff",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TnD Free Rotating FF",
                "src_mnemonic": "DBA TnD Free Rotating FF",
                "src_unit": "unitless",
                "std_curve_description": "DBA TnD Free Rotating FF",
                "std_mnemonic_displayname": "DBA TnD Free Rotating FF",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tnd free rotating torque",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TnD Free Rotating Torque",
                "src_mnemonic": "DBA TnD Free Rotating Torque",
                "src_unit": "ft.lbf",
                "std_curve_description": "DBA TnD Free Rotating Torque",
                "std_mnemonic_displayname": "DBA TnD Free Rotating Torque",
                "std_unit": "kft.lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tnd free rotating weight",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TnD Free Rotating Weight",
                "src_mnemonic": "DBA TnD Free Rotating Weight",
                "src_unit": "klb",
                "std_curve_description": "DBA TnD Free Rotating Weight",
                "std_mnemonic_displayname": "DBA TnD Free Rotating Weight",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tnd hookload deviation",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TnD Hookload Deviation",
                "src_mnemonic": "DBA TnD Hookload Deviation",
                "src_unit": "klb",
                "std_curve_description": "DBA TnD Hookload Deviation",
                "std_mnemonic_displayname": "DBA TnD Hookload Deviation",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tnd hookload reference",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TnD Hookload Reference",
                "src_mnemonic": "DBA TnD Hookload Reference",
                "src_unit": "klb",
                "std_curve_description": "DBA TnD Hookload Reference",
                "std_mnemonic_displayname": "DBA TnD Hookload Reference",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tnd overpull belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TnD Overpull Belief",
                "src_mnemonic": "DBA TnD Overpull Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA TnD Overpull Belief",
                "std_mnemonic_displayname": "DBA TnD Overpull Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tnd pick up ff",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TnD Pick Up FF",
                "src_mnemonic": "DBA TnD Pick Up FF",
                "src_unit": "unitless",
                "std_curve_description": "DBA TnD Pick Up FF",
                "std_mnemonic_displayname": "DBA TnD Pick Up FF",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tnd pick up weight",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TnD Pick Up Weight",
                "src_mnemonic": "DBA TnD Pick Up Weight",
                "src_unit": "klb",
                "std_curve_description": "DBA TnD Pick Up Weight",
                "std_mnemonic_displayname": "DBA TnD Pick Up Weight",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tnd slack off ff",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TnD Slack Off FF",
                "src_mnemonic": "DBA TnD Slack Off FF",
                "src_unit": "unitless",
                "std_curve_description": "DBA TnD Slack Off FF",
                "std_mnemonic_displayname": "DBA TnD Slack Off FF",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tnd slack off weight",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TnD Slack Off Weight",
                "src_mnemonic": "DBA TnD Slack Off Weight",
                "src_unit": "klb",
                "std_curve_description": "DBA TnD Slack Off Weight",
                "std_mnemonic_displayname": "DBA TnD Slack Off Weight",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tnd standpipe press",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TnD Standpipe Press",
                "src_mnemonic": "DBA TnD Standpipe Press",
                "src_unit": "psi",
                "std_curve_description": "DBA TnD Standpipe Press",
                "std_mnemonic_displayname": "DBA TnD Standpipe Press",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tnd top drive rpm",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TnD Top Drive RPM",
                "src_mnemonic": "DBA TnD Top Drive RPM",
                "src_unit": "rpm",
                "std_curve_description": "DBA TnD Top Drive RPM",
                "std_mnemonic_displayname": "DBA TnD Top Drive RPM",
                "std_unit": "rpm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tnd top drive torque",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TnD Top Drive Torque",
                "src_mnemonic": "DBA TnD Top Drive Torque",
                "src_unit": "ft.lbf",
                "std_curve_description": "DBA TnD Top Drive Torque",
                "std_mnemonic_displayname": "DBA TnD Top Drive Torque",
                "std_unit": "kft.lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tnd trip speed",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TnD Trip Speed",
                "src_mnemonic": "DBA TnD Trip Speed",
                "src_unit": "ft/min",
                "std_curve_description": "DBA TnD Trip Speed",
                "std_mnemonic_displayname": "DBA TnD Trip Speed",
                "std_unit": "ft/min"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tnd underpull belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TnD Underpull Belief",
                "src_mnemonic": "DBA TnD Underpull Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA TnD Underpull Belief",
                "std_mnemonic_displayname": "DBA TnD Underpull Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tob modeled",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TOB Modeled",
                "src_mnemonic": "DBA TOB Modeled",
                "src_unit": "ft.lbf",
                "std_curve_description": "DBA TOB Modeled",
                "std_mnemonic_displayname": "DBA TOB Modeled",
                "std_unit": "kft.lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba toolface eff index",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Toolface Eff Index",
                "src_mnemonic": "DBA Toolface Eff Index",
                "src_unit": "unitless",
                "std_curve_description": "DBA Toolface Eff Index",
                "std_mnemonic_displayname": "DBA Toolface Eff Index",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba top drive rpm est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Top Drive RPM Est",
                "src_mnemonic": "DBA Top Drive RPM Est",
                "src_unit": "rpm",
                "std_curve_description": "DBA Top Drive RPM Est",
                "std_mnemonic_displayname": "DBA Top Drive RPM Est",
                "std_unit": "rpm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba top drive rpm fault alert",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Top Drive RPM Fault Alert",
                "src_mnemonic": "DBA Top Drive RPM Fault Alert",
                "src_unit": "unitless",
                "std_curve_description": "DBA Top Drive RPM Fault Alert",
                "std_mnemonic_displayname": "DBA Top Drive RPM Fault Alert",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba top drive rpm fault code",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Top Drive RPM Fault Code",
                "src_mnemonic": "DBA Top Drive RPM Fault Code",
                "src_unit": "unitless",
                "std_curve_description": "DBA Top Drive RPM Fault Code",
                "std_mnemonic_displayname": "DBA Top Drive RPM Fault Code",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba top drive torque est",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Top Drive Torque Est",
                "src_mnemonic": "DBA Top Drive Torque Est",
                "src_unit": "ft.lbf",
                "std_curve_description": "DBA Top Drive Torque Est",
                "std_mnemonic_displayname": "DBA Top Drive Torque Est",
                "std_unit": "kft.lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba top drive torque fault alert",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Top Drive Torque Fault Alert",
                "src_mnemonic": "DBA Top Drive Torque Fault Alert",
                "src_unit": "unitless",
                "std_curve_description": "DBA Top Drive Torque Fault Alert",
                "std_mnemonic_displayname": "DBA Top Drive Torque Fault Alert",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba top drive torque fault code",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Top Drive Torque Fault Code",
                "src_mnemonic": "DBA Top Drive Torque Fault Code",
                "src_unit": "unitless",
                "std_curve_description": "DBA Top Drive Torque Fault Code",
                "std_mnemonic_displayname": "DBA Top Drive Torque Fault Code",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba trip speed",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Trip Speed",
                "src_mnemonic": "DBA Trip Speed",
                "src_unit": "ft/min",
                "std_curve_description": "DBA Trip Speed",
                "std_mnemonic_displayname": "DBA Trip Speed",
                "std_unit": "ft/min"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tvd",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TVD",
                "src_mnemonic": "DBA TVD",
                "src_unit": "m",
                "std_curve_description": "DBA TVD",
                "std_mnemonic_displayname": "DBA TVD",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba tvd bit",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA TVD Bit",
                "src_mnemonic": "DBA TVD Bit",
                "src_unit": "m",
                "std_curve_description": "DBA TVD Bit",
                "std_mnemonic_displayname": "DBA TVD Bit",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba underpull amount",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Underpull Amount",
                "src_mnemonic": "DBA Underpull Amount",
                "src_unit": "klb",
                "std_curve_description": "DBA Underpull Amount",
                "std_mnemonic_displayname": "DBA Underpull Amount",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba underpull belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Underpull Belief",
                "src_mnemonic": "DBA Underpull Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Underpull Belief",
                "std_mnemonic_displayname": "DBA Underpull Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba underpull overpull amount",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Underpull Overpull Amount",
                "src_mnemonic": "DBA Underpull Overpull Amount",
                "src_unit": "klb",
                "std_curve_description": "DBA Underpull Overpull Amount",
                "std_mnemonic_displayname": "DBA Underpull Overpull Amount",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba walk rate",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Walk Rate",
                "src_mnemonic": "DBA Walk Rate",
                "src_unit": "dega/100ft",
                "std_curve_description": "DBA Walk Rate",
                "std_mnemonic_displayname": "DBA Walk Rate",
                "std_unit": "deg/100ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba washout belief",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Washout Belief",
                "src_mnemonic": "DBA Washout Belief",
                "src_unit": "unitless",
                "std_curve_description": "DBA Washout Belief",
                "std_mnemonic_displayname": "DBA Washout Belief",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba washout pump fail belief sum",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA Washout Pump Fail Belief Sum",
                "src_mnemonic": "DBA Washout Pump Fail Belief Sum",
                "src_unit": "unitless",
                "std_curve_description": "DBA Washout Pump Fail Belief Sum",
                "std_mnemonic_displayname": "DBA Washout Pump Fail Belief Sum",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dba wob modeled",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "DBA WOB Modeled",
                "src_mnemonic": "DBA WOB Modeled",
                "src_unit": "klb",
                "std_curve_description": "DBA WOB Modeled",
                "std_mnemonic_displayname": "DBA WOB Modeled",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dog leg severity - wits",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Dog Leg Severity - WITS",
                "src_mnemonic": "Dog Leg Severity - WITS",
                "src_unit": "dega/100ft",
                "std_curve_description": "Dog Leg Severity - WITS",
                "std_mnemonic_displayname": "Dog Leg Severity - WITS",
                "std_unit": "deg/100ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "drilling trigger hkld",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Drilling Trigger HKLD",
                "src_mnemonic": "Drilling Trigger HKLD",
                "src_unit": "klb",
                "std_curve_description": "Drilling Trigger HKLD",
                "std_mnemonic_displayname": "Drilling Trigger HKLD",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "east west horizontal",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "East West Horizontal",
                "src_mnemonic": "East West Horizontal",
                "src_unit": "m",
                "std_curve_description": "East West Horizontal",
                "std_mnemonic_displayname": "East West Horizontal",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "edms counts",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "EDMS Counts",
                "src_mnemonic": "EDMS Counts",
                "src_unit": "EDMS Counts",
                "std_curve_description": "EDMS Counts",
                "std_mnemonic_displayname": "EDMS Counts",
                "std_unit": "EDMS Counts"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "fast_rop_ft_hr_depth",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "ROP - Fast",
                "src_mnemonic": "FAST_ROP_FT_HR_DEPTH",
                "src_unit": "ft/h",
                "std_curve_description": "ROP - Fast",
                "std_mnemonic_displayname": "ROP - Fast",
                "std_unit": "ft/h"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "fd heartbeat",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "FD Heartbeat",
                "src_mnemonic": "FD Heartbeat",
                "src_unit": "unitless",
                "std_curve_description": "FD Heartbeat",
                "std_mnemonic_displayname": "FD Heartbeat",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "fd pump stability",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "FD Pump Stability",
                "src_mnemonic": "FD Pump Stability",
                "src_unit": "%",
                "std_curve_description": "FD Pump Stability",
                "std_mnemonic_displayname": "FD Pump Stability",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "fd svy azimuth",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "FD Svy Azimuth",
                "src_mnemonic": "FD Svy Azimuth",
                "src_unit": "rad",
                "std_curve_description": "FD Svy Azimuth",
                "std_mnemonic_displayname": "FD Svy Azimuth",
                "std_unit": "dega"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "fd svy date",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "FD Svy Date",
                "src_mnemonic": "FD Svy Date",
                "src_unit": "unitless",
                "std_curve_description": "FD Svy Date",
                "std_mnemonic_displayname": "FD Svy Date",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "fd svy depth",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "FD Svy Depth",
                "src_mnemonic": "FD Svy Depth",
                "src_unit": "m",
                "std_curve_description": "FD Svy Depth",
                "std_mnemonic_displayname": "FD Svy Depth",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "fd svy inclination",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "FD Svy Inclination",
                "src_mnemonic": "FD Svy Inclination",
                "src_unit": "rad",
                "std_curve_description": "FD Svy Inclination",
                "std_mnemonic_displayname": "FD Svy Inclination",
                "std_unit": "dega"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "fd svy quality",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "FD Svy Quality",
                "src_mnemonic": "FD Svy Quality",
                "src_unit": "%",
                "std_curve_description": "FD Svy Quality",
                "std_mnemonic_displayname": "FD Svy Quality",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "fill_strokes",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Strks - Fill",
                "src_mnemonic": "FILL_STROKES",
                "src_unit": "strokes",
                "std_curve_description": "Strks - Fill",
                "std_mnemonic_displayname": "Strks - Fill",
                "std_unit": "strokes"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "flow pressure",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Flow Pressure",
                "src_mnemonic": "Flow Pressure",
                "src_unit": "psi",
                "std_curve_description": "Flow Pressure",
                "std_mnemonic_displayname": "Flow Pressure",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "flow temp",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Flow Temp",
                "src_mnemonic": "Flow Temp",
                "src_unit": "degF",
                "std_curve_description": "Flow Temp",
                "std_mnemonic_displayname": "Flow Temp",
                "std_unit": "degF"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "flow_deviation",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Flow Deviation",
                "src_mnemonic": "FLOW_DEVIATION",
                "src_unit": "%",
                "std_curve_description": "Flow Deviation",
                "std_mnemonic_displayname": "Flow Deviation",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "g/l rate - 1 min",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "G/L Rate - 1 Min",
                "src_mnemonic": "G/L Rate - 1 Min",
                "src_unit": "bbl/min",
                "std_curve_description": "G/L Rate - 1 Min",
                "std_mnemonic_displayname": "G/L Rate - 1 Min",
                "std_unit": null
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "g/l rate - 5 min",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "G/L Rate - 5 Min",
                "src_mnemonic": "G/L Rate - 5 Min",
                "src_unit": "bbl/min",
                "std_curve_description": "G/L Rate - 5 Min",
                "std_mnemonic_displayname": "G/L Rate - 5 Min",
                "std_unit": null
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gain loss - spare",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Gain Loss - Spare",
                "src_mnemonic": "Gain Loss - Spare",
                "src_unit": "bbl",
                "std_curve_description": "Gain Loss - Spare",
                "std_mnemonic_displayname": "Gain Loss - Spare",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gainloss",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Gain Loss",
                "src_mnemonic": "GAINLOSS",
                "src_unit": "bbl",
                "std_curve_description": "Gain Loss",
                "std_mnemonic_displayname": "Gain Loss",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gamma_ray_depth",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Gamma Depth",
                "src_mnemonic": "GAMMA_RAY_DEPTH",
                "src_unit": "m",
                "std_curve_description": "Gamma Depth",
                "std_mnemonic_displayname": "Gamma Depth",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gamma_ray_depth_adjusted",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Gamma Depth",
                "src_mnemonic": "GAMMA_RAY_DEPTH_ADJUSTED",
                "src_unit": "m",
                "std_curve_description": "Gamma Depth",
                "std_mnemonic_displayname": "Gamma Depth",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gas fault",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Gas Fault",
                "src_mnemonic": "Gas Fault",
                "src_unit": "Status",
                "std_curve_description": "Gas Fault",
                "std_mnemonic_displayname": "Gas Fault",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gas flow rate",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Gas Flow Rate",
                "src_mnemonic": "Gas Flow Rate",
                "src_unit": "MCMD",
                "std_curve_description": "Gas Flow Rate",
                "std_mnemonic_displayname": "Gas Flow Rate",
                "std_unit": "MCMD"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gas injection",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Gas Injection",
                "src_mnemonic": "Gas Injection",
                "src_unit": "galUS/min",
                "std_curve_description": "Gas Injection",
                "std_mnemonic_displayname": "Gas Injection",
                "std_unit": "galUS/min"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gas_acetyl",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Acetylene",
                "src_mnemonic": "GAS_ACETYL",
                "src_unit": "ppm",
                "std_curve_description": "Acetylene",
                "std_mnemonic_displayname": "Acetylene",
                "std_unit": "ppm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gas_c1",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Chromo Methane",
                "src_mnemonic": "GAS_C1",
                "src_unit": "%",
                "std_curve_description": "Chromo Methane",
                "std_mnemonic_displayname": "Chromo Methane",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gas_c2",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Ethane",
                "src_mnemonic": "GAS_C2",
                "src_unit": "ppm",
                "std_curve_description": "Ethane",
                "std_mnemonic_displayname": "Ethane",
                "std_unit": "ppm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gas_c3",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Chromo Propane",
                "src_mnemonic": "GAS_C3",
                "src_unit": "%",
                "std_curve_description": "Chromo Propane",
                "std_mnemonic_displayname": "Chromo Propane",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gas_co2",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Carbon Dioxide",
                "src_mnemonic": "GAS_CO2",
                "src_unit": "ppm",
                "std_curve_description": "Carbon Dioxide",
                "std_mnemonic_displayname": "Carbon Dioxide",
                "std_unit": "ppm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gas_h2s_max",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "H2S Gas",
                "src_mnemonic": "GAS_H2S_MAX",
                "src_unit": "ppm",
                "std_curve_description": "H2S Gas",
                "std_mnemonic_displayname": "H2S Gas",
                "std_unit": "ppm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gas_iso-c4",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Iso-Butane",
                "src_mnemonic": "GAS_ISO-C4",
                "src_unit": "ppm",
                "std_curve_description": "Iso-Butane",
                "std_mnemonic_displayname": "Iso-Butane",
                "std_unit": "ppm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gas_iso-c5",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Iso-Pentane",
                "src_mnemonic": "GAS_ISO-C5",
                "src_unit": "ppm",
                "std_curve_description": "Iso-Pentane",
                "std_mnemonic_displayname": "Iso-Pentane",
                "std_unit": "ppm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gas_neo-c5",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Neo-Pentane",
                "src_mnemonic": "GAS_NEO-C5",
                "src_unit": "ppm",
                "std_curve_description": "Neo-Pentane",
                "std_mnemonic_displayname": "Neo-Pentane",
                "std_unit": "ppm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gas_nor-c4",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Nor-Butane",
                "src_mnemonic": "GAS_NOR-C4",
                "src_unit": "ppm",
                "std_curve_description": "Nor-Butane",
                "std_mnemonic_displayname": "Nor-Butane",
                "std_unit": "ppm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gas_nor-c5",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Nor-Pentane",
                "src_mnemonic": "GAS_NOR-C5",
                "src_unit": "ppm",
                "std_curve_description": "Nor-Pentane",
                "std_mnemonic_displayname": "Nor-Pentane",
                "std_unit": "ppm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gear in use",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Gear In Use",
                "src_mnemonic": "Gear In Use",
                "src_unit": "unitless",
                "std_curve_description": "Gear In Use",
                "std_mnemonic_displayname": "Gear In Use",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gw air flow",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GW Air Flow",
                "src_mnemonic": "GW Air Flow",
                "src_unit": "unitless",
                "std_curve_description": "GW Air Flow",
                "std_mnemonic_displayname": "GW Air Flow",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gw balance ratio",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GW Balance Ratio",
                "src_mnemonic": "GW Balance Ratio",
                "src_unit": "unitless",
                "std_curve_description": "GW Balance Ratio",
                "std_mnemonic_displayname": "GW Balance Ratio",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gw blowback",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GW Blowback",
                "src_mnemonic": "GW Blowback",
                "src_unit": "unitless",
                "std_curve_description": "GW Blowback",
                "std_mnemonic_displayname": "GW Blowback",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gw blowbackon/off",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GW BlowbackOn/Off",
                "src_mnemonic": "GW BlowbackOn/Off",
                "src_unit": "unitless",
                "std_curve_description": "GW BlowbackOn/Off",
                "std_mnemonic_displayname": "GW BlowbackOn/Off",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gw char ratio",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GW Char Ratio",
                "src_mnemonic": "GW Char Ratio",
                "src_unit": "unitless",
                "std_curve_description": "GW Char Ratio",
                "std_mnemonic_displayname": "GW Char Ratio",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gw ethane (c2)",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GW Ethane (C2)",
                "src_mnemonic": "GW Ethane (C2)",
                "src_unit": "%",
                "std_curve_description": "GW Ethane (C2)",
                "std_mnemonic_displayname": "GW Ethane (C2)",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gw iso-butane (ic4)",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GW ISO-Butane (IC4)",
                "src_mnemonic": "GW ISO-Butane (IC4)",
                "src_unit": "%",
                "std_curve_description": "GW ISO-Butane (IC4)",
                "std_mnemonic_displayname": "GW ISO-Butane (IC4)",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gw iso-pentane (ic5)",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GW ISO-Pentane (IC5)",
                "src_mnemonic": "GW ISO-Pentane (IC5)",
                "src_unit": "%",
                "std_curve_description": "GW ISO-Pentane (IC5)",
                "std_mnemonic_displayname": "GW ISO-Pentane (IC5)",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gw methane (c1)",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GW Methane (C1)",
                "src_mnemonic": "GW Methane (C1)",
                "src_unit": "%",
                "std_curve_description": "GW Methane (C1)",
                "std_mnemonic_displayname": "GW Methane (C1)",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gw nor-butane (nc4)",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GW NOR-Butane (NC4)",
                "src_mnemonic": "GW NOR-Butane (NC4)",
                "src_unit": "%",
                "std_curve_description": "GW NOR-Butane (NC4)",
                "std_mnemonic_displayname": "GW NOR-Butane (NC4)",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gw nor-pentane (nc5)",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GW NOR-Pentane (NC5)",
                "src_mnemonic": "GW NOR-Pentane (NC5)",
                "src_unit": "%",
                "std_curve_description": "GW NOR-Pentane (NC5)",
                "std_mnemonic_displayname": "GW NOR-Pentane (NC5)",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gw propane (c3)",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GW Propane (C3)",
                "src_mnemonic": "GW Propane (C3)",
                "src_unit": "%",
                "std_curve_description": "GW Propane (C3)",
                "std_mnemonic_displayname": "GW Propane (C3)",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gw pump shutdown",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GW Pump Shutdown",
                "src_mnemonic": "GW Pump Shutdown",
                "src_unit": "unitless",
                "std_curve_description": "GW Pump Shutdown",
                "std_mnemonic_displayname": "GW Pump Shutdown",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gw status",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GW Status",
                "src_mnemonic": "GW Status",
                "src_unit": "Status",
                "std_curve_description": "GW Status",
                "std_mnemonic_displayname": "GW Status",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gw total gas",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GW Total Gas",
                "src_mnemonic": "GW Total Gas",
                "src_unit": "%",
                "std_curve_description": "GW Total Gas",
                "std_mnemonic_displayname": "GW Total Gas",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gw vacuum",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GW Vacuum",
                "src_mnemonic": "GW Vacuum",
                "src_unit": "inHG",
                "std_curve_description": "GW Vacuum",
                "std_mnemonic_displayname": "GW Vacuum",
                "std_unit": "inHG"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gw vacuum max",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GW Vacuum Max",
                "src_mnemonic": "GW Vacuum Max",
                "src_unit": "inHG",
                "std_curve_description": "GW Vacuum Max",
                "std_mnemonic_displayname": "GW Vacuum Max",
                "std_unit": "inHG"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gw wet ratio",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GW Wet Ratio",
                "src_mnemonic": "GW Wet Ratio",
                "src_unit": "unitless",
                "std_curve_description": "GW Wet Ratio",
                "std_mnemonic_displayname": "GW Wet Ratio",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gwex agitatorflow",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GWEX AgitatorFlow",
                "src_mnemonic": "GWEX AgitatorFlow",
                "src_unit": "galUS/min",
                "std_curve_description": "GWEX AgitatorFlow",
                "std_mnemonic_displayname": "GWEX AgitatorFlow",
                "std_unit": "galUS/min"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gwex agitatorflowsetpoint",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GWEX AgitatorFlowSetpoint",
                "src_mnemonic": "GWEX AgitatorFlowSetpoint",
                "src_unit": "galUS/min",
                "std_curve_description": "GWEX AgitatorFlowSetpoint",
                "std_mnemonic_displayname": "GWEX AgitatorFlowSetpoint",
                "std_unit": "galUS/min"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gwex agitatorpressure",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GWEX AgitatorPressure",
                "src_mnemonic": "GWEX AgitatorPressure",
                "src_unit": "psi",
                "std_curve_description": "GWEX AgitatorPressure",
                "std_mnemonic_displayname": "GWEX AgitatorPressure",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gwex agitatorrate",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GWEX AgitatorRate",
                "src_mnemonic": "GWEX AgitatorRate",
                "src_unit": "rpm",
                "std_curve_description": "GWEX AgitatorRate",
                "std_mnemonic_displayname": "GWEX AgitatorRate",
                "std_unit": "rpm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gwex blowbacksetpoint",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GWEX BlowbackSetpoint",
                "src_mnemonic": "GWEX BlowbackSetpoint",
                "src_unit": "psi",
                "std_curve_description": "GWEX BlowbackSetpoint",
                "std_mnemonic_displayname": "GWEX BlowbackSetpoint",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gwex command",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GWEX Command",
                "src_mnemonic": "GWEX Command",
                "src_unit": "unitless",
                "std_curve_description": "GWEX Command",
                "std_mnemonic_displayname": "GWEX Command",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gwex faultmode",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GWEX FaultMode",
                "src_mnemonic": "GWEX FaultMode",
                "src_unit": "unitless",
                "std_curve_description": "GWEX FaultMode",
                "std_mnemonic_displayname": "GWEX FaultMode",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gwex h2s",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GWEX H2S",
                "src_mnemonic": "GWEX H2S",
                "src_unit": "%",
                "std_curve_description": "GWEX H2S",
                "std_mnemonic_displayname": "GWEX H2S",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gwex h2salarm",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GWEX H2SAlarm",
                "src_mnemonic": "GWEX H2SAlarm",
                "src_unit": "Status",
                "std_curve_description": "GWEX H2SAlarm",
                "std_mnemonic_displayname": "GWEX H2SAlarm",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gwex methane",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GWEX Methane",
                "src_mnemonic": "GWEX Methane",
                "src_unit": "%",
                "std_curve_description": "GWEX Methane",
                "std_mnemonic_displayname": "GWEX Methane",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gwex propane",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GWEX Propane",
                "src_mnemonic": "GWEX Propane",
                "src_unit": "%",
                "std_curve_description": "GWEX Propane",
                "std_mnemonic_displayname": "GWEX Propane",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gwex rigairpressure",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GWEX RigAirPressure",
                "src_mnemonic": "GWEX RigAirPressure",
                "src_unit": "psi",
                "std_curve_description": "GWEX RigAirPressure",
                "std_mnemonic_displayname": "GWEX RigAirPressure",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gwex sampleflow",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GWEX SampleFlow",
                "src_mnemonic": "GWEX SampleFlow",
                "src_unit": "galUS/min",
                "std_curve_description": "GWEX SampleFlow",
                "std_mnemonic_displayname": "GWEX SampleFlow",
                "std_unit": "galUS/min"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gwex sampleflowsetpoint",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GWEX SampleFlowSetpoint",
                "src_mnemonic": "GWEX SampleFlowSetpoint",
                "src_unit": "galUS/min",
                "std_curve_description": "GWEX SampleFlowSetpoint",
                "std_mnemonic_displayname": "GWEX SampleFlowSetpoint",
                "std_unit": "galUS/min"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gwex samplevacuum",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GWEX SampleVacuum",
                "src_mnemonic": "GWEX SampleVacuum",
                "src_unit": "psi",
                "std_curve_description": "GWEX SampleVacuum",
                "std_mnemonic_displayname": "GWEX SampleVacuum",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gwex systemmode",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GWEX SystemMode",
                "src_mnemonic": "GWEX SystemMode",
                "src_unit": "unitless",
                "std_curve_description": "GWEX SystemMode",
                "std_mnemonic_displayname": "GWEX SystemMode",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gwex temperature",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GWEX Temperature",
                "src_mnemonic": "GWEX Temperature",
                "src_unit": "degF",
                "std_curve_description": "GWEX Temperature",
                "std_mnemonic_displayname": "GWEX Temperature",
                "std_unit": "degF"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gwex totalgas",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "GWEX TotalGas",
                "src_mnemonic": "GWEX TotalGas",
                "src_unit": "%",
                "std_curve_description": "GWEX TotalGas",
                "std_mnemonic_displayname": "GWEX TotalGas",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "hole depth last 24hrs",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Hole Depth Last 24Hrs",
                "src_mnemonic": "Hole Depth Last 24Hrs",
                "src_unit": "m",
                "std_curve_description": "Hole Depth Last 24Hrs",
                "std_mnemonic_displayname": "Hole Depth Last 24Hrs",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "horn alarm state",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Horn Alarm State",
                "src_mnemonic": "Horn Alarm State",
                "src_unit": "unitless",
                "std_curve_description": "Horn Alarm State",
                "std_mnemonic_displayname": "Horn Alarm State",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "hrs_on_bit",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Bit Time",
                "src_mnemonic": "HRS_ON_BIT",
                "src_unit": "hr",
                "std_curve_description": "Bit Time",
                "std_mnemonic_displayname": "Bit Time",
                "std_unit": "hr"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "iadc_rig_activity",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Rig Activity Code",
                "src_mnemonic": "IADC_RIG_ACTIVITY",
                "src_unit": "unitless",
                "std_curve_description": "Rig Activity Code",
                "std_mnemonic_displayname": "Rig Activity Code",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "iadc_rig_activity2",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Rig Activity SubCode",
                "src_mnemonic": "IADC_RIG_ACTIVITY2",
                "src_unit": "unitless",
                "std_curve_description": "Rig Activity SubCode",
                "std_mnemonic_displayname": "Rig Activity SubCode",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "iadc_rig_activity3",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Rig Activity Engine",
                "src_mnemonic": "IADC_RIG_ACTIVITY3",
                "src_unit": "unitless",
                "std_curve_description": "Rig Activity Engine",
                "std_mnemonic_displayname": "Rig Activity Engine",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "iso-hexane",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Iso-Hexane",
                "src_mnemonic": "Iso-Hexane",
                "src_unit": "ppm",
                "std_curve_description": "Iso-Hexane",
                "std_mnemonic_displayname": "Iso-Hexane",
                "std_unit": "ppm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "joints per stand",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Joints Per Stand",
                "src_mnemonic": "Joints Per Stand",
                "src_unit": "unitless",
                "std_curve_description": "Joints Per Stand",
                "std_mnemonic_displayname": "Joints Per Stand",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kelly down",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Kelly Down",
                "src_mnemonic": "Kelly Down",
                "src_unit": "m",
                "std_curve_description": "Kelly Down",
                "std_mnemonic_displayname": "Kelly Down",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz base mse",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Base MSE",
                "src_mnemonic": "KZ Base MSE",
                "src_unit": "kpsi",
                "std_curve_description": "KZ Base MSE",
                "std_mnemonic_displayname": "KZ Base MSE",
                "std_unit": "kpsi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz bit bounce",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Bit Bounce",
                "src_mnemonic": "KZ Bit Bounce",
                "src_unit": "unitless",
                "std_curve_description": "KZ Bit Bounce",
                "std_mnemonic_displayname": "KZ Bit Bounce",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz bit mse",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Bit MSE",
                "src_mnemonic": "KZ Bit MSE",
                "src_unit": "kpsi",
                "std_curve_description": "KZ Bit MSE",
                "std_mnemonic_displayname": "KZ Bit MSE",
                "std_unit": "kpsi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz command delta p",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Command Delta P",
                "src_mnemonic": "KZ Command Delta P",
                "src_unit": "psi",
                "std_curve_description": "KZ Command Delta P",
                "std_mnemonic_displayname": "KZ Command Delta P",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz command rpm",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Command RPM",
                "src_mnemonic": "KZ Command RPM",
                "src_unit": "rpm",
                "std_curve_description": "KZ Command RPM",
                "std_mnemonic_displayname": "KZ Command RPM",
                "std_unit": "rpm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz command swob",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Command SWOB",
                "src_mnemonic": "KZ Command SWOB",
                "src_unit": "klb",
                "std_curve_description": "KZ Command SWOB",
                "std_mnemonic_displayname": "KZ Command SWOB",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz command toolface grav",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Command Toolface Grav",
                "src_mnemonic": "KZ Command Toolface Grav",
                "src_unit": "rad",
                "std_curve_description": "KZ Command Toolface Grav",
                "std_mnemonic_displayname": "KZ Command Toolface Grav",
                "std_unit": "dega"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz command toolface mag",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Command Toolface Mag",
                "src_mnemonic": "KZ Command Toolface Mag",
                "src_unit": "rad",
                "std_curve_description": "KZ Command Toolface Mag",
                "std_mnemonic_displayname": "KZ Command Toolface Mag",
                "std_unit": "dega"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz communication resets",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Communication Resets",
                "src_mnemonic": "KZ Communication Resets",
                "src_unit": "unitless",
                "std_curve_description": "KZ Communication Resets",
                "std_mnemonic_displayname": "KZ Communication Resets",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz corrected delta p",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Corrected Delta P",
                "src_mnemonic": "KZ Corrected Delta P",
                "src_unit": "psi",
                "std_curve_description": "KZ Corrected Delta P",
                "std_mnemonic_displayname": "KZ Corrected Delta P",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz cpu overload count",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ CPU Overload Count",
                "src_mnemonic": "KZ CPU Overload Count",
                "src_unit": "unitless",
                "std_curve_description": "KZ CPU Overload Count",
                "std_mnemonic_displayname": "KZ CPU Overload Count",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz delta p correction status",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Delta P Correction Status",
                "src_mnemonic": "KZ Delta P Correction Status",
                "src_unit": "Status",
                "std_curve_description": "KZ Delta P Correction Status",
                "std_mnemonic_displayname": "KZ Delta P Correction Status",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz delta p/swob focus",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Delta P/SWOB Focus",
                "src_mnemonic": "KZ Delta P/SWOB Focus",
                "src_unit": "unitless",
                "std_curve_description": "KZ Delta P/SWOB Focus",
                "std_mnemonic_displayname": "KZ Delta P/SWOB Focus",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz depth of cut",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Depth of Cut",
                "src_mnemonic": "KZ Depth of Cut",
                "src_unit": "in",
                "std_curve_description": "KZ Depth of Cut",
                "std_mnemonic_displayname": "KZ Depth of Cut",
                "std_unit": "in"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz distance to plan",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Distance to Plan",
                "src_mnemonic": "KZ Distance to Plan",
                "src_unit": "m",
                "std_curve_description": "KZ Distance to Plan",
                "std_mnemonic_displayname": "KZ Distance to Plan",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz downhole model status",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Downhole Model Status",
                "src_mnemonic": "KZ Downhole Model Status",
                "src_unit": "Status",
                "std_curve_description": "KZ Downhole Model Status",
                "std_mnemonic_displayname": "KZ Downhole Model Status",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz dynamic window",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Dynamic Window",
                "src_mnemonic": "KZ Dynamic Window",
                "src_unit": "unitless",
                "std_curve_description": "KZ Dynamic Window",
                "std_mnemonic_displayname": "KZ Dynamic Window",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz dysfunction indicator",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Dysfunction Indicator",
                "src_mnemonic": "KZ Dysfunction Indicator",
                "src_unit": "Status",
                "std_curve_description": "KZ Dysfunction Indicator",
                "std_mnemonic_displayname": "KZ Dysfunction Indicator",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz engine heartbeat",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Engine Heartbeat",
                "src_mnemonic": "KZ Engine Heartbeat",
                "src_unit": "unitless",
                "std_curve_description": "KZ Engine Heartbeat",
                "std_mnemonic_displayname": "KZ Engine Heartbeat",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz engine in control",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Engine In Control",
                "src_mnemonic": "KZ Engine In Control",
                "src_unit": "Status",
                "std_curve_description": "KZ Engine In Control",
                "std_mnemonic_displayname": "KZ Engine In Control",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz engine status",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Engine Status",
                "src_mnemonic": "KZ Engine Status",
                "src_unit": "Status",
                "std_curve_description": "KZ Engine Status",
                "std_mnemonic_displayname": "KZ Engine Status",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz exploration percent",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Exploration Percent",
                "src_mnemonic": "KZ Exploration Percent",
                "src_unit": "%",
                "std_curve_description": "KZ Exploration Percent",
                "std_mnemonic_displayname": "KZ Exploration Percent",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz exploration progress",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Exploration Progress",
                "src_mnemonic": "KZ Exploration Progress",
                "src_unit": "%",
                "std_curve_description": "KZ Exploration Progress",
                "std_mnemonic_displayname": "KZ Exploration Progress",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz max delta p",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Max Delta P",
                "src_mnemonic": "KZ Max Delta P",
                "src_unit": "psi",
                "std_curve_description": "KZ Max Delta P",
                "std_mnemonic_displayname": "KZ Max Delta P",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz max delta p step",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Max Delta P Step",
                "src_mnemonic": "KZ Max Delta P Step",
                "src_unit": "psi",
                "std_curve_description": "KZ Max Delta P Step",
                "std_mnemonic_displayname": "KZ Max Delta P Step",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz max dwob",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Max DWOB",
                "src_mnemonic": "KZ Max DWOB",
                "src_unit": "klb",
                "std_curve_description": "KZ Max DWOB",
                "std_mnemonic_displayname": "KZ Max DWOB",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz max rop",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Max ROP",
                "src_mnemonic": "KZ Max ROP",
                "src_unit": "ft/h",
                "std_curve_description": "KZ Max ROP",
                "std_mnemonic_displayname": "KZ Max ROP",
                "std_unit": "ft/h"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz max rpm",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Max RPM",
                "src_mnemonic": "KZ Max RPM",
                "src_unit": "rpm",
                "std_curve_description": "KZ Max RPM",
                "std_mnemonic_displayname": "KZ Max RPM",
                "std_unit": "rpm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz max rpm step",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Max RPM Step",
                "src_mnemonic": "KZ Max RPM Step",
                "src_unit": "rpm",
                "std_curve_description": "KZ Max RPM Step",
                "std_mnemonic_displayname": "KZ Max RPM Step",
                "std_unit": "rpm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz max swob",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Max SWOB",
                "src_mnemonic": "KZ Max SWOB",
                "src_unit": "klb",
                "std_curve_description": "KZ Max SWOB",
                "std_mnemonic_displayname": "KZ Max SWOB",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz max swob based on stress",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Max SWOB Based on Stress",
                "src_mnemonic": "KZ Max SWOB Based on Stress",
                "src_unit": "klb",
                "std_curve_description": "KZ Max SWOB Based on Stress",
                "std_mnemonic_displayname": "KZ Max SWOB Based on Stress",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz max swob step",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Max SWOB Step",
                "src_mnemonic": "KZ Max SWOB Step",
                "src_unit": "klb",
                "std_curve_description": "KZ Max SWOB Step",
                "std_mnemonic_displayname": "KZ Max SWOB Step",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz max torque",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Max Torque",
                "src_mnemonic": "KZ Max Torque",
                "src_unit": "ft.lbf",
                "std_curve_description": "KZ Max Torque",
                "std_mnemonic_displayname": "KZ Max Torque",
                "std_unit": "kft.lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz min delta p",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Min Delta P",
                "src_mnemonic": "KZ Min Delta P",
                "src_unit": "psi",
                "std_curve_description": "KZ Min Delta P",
                "std_mnemonic_displayname": "KZ Min Delta P",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz min rpm",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Min RPM",
                "src_mnemonic": "KZ Min RPM",
                "src_unit": "rpm",
                "std_curve_description": "KZ Min RPM",
                "std_mnemonic_displayname": "KZ Min RPM",
                "std_unit": "rpm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz min swob",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Min SWOB",
                "src_mnemonic": "KZ Min SWOB",
                "src_unit": "klb",
                "std_curve_description": "KZ Min SWOB",
                "std_mnemonic_displayname": "KZ Min SWOB",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz mse scatter",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ MSE Scatter",
                "src_mnemonic": "KZ MSE Scatter",
                "src_unit": "kpsi",
                "std_curve_description": "KZ MSE Scatter",
                "std_mnemonic_displayname": "KZ MSE Scatter",
                "std_unit": "kpsi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz optimum delta p",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Optimum Delta P",
                "src_mnemonic": "KZ Optimum Delta P",
                "src_unit": "psi",
                "std_curve_description": "KZ Optimum Delta P",
                "std_mnemonic_displayname": "KZ Optimum Delta P",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz optimum rpm",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Optimum RPM",
                "src_mnemonic": "KZ Optimum RPM",
                "src_unit": "rpm",
                "std_curve_description": "KZ Optimum RPM",
                "std_mnemonic_displayname": "KZ Optimum RPM",
                "std_unit": "rpm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz optimum swob",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Optimum SWOB",
                "src_mnemonic": "KZ Optimum SWOB",
                "src_unit": "klb",
                "std_curve_description": "KZ Optimum SWOB",
                "std_mnemonic_displayname": "KZ Optimum SWOB",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz response tuning",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Response Tuning",
                "src_mnemonic": "KZ Response Tuning",
                "src_unit": "unitless",
                "std_curve_description": "KZ Response Tuning",
                "std_mnemonic_displayname": "KZ Response Tuning",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz soft speed status",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Soft Speed Status",
                "src_mnemonic": "KZ Soft Speed Status",
                "src_unit": "Status",
                "std_curve_description": "KZ Soft Speed Status",
                "std_mnemonic_displayname": "KZ Soft Speed Status",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz static data health",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Static Data Health",
                "src_mnemonic": "KZ Static Data Health",
                "src_unit": "unitless",
                "std_curve_description": "KZ Static Data Health",
                "std_mnemonic_displayname": "KZ Static Data Health",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz stick slip level",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Stick Slip Level",
                "src_mnemonic": "KZ Stick Slip Level",
                "src_unit": "unitless",
                "std_curve_description": "KZ Stick Slip Level",
                "std_mnemonic_displayname": "KZ Stick Slip Level",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz surface axial osc",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Surface Axial OSC",
                "src_mnemonic": "KZ Surface Axial OSC",
                "src_unit": "unitless",
                "std_curve_description": "KZ Surface Axial OSC",
                "std_mnemonic_displayname": "KZ Surface Axial OSC",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz surface torsional osc",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Surface Torsional OSC",
                "src_mnemonic": "KZ Surface Torsional OSC",
                "src_unit": "unitless",
                "std_curve_description": "KZ Surface Torsional OSC",
                "std_mnemonic_displayname": "KZ Surface Torsional OSC",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz twister status",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Twister Status",
                "src_mnemonic": "KZ Twister Status",
                "src_unit": "Status",
                "std_curve_description": "KZ Twister Status",
                "std_mnemonic_displayname": "KZ Twister Status",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz whirl level",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Whirl Level",
                "src_mnemonic": "KZ Whirl Level",
                "src_unit": "unitless",
                "std_curve_description": "KZ Whirl Level",
                "std_mnemonic_displayname": "KZ Whirl Level",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz whirl magnitude",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Whirl Magnitude",
                "src_mnemonic": "KZ Whirl Magnitude",
                "src_unit": "unitless",
                "std_curve_description": "KZ Whirl Magnitude",
                "std_mnemonic_displayname": "KZ Whirl Magnitude",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "kz whirl velocity",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "KZ Whirl Velocity",
                "src_mnemonic": "KZ Whirl Velocity",
                "src_unit": "unitless",
                "std_curve_description": "KZ Whirl Velocity",
                "std_mnemonic_displayname": "KZ Whirl Velocity",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "liquid flow rate",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Liquid Flow Rate",
                "src_mnemonic": "Liquid Flow Rate",
                "src_unit": "galUS/D",
                "std_curve_description": "Liquid Flow Rate",
                "std_mnemonic_displayname": "Liquid Flow Rate",
                "std_unit": "galUS/d"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "liquid injection",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Liquid Injection",
                "src_mnemonic": "Liquid Injection",
                "src_unit": "psi",
                "std_curve_description": "Liquid Injection",
                "std_mnemonic_displayname": "Liquid Injection",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "loop in control",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Loop In Control",
                "src_mnemonic": "Loop In Control",
                "src_unit": "Status",
                "std_curve_description": "Loop In Control",
                "std_mnemonic_displayname": "Loop In Control",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "lst elem len",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Lst Elem Len",
                "src_mnemonic": "Lst Elem Len",
                "src_unit": "m",
                "std_curve_description": "Lst Elem Len",
                "std_mnemonic_displayname": "Lst Elem Len",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "methane (gw)",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Methane (GW)",
                "src_mnemonic": "Methane (GW)",
                "src_unit": "%",
                "std_curve_description": "Methane (GW)",
                "std_mnemonic_displayname": "Methane (GW)",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "mpd choke pressure",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "MPD Choke Pressure",
                "src_mnemonic": "MPD Choke Pressure",
                "src_unit": "psi",
                "std_curve_description": "MPD Choke Pressure",
                "std_mnemonic_displayname": "MPD Choke Pressure",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "mpd flow out",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "MPD Flow Out",
                "src_mnemonic": "MPD Flow Out",
                "src_unit": "m3/D",
                "std_curve_description": "MPD Flow Out",
                "std_mnemonic_displayname": "MPD Flow Out",
                "std_unit": "m3/d"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "mpd gas flow",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "MPD Gas Flow",
                "src_mnemonic": "MPD Gas Flow",
                "src_unit": "m3/D",
                "std_curve_description": "MPD Gas Flow",
                "std_mnemonic_displayname": "MPD Gas Flow",
                "std_unit": "m3/d"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "mpd rcd pressure",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "MPD RCD Pressure",
                "src_mnemonic": "MPD RCD Pressure",
                "src_unit": "psi",
                "std_curve_description": "MPD RCD Pressure",
                "std_mnemonic_displayname": "MPD RCD Pressure",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "mse downhole",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "MSE Downhole",
                "src_mnemonic": "MSE Downhole",
                "src_unit": "psi",
                "std_curve_description": "MSE Downhole",
                "std_mnemonic_displayname": "MSE Downhole",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "mse total",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "MSE Total",
                "src_mnemonic": "MSE Total",
                "src_unit": "psi",
                "std_curve_description": "MSE Total",
                "std_mnemonic_displayname": "MSE Total",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "mud conductivity in",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Mud Conductivity In",
                "src_mnemonic": "Mud Conductivity In",
                "src_unit": "mmho/cm",
                "std_curve_description": "Mud Conductivity In",
                "std_mnemonic_displayname": "Mud Conductivity In",
                "std_unit": "mmho/cm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "mud conductivity out",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Mud Conductivity Out",
                "src_mnemonic": "Mud Conductivity Out",
                "src_unit": "mmho/cm",
                "std_curve_description": "Mud Conductivity Out",
                "std_mnemonic_displayname": "Mud Conductivity Out",
                "std_unit": "mmho/cm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "mud motor max diff pressure",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Mud Motor Max Diff Pressure",
                "src_mnemonic": "Mud Motor Max Diff Pressure",
                "src_unit": "psi",
                "std_curve_description": "Mud Motor Max Diff Pressure",
                "std_mnemonic_displayname": "Mud Motor Max Diff Pressure",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "mud motor max torque",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Mud Motor Max Torque",
                "src_mnemonic": "Mud Motor Max Torque",
                "src_unit": "ft.lbf",
                "std_curve_description": "Mud Motor Max Torque",
                "std_mnemonic_displayname": "Mud Motor Max Torque",
                "std_unit": "kft.lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "mud motor rpm",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Mud Motor RPM",
                "src_mnemonic": "Mud Motor RPM",
                "src_unit": "rpm",
                "std_curve_description": "Mud Motor RPM",
                "std_mnemonic_displayname": "Mud Motor RPM",
                "std_unit": "rpm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "mud motor rpm factor",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Mud Motor RPM Factor",
                "src_mnemonic": "Mud Motor RPM Factor",
                "src_unit": "Ratio",
                "std_curve_description": "Mud Motor RPM Factor",
                "std_mnemonic_displayname": "Mud Motor RPM Factor",
                "std_unit": null
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "mud motor torque",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Mud Motor Torque",
                "src_mnemonic": "Mud Motor Torque",
                "src_unit": "ft.lbf",
                "std_curve_description": "Mud Motor Torque",
                "std_mnemonic_displayname": "Mud Motor Torque",
                "std_unit": "kft.lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "mud motor torque factor",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Mud Motor Torque Factor",
                "src_mnemonic": "Mud Motor Torque Factor",
                "src_unit": "Factor",
                "std_curve_description": "Mud Motor Torque Factor",
                "std_mnemonic_displayname": "Mud Motor Torque Factor",
                "std_unit": null
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "n2 out",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "N2 Out",
                "src_mnemonic": "N2 Out",
                "src_unit": "galUS/min",
                "std_curve_description": "N2 Out",
                "std_mnemonic_displayname": "N2 Out",
                "std_unit": "galUS/min"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "n2 pressure in",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "N2 Pressure In",
                "src_mnemonic": "N2 Pressure In",
                "src_unit": "psi",
                "std_curve_description": "N2 Pressure In",
                "std_mnemonic_displayname": "N2 Pressure In",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "nor-hexane",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Nor-Hexane",
                "src_mnemonic": "Nor-Hexane",
                "src_unit": "ppm",
                "std_curve_description": "Nor-Hexane",
                "std_mnemonic_displayname": "Nor-Hexane",
                "std_unit": "ppm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "north south horizontal",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "North South Horizontal",
                "src_mnemonic": "North South Horizontal",
                "src_unit": "m",
                "std_curve_description": "North South Horizontal",
                "std_mnemonic_displayname": "North South Horizontal",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "oscillator state",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Oscillator State",
                "src_mnemonic": "Oscillator State",
                "src_unit": "Status",
                "std_curve_description": "Oscillator State",
                "std_mnemonic_displayname": "Oscillator State",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "oxygen",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Oxygen",
                "src_mnemonic": "Oxygen",
                "src_unit": "ppm",
                "std_curve_description": "Oxygen",
                "std_mnemonic_displayname": "Oxygen",
                "std_unit": "ppm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pipe id",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pipe ID",
                "src_mnemonic": "Pipe ID",
                "src_unit": "in",
                "std_curve_description": "Pipe ID",
                "std_mnemonic_displayname": "Pipe ID",
                "std_unit": "in"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pipe length",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pipe Length",
                "src_mnemonic": "Pipe Length",
                "src_unit": "m",
                "std_curve_description": "Pipe Length",
                "std_mnemonic_displayname": "Pipe Length",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pipe length change",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pipe Length Change",
                "src_mnemonic": "Pipe Length Change",
                "src_unit": "unitless",
                "std_curve_description": "Pipe Length Change",
                "std_mnemonic_displayname": "Pipe Length Change",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pipe od",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pipe OD",
                "src_mnemonic": "Pipe OD",
                "src_unit": "in",
                "std_curve_description": "Pipe OD",
                "std_mnemonic_displayname": "Pipe OD",
                "std_unit": "in"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pipe rpm",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pipe RPM",
                "src_mnemonic": "Pipe RPM",
                "src_unit": "rpm",
                "std_curve_description": "Pipe RPM",
                "std_mnemonic_displayname": "Pipe RPM",
                "std_unit": "rpm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pipe torque",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pipe Torque",
                "src_mnemonic": "Pipe Torque",
                "src_unit": "ft.lbf",
                "std_curve_description": "Pipe Torque",
                "std_mnemonic_displayname": "Pipe Torque",
                "std_unit": "kft.lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "prev day wear",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Prev Day Wear",
                "src_mnemonic": "Prev Day Wear",
                "src_unit": "tonUS·mi",
                "std_curve_description": "Prev Day Wear",
                "std_mnemonic_displayname": "Prev Day Wear",
                "std_unit": null
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "propane (gw)",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Propane (GW)",
                "src_mnemonic": "Propane (GW)",
                "src_unit": "%",
                "std_curve_description": "Propane (GW)",
                "std_mnemonic_displayname": "Propane (GW)",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pump displacement 1",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pump Displacement 1",
                "src_mnemonic": "Pump Displacement 1",
                "src_unit": "galUS",
                "std_curve_description": "Pump Displacement 1",
                "std_mnemonic_displayname": "Pump Displacement 1",
                "std_unit": null
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pump displacement 2",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pump Displacement 2",
                "src_mnemonic": "Pump Displacement 2",
                "src_unit": "galUS",
                "std_curve_description": "Pump Displacement 2",
                "std_mnemonic_displayname": "Pump Displacement 2",
                "std_unit": null
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pump displacement 3",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pump Displacement 3",
                "src_mnemonic": "Pump Displacement 3",
                "src_unit": "galUS",
                "std_curve_description": "Pump Displacement 3",
                "std_mnemonic_displayname": "Pump Displacement 3",
                "std_unit": null
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pump efficiency 1",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pump Efficiency 1",
                "src_mnemonic": "Pump Efficiency 1",
                "src_unit": "%",
                "std_curve_description": "Pump Efficiency 1",
                "std_mnemonic_displayname": "Pump Efficiency 1",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pump efficiency 2",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pump Efficiency 2",
                "src_mnemonic": "Pump Efficiency 2",
                "src_unit": "%",
                "std_curve_description": "Pump Efficiency 2",
                "std_mnemonic_displayname": "Pump Efficiency 2",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pump liner 1",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pump Liner 1",
                "src_mnemonic": "Pump Liner 1",
                "src_unit": "in",
                "std_curve_description": "Pump Liner 1",
                "std_mnemonic_displayname": "Pump Liner 1",
                "std_unit": "in"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pump liner 2",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pump Liner 2",
                "src_mnemonic": "Pump Liner 2",
                "src_unit": "in",
                "std_curve_description": "Pump Liner 2",
                "std_mnemonic_displayname": "Pump Liner 2",
                "std_unit": "in"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pump liner 3",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pump Liner 3",
                "src_mnemonic": "Pump Liner 3",
                "src_unit": "in",
                "std_curve_description": "Pump Liner 3",
                "std_mnemonic_displayname": "Pump Liner 3",
                "std_unit": "in"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pump stroke length 1",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pump Stroke Length 1",
                "src_mnemonic": "Pump Stroke Length 1",
                "src_unit": "in",
                "std_curve_description": "Pump Stroke Length 1",
                "std_mnemonic_displayname": "Pump Stroke Length 1",
                "std_unit": "in"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pump stroke length 2",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pump Stroke Length 2",
                "src_mnemonic": "Pump Stroke Length 2",
                "src_unit": "in",
                "std_curve_description": "Pump Stroke Length 2",
                "std_mnemonic_displayname": "Pump Stroke Length 2",
                "std_unit": "in"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pump stroke length 3",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pump Stroke Length 3",
                "src_mnemonic": "Pump Stroke Length 3",
                "src_unit": "in",
                "std_curve_description": "Pump Stroke Length 3",
                "std_mnemonic_displayname": "Pump Stroke Length 3",
                "std_unit": "in"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pump type 1",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pump Type 1",
                "src_mnemonic": "Pump Type 1",
                "src_unit": "unitless",
                "std_curve_description": "Pump Type 1",
                "std_mnemonic_displayname": "Pump Type 1",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pump type 2",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pump Type 2",
                "src_mnemonic": "Pump Type 2",
                "src_unit": "unitless",
                "std_curve_description": "Pump Type 2",
                "std_mnemonic_displayname": "Pump Type 2",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pump type 3",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Pump Type 3",
                "src_mnemonic": "Pump Type 3",
                "src_unit": "unitless",
                "std_curve_description": "Pump Type 3",
                "std_mnemonic_displayname": "Pump Type 3",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ql depth delay",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "QL Depth Delay",
                "src_mnemonic": "QL Depth Delay",
                "src_unit": "m",
                "std_curve_description": "QL Depth Delay",
                "std_mnemonic_displayname": "QL Depth Delay",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ql returns depth",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "QL Returns Depth",
                "src_mnemonic": "QL Returns Depth",
                "src_unit": "m",
                "std_curve_description": "QL Returns Depth",
                "std_mnemonic_displayname": "QL Returns Depth",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ql start depth",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "QL Start Depth",
                "src_mnemonic": "QL Start Depth",
                "src_unit": "m",
                "std_curve_description": "QL Start Depth",
                "std_mnemonic_displayname": "QL Start Depth",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ql stks bt up",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "QL Stks Bt Up",
                "src_mnemonic": "QL Stks Bt Up",
                "src_unit": "strokes",
                "std_curve_description": "QL Stks Bt Up",
                "std_mnemonic_displayname": "QL Stks Bt Up",
                "std_unit": "strokes"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ql strokes to surface",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "QL Strokes To Surface",
                "src_mnemonic": "QL Strokes To Surface",
                "src_unit": "strokes",
                "std_curve_description": "QL Strokes To Surface",
                "std_mnemonic_displayname": "QL Strokes To Surface",
                "std_unit": "strokes"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ql_annular_velocity",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Annular Velocity",
                "src_mnemonic": "QL_ANNULAR_VELOCITY",
                "src_unit": "ft/min",
                "std_curve_description": "Annular Velocity",
                "std_mnemonic_displayname": "Annular Velocity",
                "std_unit": "ft/min"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ql_fast_ret_depth",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Returns Depth - Realtime",
                "src_mnemonic": "QL_FAST_RET_DEPTH",
                "src_unit": "m",
                "std_curve_description": "Returns Depth - Realtime",
                "std_mnemonic_displayname": "Returns Depth - Realtime",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ql_tm_to_surf",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "QL Time To Surface",
                "src_mnemonic": "QL_TM_TO_SURF",
                "src_unit": "min",
                "std_curve_description": "QL Time To Surface",
                "std_mnemonic_displayname": "QL Time To Surface",
                "std_unit": null
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "r/t gear",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "R/T Gear",
                "src_mnemonic": "R/T Gear",
                "src_unit": "unitless",
                "std_curve_description": "R/T Gear",
                "std_mnemonic_displayname": "R/T Gear",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "remote gain loss alarm",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Remote Gain Loss Alarm",
                "src_mnemonic": "Remote Gain Loss Alarm",
                "src_unit": "Status",
                "std_curve_description": "Remote Gain Loss Alarm",
                "std_mnemonic_displayname": "Remote Gain Loss Alarm",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "rig activity version",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Rig Activity Version",
                "src_mnemonic": "Rig Activity Version",
                "src_unit": "unitless",
                "std_curve_description": "Rig Activity Version",
                "std_mnemonic_displayname": "Rig Activity Version",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "rig air press",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Rig Air Press",
                "src_mnemonic": "Rig Air Press",
                "src_unit": "psi",
                "std_curve_description": "Rig Air Press",
                "std_mnemonic_displayname": "Rig Air Press",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "rig_activity",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Rig Activity",
                "src_mnemonic": "RIG_ACTIVITY",
                "src_unit": "unitless",
                "std_curve_description": "Rig Activity",
                "std_mnemonic_displayname": "Rig Activity",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "rot_speed",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Rotary RPM",
                "src_mnemonic": "ROT_SPEED",
                "src_unit": "rpm",
                "std_curve_description": "Rotary RPM",
                "std_mnemonic_displayname": "Rotary RPM",
                "std_unit": "rpm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "rot_torque",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Rotary Torque",
                "src_mnemonic": "ROT_TORQUE",
                "src_unit": "A",
                "std_curve_description": "Rotary Torque",
                "std_mnemonic_displayname": "Rotary Torque",
                "std_unit": "A"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "rotary torque factor",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Rotary Torque Factor",
                "src_mnemonic": "Rotary Torque Factor",
                "src_unit": "Ratio",
                "std_curve_description": "Rotary Torque Factor",
                "std_mnemonic_displayname": "Rotary Torque Factor",
                "std_unit": null
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "s_flow_out",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Flow Out Rate",
                "src_mnemonic": "FLOW_OUT",
                "src_unit": "galUS/min",
                "std_curve_description": "Flow Out Rate",
                "std_mnemonic_displayname": "Flow Out Rate",
                "std_unit": "galUS/min"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "sdaq status",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "SDAQ Status",
                "src_mnemonic": "SDAQ Status",
                "src_unit": "Status",
                "std_curve_description": "SDAQ Status",
                "std_mnemonic_displayname": "SDAQ Status",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "skim flow",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Skim Flow",
                "src_mnemonic": "Skim Flow",
                "src_unit": "%",
                "std_curve_description": "Skim Flow",
                "std_mnemonic_displayname": "Skim Flow",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "slip switch",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Slip Switch",
                "src_mnemonic": "Slip Switch",
                "src_unit": "unitless",
                "std_curve_description": "Slip Switch",
                "std_mnemonic_displayname": "Slip Switch",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "srv_md",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Svy Depth",
                "src_mnemonic": "SRV_MD",
                "src_unit": "m",
                "std_curve_description": "Svy Depth",
                "std_mnemonic_displayname": "Svy Depth",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "standpipe pressure 1",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Standpipe Pressure 1",
                "src_mnemonic": "Standpipe Pressure 1",
                "src_unit": "psi",
                "std_curve_description": "Standpipe Pressure 1",
                "std_mnemonic_displayname": "Standpipe Pressure 1",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "standpipe pressure 2",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Standpipe Pressure 2",
                "src_mnemonic": "Standpipe Pressure 2",
                "src_unit": "psi",
                "std_curve_description": "Standpipe Pressure 2",
                "std_mnemonic_displayname": "Standpipe Pressure 2",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "stick slip mitigator on",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Stick Slip Mitigator On",
                "src_mnemonic": "Stick Slip Mitigator On",
                "src_unit": "Status",
                "std_curve_description": "Stick Slip Mitigator On",
                "std_mnemonic_displayname": "Stick Slip Mitigator On",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "string length",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "String Length",
                "src_mnemonic": "String Length",
                "src_unit": "m",
                "std_curve_description": "String Length",
                "std_mnemonic_displayname": "String Length",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "string_speed",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "String Speed",
                "src_mnemonic": "STRING_SPEED",
                "src_unit": "ft/min",
                "std_curve_description": "String Speed",
                "std_mnemonic_displayname": "String Speed",
                "std_unit": "ft/min"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "string_torque",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "String Torque",
                "src_mnemonic": "STRING_TORQUE",
                "src_unit": "ft.lbf",
                "std_curve_description": "String Torque",
                "std_mnemonic_displayname": "String Torque",
                "std_unit": "kft.lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "string_weight",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "String Weight",
                "src_mnemonic": "STRING_WEIGHT",
                "src_unit": "klb",
                "std_curve_description": "String Weight",
                "std_mnemonic_displayname": "String Weight",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "strokes 1a",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Strokes 1A",
                "src_mnemonic": "Strokes 1A",
                "src_unit": "strokes",
                "std_curve_description": "Strokes 1A",
                "std_mnemonic_displayname": "Strokes 1A",
                "std_unit": "strokes"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "strokes 1b",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Strokes 1B",
                "src_mnemonic": "Strokes 1B",
                "src_unit": "Count",
                "std_curve_description": "Strokes 1B",
                "std_mnemonic_displayname": "Strokes 1B",
                "std_unit": "Count"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "strokes 2a",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Strokes 2A",
                "src_mnemonic": "Strokes 2A",
                "src_unit": "strokes",
                "std_curve_description": "Strokes 2A",
                "std_mnemonic_displayname": "Strokes 2A",
                "std_unit": "strokes"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "strokes 2b",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Strokes 2B",
                "src_mnemonic": "Strokes 2B",
                "src_unit": "Count",
                "std_curve_description": "Strokes 2B",
                "std_mnemonic_displayname": "Strokes 2B",
                "std_unit": "Count"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "strokes 3a",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Strokes 3A",
                "src_mnemonic": "Strokes 3A",
                "src_unit": "strokes",
                "std_curve_description": "Strokes 3A",
                "std_mnemonic_displayname": "Strokes 3A",
                "std_unit": "strokes"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "strokes 3b",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Strokes 3B",
                "src_mnemonic": "Strokes 3B",
                "src_unit": "Count",
                "std_curve_description": "Strokes 3B",
                "std_mnemonic_displayname": "Strokes 3B",
                "std_unit": "Count"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "subsea tvd",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Subsea TVD",
                "src_mnemonic": "Subsea TVD",
                "src_unit": "m",
                "std_curve_description": "Subsea TVD",
                "std_mnemonic_displayname": "Subsea TVD",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "subsea_tvd_3rdparty",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Subsea TVD",
                "src_mnemonic": "SUBSEA_TVD_3RDPARTY",
                "src_unit": "m",
                "std_curve_description": "Subsea TVD",
                "std_mnemonic_displayname": "Subsea TVD",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "survey_number",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Svy Number",
                "src_mnemonic": "SURVEY_NUMBER",
                "src_unit": "unitless",
                "std_curve_description": "Svy Number",
                "std_mnemonic_displayname": "Svy Number",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "svy_e_w_position",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "SVY_E_W_POSITION",
                "src_mnemonic": "SVY_E_W_POSITION",
                "src_unit": "m",
                "std_curve_description": "SVY_E_W_POSITION",
                "std_mnemonic_displayname": "SVY_E_W_POSITION",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "svy_n_s_position",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "SVY_N_S_POSITION",
                "src_mnemonic": "SVY_N_S_POSITION",
                "src_unit": "m",
                "std_curve_description": "SVY_N_S_POSITION",
                "std_mnemonic_displayname": "SVY_N_S_POSITION",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "svy_walk_rate",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "SVY_WALK_RATE",
                "src_mnemonic": "SVY_WALK_RATE",
                "src_unit": "dega/100ft",
                "std_curve_description": "SVY_WALK_RATE",
                "std_mnemonic_displayname": "SVY_WALK_RATE",
                "std_unit": "deg/100ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "td/rt sel flag",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "TD/RT Sel Flag",
                "src_mnemonic": "TD/RT Sel Flag",
                "src_unit": "Status",
                "std_curve_description": "TD/RT Sel Flag",
                "std_mnemonic_displayname": "TD/RT Sel Flag",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ton_mile_24hr",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Last 24 Hr Wear",
                "src_mnemonic": "TON_MILE_24HR",
                "src_unit": "tonUS·mi",
                "std_curve_description": "Last 24 Hr Wear",
                "std_mnemonic_displayname": "Last 24 Hr Wear",
                "std_unit": null
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tong length",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Tong Length",
                "src_mnemonic": "Tong Length",
                "src_unit": "in",
                "std_curve_description": "Tong Length",
                "std_mnemonic_displayname": "Tong Length",
                "std_unit": "in"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tong torque",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Tong Torque",
                "src_mnemonic": "Tong Torque",
                "src_unit": "ft.lbf",
                "std_curve_description": "Tong Torque",
                "std_mnemonic_displayname": "Tong Torque",
                "std_unit": "kft.lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tong torque lp",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Tong Torque LP",
                "src_mnemonic": "Tong Torque LP",
                "src_unit": "lb",
                "std_curve_description": "Tong Torque LP",
                "std_mnemonic_displayname": "Tong Torque LP",
                "std_unit": "lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "toolface target",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Toolface Target",
                "src_mnemonic": "Toolface Target",
                "src_unit": "rad",
                "std_curve_description": "Toolface Target",
                "std_mnemonic_displayname": "Toolface Target",
                "std_unit": "dega"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "toolface threshold",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Toolface Threshold",
                "src_mnemonic": "Toolface Threshold",
                "src_unit": "unitless",
                "std_curve_description": "Toolface Threshold",
                "std_mnemonic_displayname": "Toolface Threshold",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "top drv torque - converted",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Top Drv Torque - Converted",
                "src_mnemonic": "Top Drv Torque - Converted",
                "src_unit": "ft.lbf",
                "std_curve_description": "Top Drv Torque - Converted",
                "std_mnemonic_displayname": "Top Drv Torque - Converted",
                "std_unit": "kft.lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "torque diff",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Torque Diff",
                "src_mnemonic": "Torque Diff",
                "src_unit": "unitless",
                "std_curve_description": "Torque Diff",
                "std_mnemonic_displayname": "Torque Diff",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "total gas normalized",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Total Gas Normalized",
                "src_mnemonic": "Total Gas Normalized",
                "src_unit": "1%=100Units",
                "std_curve_description": "Total Gas Normalized",
                "std_mnemonic_displayname": "Total Gas Normalized",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "total gas out",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Total Gas Out",
                "src_mnemonic": "Total Gas Out",
                "src_unit": "MCMD",
                "std_curve_description": "Total Gas Out",
                "std_mnemonic_displayname": "Total Gas Out",
                "std_unit": "MCMD"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "total_gas3",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "TOTAL_GAS3",
                "src_mnemonic": "TOTAL_GAS3",
                "src_unit": "1%=100Units",
                "std_curve_description": "TOTAL_GAS3",
                "std_mnemonic_displayname": "TOTAL_GAS3",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "total_gas_gw",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Total Gas (GW)",
                "src_mnemonic": "TOTAL_GAS_GW",
                "src_unit": "%",
                "std_curve_description": "Total Gas (GW)",
                "std_mnemonic_displayname": "Total Gas (GW)",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "trigger hkld status",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Trigger HKLD Status",
                "src_mnemonic": "Trigger HKLD Status",
                "src_unit": "unitless",
                "std_curve_description": "Trigger HKLD Status",
                "std_mnemonic_displayname": "Trigger HKLD Status",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "trip_tank_gl",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Trip Tank GL",
                "src_mnemonic": "TRIP_TANK_GL",
                "src_unit": "bbl",
                "std_curve_description": "Trip Tank GL",
                "std_mnemonic_displayname": "Trip Tank GL",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tripping trigger hkld",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Tripping Trigger HKLD",
                "src_mnemonic": "Tripping Trigger HKLD",
                "src_unit": "klb",
                "std_curve_description": "Tripping Trigger HKLD",
                "std_mnemonic_displayname": "Tripping Trigger HKLD",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tvd_3rdparty",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "TVD",
                "src_mnemonic": "TVD_3RDPARTY",
                "src_unit": "m",
                "std_curve_description": "TVD",
                "std_mnemonic_displayname": "TVD",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "washout factor",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Washout Factor",
                "src_mnemonic": "Washout Factor",
                "src_unit": "unitless",
                "std_curve_description": "Washout Factor",
                "std_mnemonic_displayname": "Washout Factor",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "water out",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Water Out",
                "src_mnemonic": "Water Out",
                "src_unit": "galUS/D",
                "std_curve_description": "Water Out",
                "std_mnemonic_displayname": "Water Out",
                "std_unit": "galUS/d"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "water pit",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "Water Pit",
                "src_mnemonic": "Water Pit",
                "src_unit": "bbl",
                "std_curve_description": "Water Pit",
                "std_mnemonic_displayname": "Water Pit",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc a/d status",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC A/D Status",
                "src_mnemonic": "WC A/D Status",
                "src_unit": "Status",
                "std_curve_description": "WC A/D Status",
                "std_mnemonic_displayname": "WC A/D Status",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc active profile",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Active Profile",
                "src_mnemonic": "WC Active Profile",
                "src_unit": "Count",
                "std_curve_description": "WC Active Profile",
                "std_mnemonic_displayname": "WC Active Profile",
                "std_unit": "Count"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc bit bounce",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Bit Bounce",
                "src_mnemonic": "WC Bit Bounce",
                "src_unit": "m",
                "std_curve_description": "WC Bit Bounce",
                "std_mnemonic_displayname": "WC Bit Bounce",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc bit weight",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Bit Weight",
                "src_mnemonic": "WC Bit Weight",
                "src_unit": "klb",
                "std_curve_description": "WC Bit Weight",
                "std_mnemonic_displayname": "WC Bit Weight",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc drill stop point",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Drill Stop Point",
                "src_mnemonic": "WC Drill Stop Point",
                "src_unit": "m",
                "std_curve_description": "WC Drill Stop Point",
                "std_mnemonic_displayname": "WC Drill Stop Point",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc feed forward",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Feed Forward",
                "src_mnemonic": "WC Feed Forward",
                "src_unit": "HZ/100",
                "std_curve_description": "WC Feed Forward",
                "std_mnemonic_displayname": "WC Feed Forward",
                "std_unit": null
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc freq cmd",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Freq Cmd",
                "src_mnemonic": "WC Freq Cmd",
                "src_unit": "Hz",
                "std_curve_description": "WC Freq Cmd",
                "std_mnemonic_displayname": "WC Freq Cmd",
                "std_unit": null
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc incr distance",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Incr Distance",
                "src_mnemonic": "WC Incr Distance",
                "src_unit": "in",
                "std_curve_description": "WC Incr Distance",
                "std_mnemonic_displayname": "WC Incr Distance",
                "std_unit": "in"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc len to drill",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Len To Drill",
                "src_mnemonic": "WC Len To Drill",
                "src_unit": "m",
                "std_curve_description": "WC Len To Drill",
                "std_mnemonic_displayname": "WC Len To Drill",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc lift unit threshold",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Lift Unit Threshold",
                "src_mnemonic": "WC Lift Unit Threshold",
                "src_unit": "HZ/100",
                "std_curve_description": "WC Lift Unit Threshold",
                "std_mnemonic_displayname": "WC Lift Unit Threshold",
                "std_unit": null
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc phoenix flag",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Phoenix Flag",
                "src_mnemonic": "WC Phoenix Flag",
                "src_unit": "unitless",
                "std_curve_description": "WC Phoenix Flag",
                "std_mnemonic_displayname": "WC Phoenix Flag",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc ream speed",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Ream Speed",
                "src_mnemonic": "WC Ream Speed",
                "src_unit": "ft/h",
                "std_curve_description": "WC Ream Speed",
                "std_mnemonic_displayname": "WC Ream Speed",
                "std_unit": "ft/h"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc rop",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC ROP",
                "src_mnemonic": "WC ROP",
                "src_unit": "ft/h",
                "std_curve_description": "WC ROP",
                "std_mnemonic_displayname": "WC ROP",
                "std_unit": "ft/h"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc rop safety limit",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC ROP Safety Limit",
                "src_mnemonic": "WC ROP Safety Limit",
                "src_unit": "ft/h",
                "std_curve_description": "WC ROP Safety Limit",
                "std_mnemonic_displayname": "WC ROP Safety Limit",
                "std_unit": "ft/h"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc sensitivity",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Sensitivity",
                "src_mnemonic": "WC Sensitivity",
                "src_unit": "rpm",
                "std_curve_description": "WC Sensitivity",
                "std_mnemonic_displayname": "WC Sensitivity",
                "std_unit": "rpm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc status",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Status",
                "src_mnemonic": "WC Status",
                "src_unit": "Status",
                "std_curve_description": "WC Status",
                "std_mnemonic_displayname": "WC Status",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc torque",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Torque",
                "src_mnemonic": "WC Torque",
                "src_unit": "ft.lbf",
                "std_curve_description": "WC Torque",
                "std_mnemonic_displayname": "WC Torque",
                "std_unit": "kft.lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc total drilled",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Total Drilled",
                "src_mnemonic": "WC Total Drilled",
                "src_unit": "m",
                "std_curve_description": "WC Total Drilled",
                "std_mnemonic_displayname": "WC Total Drilled",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc wob safety limit",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC WOB Safety Limit",
                "src_mnemonic": "WC WOB Safety Limit",
                "src_unit": "klb",
                "std_curve_description": "WC WOB Safety Limit",
                "std_mnemonic_displayname": "WC WOB Safety Limit",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc_control_param",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Loop In Control",
                "src_mnemonic": "WC_CONTROL_PARAM",
                "src_unit": "unitless",
                "std_curve_description": "WC Loop In Control",
                "std_mnemonic_displayname": "WC Loop In Control",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc_diff_p_enabled",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Diff P Enable",
                "src_mnemonic": "WC_DIFF_P_ENABLED",
                "src_unit": "unitless",
                "std_curve_description": "WC Diff P Enable",
                "std_mnemonic_displayname": "WC Diff P Enable",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc_drilling_mode_select",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Auto/Time Drill",
                "src_mnemonic": "WC_DRILLING_MODE_SELECT",
                "src_unit": "unitless",
                "std_curve_description": "WC Auto/Time Drill",
                "std_mnemonic_displayname": "WC Auto/Time Drill",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc_drum_engaged",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Drum Engaged",
                "src_mnemonic": "WC_DRUM_ENGAGED",
                "src_unit": "unitless",
                "std_curve_description": "WC Drum Engaged",
                "std_mnemonic_displayname": "WC Drum Engaged",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc_eads_enabled",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC EADS Enable",
                "src_mnemonic": "WC_EADS_ENABLED",
                "src_unit": "unitless",
                "std_curve_description": "WC EADS Enable",
                "std_mnemonic_displayname": "WC EADS Enable",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc_motor_enable",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Motor Enable",
                "src_mnemonic": "WC_MOTOR_ENABLE",
                "src_unit": "unitless",
                "std_curve_description": "WC Motor Enable",
                "std_mnemonic_displayname": "WC Motor Enable",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc_target_diff_press",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Target Diff Press",
                "src_mnemonic": "WC_TARGET_DIFF_PRESS",
                "src_unit": "psi",
                "std_curve_description": "WC Target Diff Press",
                "std_mnemonic_displayname": "WC Target Diff Press",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc_target_rop",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Target ROP",
                "src_mnemonic": "WC_TARGET_ROP",
                "src_unit": "ft/h",
                "std_curve_description": "WC Target ROP",
                "std_mnemonic_displayname": "WC Target ROP",
                "std_unit": "ft/h"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc_target_torque",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Target Torque",
                "src_mnemonic": "WC_TARGET_TORQUE",
                "src_unit": "ft.lbf",
                "std_curve_description": "WC Target Torque",
                "std_mnemonic_displayname": "WC Target Torque",
                "std_unit": "kft.lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc_target_wob",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Target Bit Weight",
                "src_mnemonic": "WC_TARGET_WOB",
                "src_unit": "klb",
                "std_curve_description": "WC Target Bit Weight",
                "std_mnemonic_displayname": "WC Target Bit Weight",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wc_torque_enable",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WC Torque Enable",
                "src_mnemonic": "WC_TORQUE_ENABLE",
                "src_unit": "unitless",
                "std_curve_description": "WC Torque Enable",
                "std_mnemonic_displayname": "WC Torque Enable",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wpda - avg mse",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WPDA - Avg MSE",
                "src_mnemonic": "WPDA - Avg MSE",
                "src_unit": "kpsi",
                "std_curve_description": "WPDA - Avg MSE",
                "std_mnemonic_displayname": "WPDA - Avg MSE",
                "std_unit": "kpsi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wpda - bit hhp",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WPDA - Bit HHP",
                "src_mnemonic": "WPDA - Bit HHP",
                "src_unit": "hhp/in2",
                "std_curve_description": "WPDA - Bit HHP",
                "std_mnemonic_displayname": "WPDA - Bit HHP",
                "std_unit": "hhp/in2"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wpda - d exponent",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WPDA - d Exponent",
                "src_mnemonic": "WPDA - d Exponent",
                "src_unit": "unitless",
                "std_curve_description": "WPDA - d Exponent",
                "std_mnemonic_displayname": "WPDA - d Exponent",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wpda - dim torque",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WPDA - Dim Torque",
                "src_mnemonic": "WPDA - Dim Torque",
                "src_unit": "unitless",
                "std_curve_description": "WPDA - Dim Torque",
                "std_mnemonic_displayname": "WPDA - Dim Torque",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wpda - hca heartbeat",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WPDA - HCA Heartbeat",
                "src_mnemonic": "WPDA - HCA Heartbeat",
                "src_unit": "unitless",
                "std_curve_description": "WPDA - HCA Heartbeat",
                "std_mnemonic_displayname": "WPDA - HCA Heartbeat",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wpda - heartbeat",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WPDA - HeartBeat",
                "src_mnemonic": "WPDA - HeartBeat",
                "src_unit": "unitless",
                "std_curve_description": "WPDA - HeartBeat",
                "std_mnemonic_displayname": "WPDA - HeartBeat",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wpda - mse",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WPDA - MSE",
                "src_mnemonic": "WPDA - MSE",
                "src_unit": "kpsi",
                "std_curve_description": "WPDA - MSE",
                "std_mnemonic_displayname": "WPDA - MSE",
                "std_unit": "kpsi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wpda - pipe torq engr",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WPDA - Pipe Torq Engr",
                "src_mnemonic": "WPDA - Pipe Torq Engr",
                "src_unit": "ft.lbf",
                "std_curve_description": "WPDA - Pipe Torq Engr",
                "std_mnemonic_displayname": "WPDA - Pipe Torq Engr",
                "std_unit": "kft.lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wpda - pseudo-mse",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WPDA - Pseudo-MSE",
                "src_mnemonic": "WPDA - Pseudo-MSE",
                "src_unit": "unitless",
                "std_curve_description": "WPDA - Pseudo-MSE",
                "std_mnemonic_displayname": "WPDA - Pseudo-MSE",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wpda - ref ccs",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WPDA - Ref CCS",
                "src_mnemonic": "WPDA - Ref CCS",
                "src_unit": "kpsi",
                "std_curve_description": "WPDA - Ref CCS",
                "std_mnemonic_displayname": "WPDA - Ref CCS",
                "std_unit": "kpsi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wpda - ref gamma",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WPDA - Ref Gamma",
                "src_mnemonic": "WPDA - Ref Gamma",
                "src_unit": "unitless",
                "std_curve_description": "WPDA - Ref Gamma",
                "std_mnemonic_displayname": "WPDA - Ref Gamma",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wpda - ref mse",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WPDA - Ref MSE",
                "src_mnemonic": "WPDA - Ref MSE",
                "src_unit": "kpsi",
                "std_curve_description": "WPDA - Ref MSE",
                "std_mnemonic_displayname": "WPDA - Ref MSE",
                "std_unit": "kpsi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wpda - status",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WPDA - Status",
                "src_mnemonic": "WPDA - Status",
                "src_unit": "Status",
                "std_curve_description": "WPDA - Status",
                "std_mnemonic_displayname": "WPDA - Status",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wsgd airflow",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WSGD Airflow",
                "src_mnemonic": "WSGD Airflow",
                "src_unit": "unitless",
                "std_curve_description": "WSGD Airflow",
                "std_mnemonic_displayname": "WSGD Airflow",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wsgd gcib bd1 chnl1 te",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WSGD GCIB Bd1 Chnl1 Te",
                "src_mnemonic": "WSGD GCIB Bd1 Chnl1 Te",
                "src_unit": "unitless",
                "std_curve_description": "WSGD GCIB Bd1 Chnl1 Te",
                "std_mnemonic_displayname": "WSGD GCIB Bd1 Chnl1 Te",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wsgd gcib bd2 chnl1 te",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WSGD GCIB Bd2 Chnl1 Te",
                "src_mnemonic": "WSGD GCIB Bd2 Chnl1 Te",
                "src_unit": "unitless",
                "std_curve_description": "WSGD GCIB Bd2 Chnl1 Te",
                "std_mnemonic_displayname": "WSGD GCIB Bd2 Chnl1 Te",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wsgd pump shutdown",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WSGD Pump Shutdown",
                "src_mnemonic": "WSGD Pump Shutdown",
                "src_unit": "unitless",
                "std_curve_description": "WSGD Pump Shutdown",
                "std_mnemonic_displayname": "WSGD Pump Shutdown",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wsgd total gas",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WSGD Total Gas",
                "src_mnemonic": "WSGD Total Gas",
                "src_unit": "1%=100Units",
                "std_curve_description": "WSGD Total Gas",
                "std_mnemonic_displayname": "WSGD Total Gas",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wsgd_methane",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WSGD Methane",
                "src_mnemonic": "WSGD_METHANE",
                "src_unit": "%",
                "std_curve_description": "WSGD Methane",
                "std_mnemonic_displayname": "WSGD Methane",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wsgd_propane",
                "mnemonic_type": "MISCELLANEOUS",
                "src_curve_description": "WSGD Propane",
                "src_mnemonic": "WSGD_PROPANE",
                "src_unit": "%",
                "std_curve_description": "WSGD Propane",
                "std_mnemonic_displayname": "WSGD Propane",
                "std_unit": "%"
            }
        ],
        "SOLUTION": [
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "activity",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "Activity",
                "src_mnemonic": "activity",
                "src_unit": "unitless",
                "std_curve_description": "Activity",
                "std_mnemonic_displayname": "Activity",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "activitycode",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "Activitycode",
                "src_mnemonic": "activitycode",
                "src_unit": "unitless",
                "std_curve_description": "Activitycode",
                "std_mnemonic_displayname": "Activity Code",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "annularvolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "AnnularVolume",
                "src_mnemonic": "annularvolume",
                "src_unit": "bbls",
                "std_curve_description": "AnnularVolume",
                "std_mnemonic_displayname": "Annular Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "bhalength",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "BhaLength",
                "src_mnemonic": "bhalength",
                "src_unit": "ft",
                "std_curve_description": "BhaLength",
                "std_mnemonic_displayname": "Bha Length",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "binghamplasticmodelannularpressureloss",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "BinghamPlasticModelAnnularPressureLoss",
                "src_mnemonic": "binghamplasticmodelannularpressureloss",
                "src_unit": "psi",
                "std_curve_description": "BinghamPlasticModelAnnularPressureLoss",
                "std_mnemonic_displayname": "Annular Pressure Loss",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "binghamplasticmodeldrillstringpressureloss",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "BinghamPlasticModelDrillStringpressureLoss",
                "src_mnemonic": "binghamplasticmodeldrillstringpressureloss",
                "src_unit": "psi",
                "std_curve_description": "BinghamPlasticModelDrillStringpressureLoss",
                "std_mnemonic_displayname": "String Pressure Loss",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "binghamplasticmodelstandpipepressureloss",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "BinghamPlasticModelstandpipePressureLoss",
                "src_mnemonic": "binghamplasticmodelstandpipepressureloss",
                "src_unit": "psi",
                "std_curve_description": "BinghamPlasticModelstandpipePressureLoss",
                "std_mnemonic_displayname": "Total Pressure loss",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "bithydraulichorsepower",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "BitHydraulicHorsePower",
                "src_mnemonic": "bithydraulichorsepower",
                "src_unit": "hp",
                "std_curve_description": "BitHydraulicHorsePower",
                "std_mnemonic_displayname": "HHP",
                "std_unit": "hp"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "bitpressuredrop",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "BitPressureDrop",
                "src_mnemonic": "bitpressuredrop",
                "src_unit": "psi",
                "std_curve_description": "BitPressureDrop",
                "std_mnemonic_displayname": "Bit Pressure Loss",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "bitsize",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "BITSIZE",
                "src_mnemonic": "bitsize",
                "src_unit": "inch",
                "std_curve_description": "BITSIZE",
                "std_mnemonic_displayname": "BIT SIZE",
                "std_unit": "inch"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "btvd",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "Bit True Vertical Depth",
                "src_mnemonic": "btvd",
                "src_unit": "ft",
                "std_curve_description": "Bit True Vertical Depth",
                "std_mnemonic_displayname": "B TVD",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "casedholevolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CasedHoleVolume",
                "src_mnemonic": "casedholevolume",
                "src_unit": "bbls",
                "std_curve_description": "CasedHoleVolume",
                "std_mnemonic_displayname": "CH Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "casingdc1annularcapacity",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CasingDC1AnnularCapacity",
                "src_mnemonic": "casingdc1annularcapacity",
                "src_unit": "bbl/ft",
                "std_curve_description": "CasingDC1AnnularCapacity",
                "std_mnemonic_displayname": "Casing DC1 Annular Capacity",
                "std_unit": "bbl/ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "casingdc1length",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CasingDC1Length",
                "src_mnemonic": "casingdc1length",
                "src_unit": "ft",
                "std_curve_description": "CasingDC1Length",
                "std_mnemonic_displayname": "Casing DC1 Length",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "casingdc2annularcapacity",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CasingDC2AnnularCapacity",
                "src_mnemonic": "casingdc2annularcapacity",
                "src_unit": "bbl/ft",
                "std_curve_description": "CasingDC2AnnularCapacity",
                "std_mnemonic_displayname": "Casing DC2 Annular Capacity",
                "std_unit": "bbl/ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "casingdc2length",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CasingDC2Length",
                "src_mnemonic": "casingdc2length",
                "src_unit": "ft",
                "std_curve_description": "CasingDC2Length",
                "std_mnemonic_displayname": "Casing DC2 Length",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "casingdpannularcapacity",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CasingDPAnnularCapacity",
                "src_mnemonic": "casingdpannularcapacity",
                "src_unit": "bbl/ft",
                "std_curve_description": "CasingDPAnnularCapacity",
                "std_mnemonic_displayname": "Casing DP Annular Capacity",
                "std_unit": "bbl/ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "casingdplength",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CasingDPLength",
                "src_mnemonic": "casingdplength",
                "src_unit": "ft",
                "std_curve_description": "CasingDPLength",
                "std_mnemonic_displayname": "Casing DP Length",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "casinghwdpannularcapacity",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CasingHWDPAnnularCapacity",
                "src_mnemonic": "casinghwdpannularcapacity",
                "src_unit": "bbl/ft",
                "std_curve_description": "CasingHWDPAnnularCapacity",
                "std_mnemonic_displayname": "Casing HWDP Annular Capacity",
                "std_unit": "bbl/ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "casinghwdplength",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CasingHWDPLength",
                "src_mnemonic": "casinghwdplength",
                "src_unit": "ft",
                "std_curve_description": "CasingHWDPLength",
                "std_mnemonic_displayname": "Casing HWDP Length",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "casingid",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CasingID",
                "src_mnemonic": "casingid",
                "src_unit": "inch",
                "std_curve_description": "CasingID",
                "std_mnemonic_displayname": "Casing ID",
                "std_unit": "inch"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "casinglength",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CasingLength",
                "src_mnemonic": "casinglength",
                "src_unit": "ft",
                "std_curve_description": "CasingLength",
                "std_mnemonic_displayname": "Casing Length",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "casingod",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CasingOD",
                "src_mnemonic": "casingod",
                "src_unit": "inch",
                "std_curve_description": "CasingOD",
                "std_mnemonic_displayname": "Casing OD",
                "std_unit": "inch"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "casingtvd",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CasingTVD",
                "src_mnemonic": "casingtvd",
                "src_unit": "ft",
                "std_curve_description": "CasingTVD",
                "std_mnemonic_displayname": "Casing TVD",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "chdc1annularstrokes",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CHDC1AnnularStrokes",
                "src_mnemonic": "chdc1annularstrokes",
                "src_unit": "stks",
                "std_curve_description": "CHDC1AnnularStrokes",
                "std_mnemonic_displayname": "CH DC1 Annular Strokes",
                "std_unit": "stks"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "chdc1annularvolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CHDC1AnnularVolume",
                "src_mnemonic": "chdc1annularvolume",
                "src_unit": "bbls",
                "std_curve_description": "CHDC1AnnularVolume",
                "std_mnemonic_displayname": "CH DC1 Annular Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "chdc2annularstrokes",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CHDC2AnnularStrokes",
                "src_mnemonic": "chdc2annularstrokes",
                "src_unit": "stks",
                "std_curve_description": "CHDC2AnnularStrokes",
                "std_mnemonic_displayname": "CH DC2 Annular Strokes",
                "std_unit": "stks"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "chdc2annularvolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CHDC2AnnularVolume",
                "src_mnemonic": "chdc2annularvolume",
                "src_unit": "bbls",
                "std_curve_description": "CHDC2AnnularVolume",
                "std_mnemonic_displayname": "CH DC2 Annular Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "chdpannularstrokes",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CHDPAnnularStrokes",
                "src_mnemonic": "chdpannularstrokes",
                "src_unit": "stks",
                "std_curve_description": "CHDPAnnularStrokes",
                "std_mnemonic_displayname": "CH DP Annular Strokes",
                "std_unit": "stks"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "chdpannularvolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CHDPAnnularVolume",
                "src_mnemonic": "chdpannularvolume",
                "src_unit": "bbls",
                "std_curve_description": "CHDPAnnularVolume",
                "std_mnemonic_displayname": "CH DP Annular Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "chhwdpannularstrokes",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CHHWDPAnnularStrokes",
                "src_mnemonic": "chhwdpannularstrokes",
                "src_unit": "stks",
                "std_curve_description": "CHHWDPAnnularStrokes",
                "std_mnemonic_displayname": "CH HWDP Annular Strokes",
                "std_unit": "stks"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "chhwdpannularvolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CHHWDPAnnularVolume",
                "src_mnemonic": "chhwdpannularvolume",
                "src_unit": "bbls",
                "std_curve_description": "CHHWDPAnnularVolume",
                "std_mnemonic_displayname": "CH HWDP Annular Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "consistencyindex",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "ConsistencyIndex",
                "src_mnemonic": "consistencyindex",
                "src_unit": "unitless",
                "std_curve_description": "ConsistencyIndex",
                "std_mnemonic_displayname": "Consistency Index",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "cuttingtransportefficiency",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CuttingtransportEfficiency",
                "src_mnemonic": "cuttingtransportefficiency",
                "src_unit": "%",
                "std_curve_description": "CuttingtransportEfficiency",
                "std_mnemonic_displayname": "Overall TE",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "cyclecount",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CycleCount",
                "src_mnemonic": "cyclecount",
                "src_unit": "X",
                "std_curve_description": "CycleCount",
                "std_mnemonic_displayname": "Cycle Count",
                "std_unit": "X"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "cyclestrokes",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CycleStrokes",
                "src_mnemonic": "cyclestrokes",
                "src_unit": "stks",
                "std_curve_description": "CycleStrokes",
                "std_mnemonic_displayname": "CycleStrokes",
                "std_unit": "stks"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "cycletime",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "Cycletime",
                "src_mnemonic": "cycletime",
                "src_unit": "mins",
                "std_curve_description": "Cycletime",
                "std_mnemonic_displayname": "Cycle Time",
                "std_unit": "mins"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "cyclevolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "CycleVolume",
                "src_mnemonic": "cyclevolume",
                "src_unit": "bbls",
                "std_curve_description": "CycleVolume",
                "std_mnemonic_displayname": "Cycle Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dbtm",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "Bit Position",
                "src_mnemonic": "BIT_DEPTH",
                "src_unit": "m",
                "std_curve_description": "Bit Depth (measured)",
                "std_mnemonic_displayname": "BIT DEPTH",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dc1annularcapacity",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DC1AnnularCapacity",
                "src_mnemonic": "dc1annularcapacity",
                "src_unit": "bbl/ft",
                "std_curve_description": "DC1AnnularCapacity",
                "std_mnemonic_displayname": "DC1 Annular Capacity",
                "std_unit": "bbl/ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dc1annularstrokes",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DC1AnnularStrokes",
                "src_mnemonic": "dc1annularstrokes",
                "src_unit": "stks",
                "std_curve_description": "DC1AnnularStrokes",
                "std_mnemonic_displayname": "DC1 Annular Strokes",
                "std_unit": "stks"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dc1annularvolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DC1AnnularVolume",
                "src_mnemonic": "dc1annularvolume",
                "src_unit": "bbls",
                "std_curve_description": "DC1AnnularVolume",
                "std_mnemonic_displayname": "DC1 Annular Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dc1capacity",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DC1Capacity",
                "src_mnemonic": "dc1capacity",
                "src_unit": "bbl/ft",
                "std_curve_description": "DC1Capacity",
                "std_mnemonic_displayname": "DC1 Capacity",
                "std_unit": "bbl/ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dc1id",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DC1ID",
                "src_mnemonic": "dc1id",
                "src_unit": "inch",
                "std_curve_description": "DC1ID",
                "std_mnemonic_displayname": "DC1 ID",
                "std_unit": "inch"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dc1length",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DC1Length",
                "src_mnemonic": "dc1length",
                "src_unit": "ft",
                "std_curve_description": "DC1Length",
                "std_mnemonic_displayname": "DC1 Length",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dc1od",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DC1OD",
                "src_mnemonic": "dc1od",
                "src_unit": "inch",
                "std_curve_description": "DC1OD",
                "std_mnemonic_displayname": "DC1 OD",
                "std_unit": "inch"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dc1strokes",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DC1Strokes",
                "src_mnemonic": "dc1strokes",
                "src_unit": "stks",
                "std_curve_description": "DC1Strokes",
                "std_mnemonic_displayname": "DC1 Strokes",
                "std_unit": "stks"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dc1volume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DC1Volume",
                "src_mnemonic": "dc1volume",
                "src_unit": "bbls",
                "std_curve_description": "DC1Volume",
                "std_mnemonic_displayname": "DC1 Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dc2annularcapacity",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DC2AnnularCapacity",
                "src_mnemonic": "dc2annularcapacity",
                "src_unit": "bbl/ft",
                "std_curve_description": "DC2AnnularCapacity",
                "std_mnemonic_displayname": "DC2 Annular Capacity",
                "std_unit": "bbl/ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dc2annularstrokes",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DC2AnnularStrokes",
                "src_mnemonic": "dc2annularstrokes",
                "src_unit": "stks",
                "std_curve_description": "DC2AnnularStrokes",
                "std_mnemonic_displayname": "DC2 Annular Strokes",
                "std_unit": "stks"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dc2annularvolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DC2AnnularVolume",
                "src_mnemonic": "dc2annularvolume",
                "src_unit": "bbls",
                "std_curve_description": "DC2AnnularVolume",
                "std_mnemonic_displayname": "DC2 Annular Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dc2capacity",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DC2Capacity",
                "src_mnemonic": "dc2capacity",
                "src_unit": "bbl/ft",
                "std_curve_description": "DC2Capacity",
                "std_mnemonic_displayname": "DC2 Capacity",
                "std_unit": "bbl/ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dc2id",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DC2ID",
                "src_mnemonic": "dc2id",
                "src_unit": "inch",
                "std_curve_description": "DC2ID",
                "std_mnemonic_displayname": "DC2 ID",
                "std_unit": "inch"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dc2length",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DC2Length",
                "src_mnemonic": "dc2length",
                "src_unit": "ft",
                "std_curve_description": "DC2Length",
                "std_mnemonic_displayname": "DC2 Length",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dc2od",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DC2OD",
                "src_mnemonic": "dc2od",
                "src_unit": "inch",
                "std_curve_description": "DC2OD",
                "std_mnemonic_displayname": "DC2 OD",
                "std_unit": "inch"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dc2strokes",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DC2Strokes",
                "src_mnemonic": "dc2strokes",
                "src_unit": "stks",
                "std_curve_description": "DC2Strokes",
                "std_mnemonic_displayname": "DC2 Strokes",
                "std_unit": "stks"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dc2volume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DC2Volume",
                "src_mnemonic": "dc2volume",
                "src_unit": "bbls",
                "std_curve_description": "DC2Volume",
                "std_mnemonic_displayname": "DC2 Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "diff",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "Diff Press",
                "src_mnemonic": "DIFF_PRESS",
                "src_unit": "psi",
                "std_curve_description": "Differential Pressure",
                "std_mnemonic_displayname": "DIFFERENTIAL PRESSURE",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dmea",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "Hole Depth",
                "src_mnemonic": "TOT_DPT_MD",
                "src_unit": "m",
                "std_curve_description": "Hole Depth (measured)",
                "std_mnemonic_displayname": "HOLE DEPTH",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dpannularcapacity",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DPAnnularCapacity",
                "src_mnemonic": "dpannularcapacity",
                "src_unit": "bbl/ft",
                "std_curve_description": "DPAnnularCapacity",
                "std_mnemonic_displayname": "DP Annular Capacity",
                "std_unit": "bbl/ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dpannularstrokes",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DPAnnularStrokes",
                "src_mnemonic": "dpannularstrokes",
                "src_unit": "stks",
                "std_curve_description": "DPAnnularStrokes",
                "std_mnemonic_displayname": "DP Annular Strokes",
                "std_unit": "stks"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dpannularvolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DPAnnularVolume",
                "src_mnemonic": "dpannularvolume",
                "src_unit": "bbls",
                "std_curve_description": "DPAnnularVolume",
                "std_mnemonic_displayname": "DP Annular Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dpcapacity",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DPCapacity",
                "src_mnemonic": "dpcapacity",
                "src_unit": "bbl/ft",
                "std_curve_description": "DPCapacity",
                "std_mnemonic_displayname": "DP Capacity",
                "std_unit": "bbl/ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dpid",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DPID",
                "src_mnemonic": "dpid",
                "src_unit": "inch",
                "std_curve_description": "DPID",
                "std_mnemonic_displayname": "DP ID",
                "std_unit": "inch"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dplength",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DPLength",
                "src_mnemonic": "dplength",
                "src_unit": "ft",
                "std_curve_description": "DPLength",
                "std_mnemonic_displayname": "DP Length",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dpod",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DPOD",
                "src_mnemonic": "dpod",
                "src_unit": "inch",
                "std_curve_description": "DPOD",
                "std_mnemonic_displayname": "DP OD",
                "std_unit": "inch"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dpstrokes",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DPStrokes",
                "src_mnemonic": "dpstrokes",
                "src_unit": "stks",
                "std_curve_description": "DPStrokes",
                "std_mnemonic_displayname": "DP Strokes",
                "std_unit": "stks"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dpvolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DPVolume",
                "src_mnemonic": "dpvolume",
                "src_unit": "bbls",
                "std_curve_description": "DPVolume",
                "std_mnemonic_displayname": "DP Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "drillcuttingconcentration",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DrillCuttingConcentration",
                "src_mnemonic": "drillcuttingconcentration",
                "src_unit": "%",
                "std_curve_description": "DrillCuttingConcentration",
                "std_mnemonic_displayname": "Cuttings to Hole Volume",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "drillstringvolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "DrillStringVolume",
                "src_mnemonic": "drillstringvolume",
                "src_unit": "bbls",
                "std_curve_description": "DrillStringVolume",
                "std_mnemonic_displayname": "Drill String Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ecdatbitwithbinghamplasticmodel",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "ecdatbitWithbinghamPlasticModel",
                "src_mnemonic": "ecdatbitwithbinghamplasticmodel",
                "src_unit": "lbf/galUS",
                "std_curve_description": "ecdatbitWithbinghamPlasticModel",
                "std_mnemonic_displayname": "ECD @ Bit",
                "std_unit": "lbf/galUS"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ecdatbitwithpowerlawmodel",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "EcdatbitWithPowerLawModel",
                "src_mnemonic": "ecdatbitwithpowerlawmodel",
                "src_unit": "lbf/galUS",
                "std_curve_description": "EcdatbitWithPowerLawModel",
                "std_mnemonic_displayname": "EcdatbitWithPowerLawModel",
                "std_unit": "lbf/galUS"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ecdatcasingwithbinghamplasticmodel",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "EcdatCasingWithbinghamPlasticModel",
                "src_mnemonic": "ecdatcasingwithbinghamplasticmodel",
                "src_unit": "lbf/galUS",
                "std_curve_description": "EcdatCasingWithbinghamPlasticModel",
                "std_mnemonic_displayname": "ECD @ Casing",
                "std_unit": "lbf/galUS"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ecdatcasingwithpowerlawmodel",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "EcdatCasingWithPowerLawModel",
                "src_mnemonic": "ecdatcasingwithpowerlawmodel",
                "src_unit": "lbf/galUS",
                "std_curve_description": "EcdatCasingWithPowerLawModel",
                "std_mnemonic_displayname": "EcdatCasingWithPowerLawModel",
                "std_unit": "lbf/galUS"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "flowindex",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "FlowIndex",
                "src_mnemonic": "flowindex",
                "src_unit": "unitless",
                "std_curve_description": "FlowIndex",
                "std_mnemonic_displayname": "Flow Index",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "hkl",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "Hook Load",
                "src_mnemonic": "HOOKLOAD_MAX",
                "src_unit": "klb",
                "std_curve_description": "Hook Load",
                "std_mnemonic_displayname": "HOOK LOAD",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "horsepowerperinch",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "HorsePowerPerInch",
                "src_mnemonic": "horsepowerperinch",
                "src_unit": "hp/(sq inch)",
                "std_curve_description": "HorsePowerPerInch",
                "std_mnemonic_displayname": "HSI",
                "std_unit": "hp/(sq inch)"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "hwdpannularcapacity",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "HWDPAnnularCapacity",
                "src_mnemonic": "hwdpannularcapacity",
                "src_unit": "bbl/ft",
                "std_curve_description": "HWDPAnnularCapacity",
                "std_mnemonic_displayname": "HWDP Annular Capacity",
                "std_unit": "bbl/ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "hwdpannularstrokes",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "HWDPAnnularStrokes",
                "src_mnemonic": "hwdpannularstrokes",
                "src_unit": "stks",
                "std_curve_description": "HWDPAnnularStrokes",
                "std_mnemonic_displayname": "HWDP Annular Strokes",
                "std_unit": "stks"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "hwdpannularvolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "HWDPAnnularVolume",
                "src_mnemonic": "hwdpannularvolume",
                "src_unit": "bbls",
                "std_curve_description": "HWDPAnnularVolume",
                "std_mnemonic_displayname": "HWDP Annular Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "hwdpcapacity",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "HWDPCapacity",
                "src_mnemonic": "hwdpcapacity",
                "src_unit": "bbl/ft",
                "std_curve_description": "HWDPCapacity",
                "std_mnemonic_displayname": "HWDP Capacity",
                "std_unit": "bbl/ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "hwdpid",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "HWDPID",
                "src_mnemonic": "hwdpid",
                "src_unit": "inch",
                "std_curve_description": "HWDPID",
                "std_mnemonic_displayname": "HWDP ID",
                "std_unit": "inch"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "hwdplength",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "HWDPLength",
                "src_mnemonic": "hwdplength",
                "src_unit": "ft",
                "std_curve_description": "HWDPLength",
                "std_mnemonic_displayname": "HWDP Length",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "hwdpod",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "HWDPOD",
                "src_mnemonic": "hwdpod",
                "src_unit": "inch",
                "std_curve_description": "HWDPOD",
                "std_mnemonic_displayname": "HWDP OD",
                "std_unit": "inch"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "hwdpstrokes",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "HWDPStrokes",
                "src_mnemonic": "hwdpstrokes",
                "src_unit": "stks",
                "std_curve_description": "HWDPStrokes",
                "std_mnemonic_displayname": "HWDP Strokes",
                "std_unit": "stks"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "hwdpvolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "HWDPVolume",
                "src_mnemonic": "hwdpvolume",
                "src_unit": "bbls",
                "std_curve_description": "HWDPVolume",
                "std_mnemonic_displayname": "HWDP Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "hydrostaticpressure",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "HydrostaticPressure",
                "src_mnemonic": "hydrostaticpressure",
                "src_unit": "psi",
                "std_curve_description": "HydrostaticPressure",
                "std_mnemonic_displayname": "Hydrostatic Press",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "lagstrokes",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "LagStrokes",
                "src_mnemonic": "lagstrokes",
                "src_unit": "stks",
                "std_curve_description": "LagStrokes",
                "std_mnemonic_displayname": "Lag Strokes",
                "std_unit": "stks"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "lagtime",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "LagTime",
                "src_mnemonic": "lagtime",
                "src_unit": "mins",
                "std_curve_description": "LagTime",
                "std_mnemonic_displayname": "Lag Time",
                "std_unit": "mins"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "mfi",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "Flow In Rate",
                "src_mnemonic": "FLOW_IN",
                "src_unit": "galUS/min",
                "std_curve_description": "Mud Flow In",
                "std_mnemonic_displayname": "MUD FLOW IN",
                "std_unit": "galUS/min"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "mud density",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "Mud Weight In",
                "src_mnemonic": "DENS_IN",
                "src_unit": "lb/galUS",
                "std_curve_description": "Mud Weight in",
                "std_mnemonic_displayname": "MUD WEIGHT IN",
                "std_unit": "lbf/galUS"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "nozzleimpactforce",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "NozzleImpactForce",
                "src_mnemonic": "nozzleimpactforce",
                "src_unit": "lbf",
                "std_curve_description": "NozzleImpactForce",
                "std_mnemonic_displayname": "Impact Force",
                "std_unit": "lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "nozzlevelocity",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "NozzleVelocity",
                "src_mnemonic": "nozzlevelocity",
                "src_unit": "ft/sec",
                "std_curve_description": "NozzleVelocity",
                "std_mnemonic_displayname": "Nozzle Velocity",
                "std_unit": "ft/sec"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ohdc1annularcapacity",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "OHDC1AnnularCapacity",
                "src_mnemonic": "ohdc1annularcapacity",
                "src_unit": "bbl/ft",
                "std_curve_description": "OHDC1AnnularCapacity",
                "std_mnemonic_displayname": "OH DC1 Annular Capacity",
                "std_unit": "bbl/ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ohdc1annularstrokes",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "OHDC1AnnularStrokes",
                "src_mnemonic": "ohdc1annularstrokes",
                "src_unit": "stks",
                "std_curve_description": "OHDC1AnnularStrokes",
                "std_mnemonic_displayname": "OH DC1 Annular Strokes",
                "std_unit": "stks"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ohdc1annularvolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "OH DC1AnnularVolume",
                "src_mnemonic": "ohdc1annularvolume",
                "src_unit": "bbls",
                "std_curve_description": "OH DC1AnnularVolume",
                "std_mnemonic_displayname": "OH DC1 Annular Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ohdc2annularcapacity",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "OHDC2AnnularCapacity",
                "src_mnemonic": "ohdc2annularcapacity",
                "src_unit": "bbl/ft",
                "std_curve_description": "OHDC2AnnularCapacity",
                "std_mnemonic_displayname": "OH DC2 Annular Capacity",
                "std_unit": "bbl/ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ohdc2annularstrokes",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "OHDC2AnnularStrokes",
                "src_mnemonic": "ohdc2annularstrokes",
                "src_unit": "stks",
                "std_curve_description": "OHDC2AnnularStrokes",
                "std_mnemonic_displayname": "OH DC2 Annular Strokes",
                "std_unit": "stks"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ohdc2annularvolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "OHDC2AnnularVolume",
                "src_mnemonic": "ohdc2annularvolume",
                "src_unit": "bbls",
                "std_curve_description": "OHDC2AnnularVolume",
                "std_mnemonic_displayname": "OH DC2 Annular Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ohdpannularcapacity",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "OHDPAnnularCapacity",
                "src_mnemonic": "ohdpannularcapacity",
                "src_unit": "bbl/ft",
                "std_curve_description": "OHDPAnnularCapacity",
                "std_mnemonic_displayname": "OH DP Annular Capacity",
                "std_unit": "bbl/ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ohdpannularstrokes",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "OHDPAnnularStrokes",
                "src_mnemonic": "ohdpannularstrokes",
                "src_unit": "stks",
                "std_curve_description": "OHDPAnnularStrokes",
                "std_mnemonic_displayname": "OH DP Annular Strokes",
                "std_unit": "stks"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ohdpannularvolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "OHDPAnnularVolume",
                "src_mnemonic": "ohdpannularvolume",
                "src_unit": "bbls",
                "std_curve_description": "OHDPAnnularVolume",
                "std_mnemonic_displayname": "OH DP Annular Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ohhwdpannularcapacity",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "OHHWDPAnnularCapacity",
                "src_mnemonic": "ohhwdpannularcapacity",
                "src_unit": "bbl/ft",
                "std_curve_description": "OHHWDPAnnularCapacity",
                "std_mnemonic_displayname": "OH HWDP Annular Capacity",
                "std_unit": "bbl/ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ohhwdpannularstrokes",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "OHHWDPAnnularStrokes",
                "src_mnemonic": "ohhwdpannularstrokes",
                "src_unit": "stks",
                "std_curve_description": "OHHWDPAnnularStrokes",
                "std_mnemonic_displayname": "OH HWDP Annular Strokes",
                "std_unit": "stks"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ohhwdpannularvolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "OHHWDPAnnularVolume",
                "src_mnemonic": "ohhwdpannularvolume",
                "src_unit": "bbls",
                "std_curve_description": "OHHWDPAnnularVolume",
                "std_mnemonic_displayname": "OH HWDP Annular Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "openholedc1length",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "OpenHoleDC1Length",
                "src_mnemonic": "openholedc1length",
                "src_unit": "ft",
                "std_curve_description": "OpenHoleDC1Length",
                "std_mnemonic_displayname": "Open Hole DC1 Length",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "openholedc2length",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "OpenHoleDC2Length",
                "src_mnemonic": "openholedc2length",
                "src_unit": "ft",
                "std_curve_description": "OpenHoleDC2Length",
                "std_mnemonic_displayname": "Open Hole DC2 Length",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "openholedplength",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "OpenHoleDPLength",
                "src_mnemonic": "openholedplength",
                "src_unit": "ft",
                "std_curve_description": "OpenHoleDPLength",
                "std_mnemonic_displayname": "Open Hole DP Length",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "openholehwdplength",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "OpenHoleHWDPLength",
                "src_mnemonic": "openholehwdplength",
                "src_unit": "ft",
                "std_curve_description": "OpenHoleHWDPLength",
                "std_mnemonic_displayname": "Open Hole HWDP Length",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "openholelength",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "OpenHoleLength",
                "src_mnemonic": "openholelength",
                "src_unit": "ft",
                "std_curve_description": "OpenHoleLength",
                "std_mnemonic_displayname": "OpenHole Length",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "openholevolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "OpenHoleVolume",
                "src_mnemonic": "openholevolume",
                "src_unit": "bbls",
                "std_curve_description": "OpenHoleVolume",
                "std_mnemonic_displayname": "OH Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "optimizedrop",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "OptimizedROP",
                "src_mnemonic": "optimizedrop",
                "src_unit": "ft/hr",
                "std_curve_description": "OptimizedROP",
                "std_mnemonic_displayname": "Optimized ROP",
                "std_unit": "ft/hr"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pitgain",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "PitGain",
                "src_mnemonic": "pitgain",
                "src_unit": "bbl",
                "std_curve_description": "PitGain",
                "std_mnemonic_displayname": "Pit Gain",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "plasticviscosity",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "PlasticViscosity",
                "src_mnemonic": "plasticviscosity",
                "src_unit": "cp",
                "std_curve_description": "PlasticViscosity",
                "std_mnemonic_displayname": "Plastic Viscosity",
                "std_unit": "cp"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "powerlawmodelannularpressureloss",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "PowerLawModelAnnularPressureLoss",
                "src_mnemonic": "powerlawmodelannularpressureloss",
                "src_unit": "psi",
                "std_curve_description": "PowerLawModelAnnularPressureLoss",
                "std_mnemonic_displayname": "PowerLawModelAnnularPressureLoss",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "powerlawmodeldrillstringpressureloss",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "PowerLawModelDrillStringpressureLoss",
                "src_mnemonic": "powerlawmodeldrillstringpressureloss",
                "src_unit": "psi",
                "std_curve_description": "PowerLawModelDrillStringpressureLoss",
                "std_mnemonic_displayname": "PowerLawModelDrillStringpressureLoss",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "powerlawmodelstandpipepressureloss",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "PowerLawModelstandpipePressureLoss",
                "src_mnemonic": "powerlawmodelstandpipepressureloss",
                "src_unit": "psi",
                "std_curve_description": "PowerLawModelstandpipePressureLoss",
                "std_mnemonic_displayname": "PowerLawModelstandpipePressureLoss",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pumpedvolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "PumpedVolume",
                "src_mnemonic": "pumpedvolume",
                "src_unit": "bbls",
                "std_curve_description": "PumpedVolume",
                "std_mnemonic_displayname": "Pumped Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "pumpoutput",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "PumpOutput",
                "src_mnemonic": "pumpoutput",
                "src_unit": "bbl/stk",
                "std_curve_description": "PumpOutput",
                "std_mnemonic_displayname": "Pump Output",
                "std_unit": "bbl/stk"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "r300",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "R300",
                "src_mnemonic": "r300",
                "src_unit": "unitless",
                "std_curve_description": "R300",
                "std_mnemonic_displayname": "r300",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "r600",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "R600",
                "src_mnemonic": "r600",
                "src_unit": "unitless",
                "std_curve_description": "R600",
                "std_mnemonic_displayname": "R600",
                "std_unit": "unitless"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "riservolume",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "RiserVolume",
                "src_mnemonic": "riservolume",
                "src_unit": "bbls",
                "std_curve_description": "RiserVolume",
                "std_mnemonic_displayname": "Riser Volume",
                "std_unit": "bbls"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "rop",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "ROP - Fast",
                "src_mnemonic": "FAST_ROP_FT_HR",
                "src_unit": "ft/h",
                "std_curve_description": "Rate of Penetration",
                "std_mnemonic_displayname": "ROP",
                "std_unit": "ft/h"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "rpm",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "Top Drive RPM",
                "src_mnemonic": "TD_SPEED",
                "src_unit": "rpm",
                "std_curve_description": "Rotation per Minute",
                "std_mnemonic_displayname": "RPM",
                "std_unit": "rpm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "simplifiedmse",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "SimplifiedMSE",
                "src_mnemonic": "simplifiedmse",
                "src_unit": "psi",
                "std_curve_description": "SimplifiedMSE",
                "std_mnemonic_displayname": "Simplified MSE",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "slugweight",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "SLUGWEIGHT",
                "src_mnemonic": "slugweight",
                "src_unit": "lbf/galUS",
                "std_curve_description": "SLUGWEIGHT",
                "std_mnemonic_displayname": "SLUG WEIGHT",
                "std_unit": "lbf/galUS"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "spm1",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "Pump SPM 1",
                "src_mnemonic": "MP1_SPM",
                "src_unit": "SPM",
                "std_curve_description": "Pump 1 strokes/min",
                "std_mnemonic_displayname": "SPM1",
                "std_unit": "spm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "spm2",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "Pump SPM 2",
                "src_mnemonic": "MP2_SPM",
                "src_unit": "SPM",
                "std_curve_description": "Pump 2 strokes/min",
                "std_mnemonic_displayname": "SPM2",
                "std_unit": "spm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "spm3",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "Pump SPM 3",
                "src_mnemonic": "MP3_SPM",
                "src_unit": "SPM",
                "std_curve_description": "Pump 3 strokes/min",
                "std_mnemonic_displayname": "SPM3",
                "std_unit": "spm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "spp",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "Pump Pressure",
                "src_mnemonic": "STP_PRS_1",
                "src_unit": "psi",
                "std_curve_description": "Standpipe Pressure",
                "std_mnemonic_displayname": "SPP",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "stringstrokes",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "StringStrokes",
                "src_mnemonic": "stringstrokes",
                "src_unit": "stks",
                "std_curve_description": "StringStrokes",
                "std_mnemonic_displayname": "String Strokes",
                "std_unit": "stks"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "surfacepressureloss",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "SurfacePressureLoss",
                "src_mnemonic": "surfacepressureloss",
                "src_unit": "psi",
                "std_curve_description": "SurfacePressureLoss",
                "std_mnemonic_displayname": "Surface Press Loss",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "surfacetobittime",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "SurfacetoBitTime",
                "src_mnemonic": "surfacetobittime",
                "src_unit": "mins",
                "std_curve_description": "SurfacetoBitTime",
                "std_mnemonic_displayname": "Surface to Bit Time",
                "std_unit": "mins"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "time",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "RIGTIME",
                "src_mnemonic": "RIGTIME",
                "src_unit": "DateTime",
                "std_curve_description": "Time of Measurement",
                "std_mnemonic_displayname": "TIME",
                "std_unit": "s"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tor",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "Top Drive Torque",
                "src_mnemonic": "TD_TORQUE",
                "src_unit": "ft.lbf",
                "std_curve_description": "Torque",
                "std_mnemonic_displayname": "TORQUE",
                "std_unit": "kft.lbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tot_spm",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "Pump SPM - Total",
                "src_mnemonic": "TOT_SPM",
                "src_unit": "SPM",
                "std_curve_description": "Total SPM",
                "std_mnemonic_displayname": "TOTAL SPM",
                "std_unit": "spm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "totalflowarea",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "TotalFlowArea",
                "src_mnemonic": "totalflowarea",
                "src_unit": "in^2",
                "std_curve_description": "TotalFlowArea",
                "std_mnemonic_displayname": "TFA",
                "std_unit": "in^2"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tvd",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "TVD",
                "src_mnemonic": "TVD",
                "src_unit": "m",
                "std_curve_description": "TVD",
                "std_mnemonic_displayname": "TVD",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "vslug",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "VSLUG",
                "src_mnemonic": "vslug",
                "src_unit": "lbf/galUS",
                "std_curve_description": "VSLUG",
                "std_mnemonic_displayname": "VSLUG",
                "std_unit": "lbf/galUS"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "wob",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "Bit Weight",
                "src_mnemonic": "WOB",
                "src_unit": "klb",
                "std_curve_description": "Weight on Bit",
                "std_mnemonic_displayname": "WOB",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "yieldpoint",
                "mnemonic_type": "SOLUTION",
                "src_curve_description": "YieldPoint",
                "src_mnemonic": "yieldpoint",
                "src_unit": "lbf/100 ft2",
                "std_curve_description": "YieldPoint",
                "std_mnemonic_displayname": "Yield Point",
                "std_unit": "lbf/100 ft2"
            }
        ],
        "PREFERRED": [
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ann_pressure",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Ann Pressure",
                "src_mnemonic": "ANN_PRESSURE",
                "src_unit": "psi",
                "std_curve_description": "Annular Pressure",
                "std_mnemonic_displayname": "ANNULAR PRESSURE",
                "std_unit": "psi"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "azimuth",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Svy Azimuth",
                "src_mnemonic": "SRV_AZI",
                "src_unit": "rad",
                "std_curve_description": "Survey - Azimuth",
                "std_mnemonic_displayname": "AZI",
                "std_unit": "dega"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "bit rpm",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Bit RPM",
                "src_mnemonic": "BIT_RPM",
                "src_unit": "rpm",
                "std_curve_description": "Bit RPM",
                "std_mnemonic_displayname": "BIT RPM",
                "std_unit": "rpm"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "bpos",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Block Height",
                "src_mnemonic": "BLOCK_POS",
                "src_unit": "m",
                "std_curve_description": "Block Position",
                "std_mnemonic_displayname": "BLOCK POSITION",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "cirh",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Circulating Hrs",
                "src_mnemonic": "Circulating Hrs",
                "src_unit": "hr",
                "std_curve_description": "Circulating Hours",
                "std_mnemonic_displayname": "CIRCULATING HRS",
                "std_unit": "hr"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "dls",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Dog Leg Severity",
                "src_mnemonic": "Dog Leg Severity",
                "src_unit": "dega/100ft",
                "std_curve_description": "Survey Dogleg Severity",
                "std_mnemonic_displayname": "DLS",
                "std_unit": "deg/100ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "flow_out",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Flow Out Percent",
                "src_mnemonic": "FLOW_OUT_REL",
                "src_unit": "%",
                "std_curve_description": "Flow out",
                "std_mnemonic_displayname": "FLOW OUT",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gamma_ray",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Gamma Ray",
                "src_mnemonic": "GAMMA_RAY",
                "src_unit": "API",
                "std_curve_description": "Gamma Ray",
                "std_mnemonic_displayname": "GAMMA RAY",
                "std_unit": "API"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gas_total",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Total Gas (ML)",
                "src_mnemonic": "GAS_TOTAL",
                "src_unit": "1%=100Units",
                "std_curve_description": "Total gas",
                "std_mnemonic_displayname": "Total Gas ",
                "std_unit": "%"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "gtf",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Toolface Grav",
                "src_mnemonic": "SRV_GRA_TF",
                "src_unit": "rad",
                "std_curve_description": "Gravity Tool Face",
                "std_mnemonic_displayname": "GTF",
                "std_unit": "dega"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "inclination",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Svy Inclination",
                "src_mnemonic": "SRV_INC",
                "src_unit": "rad",
                "std_curve_description": "Survey - Inclination",
                "std_mnemonic_displayname": "INC",
                "std_unit": "dega"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "lag_dpt_md",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Lag Depth",
                "src_mnemonic": "LAG_DPT_MD",
                "src_unit": "m",
                "std_curve_description": "Lag Depth",
                "std_mnemonic_displayname": "LAG DEPTH",
                "std_unit": "ft"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "mtf",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Toolface Mag",
                "src_mnemonic": "SRV_MAG_TF",
                "src_unit": "rad",
                "std_curve_description": "Magnetic Tool Face",
                "std_mnemonic_displayname": "MTF",
                "std_unit": "dega"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "mud density out",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Mud Weight Out",
                "src_mnemonic": "DENS_OUT",
                "src_unit": "lb/galUS",
                "std_curve_description": "Mud Weight Out",
                "std_mnemonic_displayname": "MUD WEIGHT OUT",
                "std_unit": "lbf/galUS"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "op",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Overpull",
                "src_mnemonic": "Overpull",
                "src_unit": "klb",
                "std_curve_description": "Overpull",
                "std_mnemonic_displayname": "OVERPULL",
                "std_unit": "klbf"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "slips_stat",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Slip Status",
                "src_mnemonic": "SLIPS_STAT",
                "src_unit": "Status",
                "std_curve_description": "Slips status",
                "std_mnemonic_displayname": "SLIP STATUS",
                "std_unit": "Status"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "temp_in",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Mud Temp In",
                "src_mnemonic": "TEMP_IN",
                "src_unit": "degF",
                "std_curve_description": "Mud Temp In",
                "std_mnemonic_displayname": "TEMP IN",
                "std_unit": "degF"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "temp_out",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Mud Temp Out",
                "src_mnemonic": "TEMP_OUT",
                "src_unit": "degF",
                "std_curve_description": "Mud Temp Out",
                "std_mnemonic_displayname": "TEMP OUT",
                "std_unit": "degF"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tmv",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Mud Volume",
                "src_mnemonic": "TOTAL_VOL",
                "src_unit": "bbl",
                "std_curve_description": "Total Mud Volume",
                "std_mnemonic_displayname": "TOTAL MUD VOLUME",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tot_stk",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Strks - Total",
                "src_mnemonic": "TOT_STK",
                "src_unit": "strokes",
                "std_curve_description": "Total Pump Strokes",
                "std_mnemonic_displayname": "TOTAL PUMP STROKES",
                "std_unit": "strokes"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ts1",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Strks - Pump 1",
                "src_mnemonic": "MP1_STK",
                "src_unit": "strokes",
                "std_curve_description": "Pump 1 total strokes",
                "std_mnemonic_displayname": "PUMP 1 TOTAL STROKES",
                "std_unit": "strokes"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ts2",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Strks - Pump 2",
                "src_mnemonic": "MP2_STK",
                "src_unit": "strokes",
                "std_curve_description": "Pump 2 total strokes",
                "std_mnemonic_displayname": "PUMP 2 TOTAL STROKES",
                "std_unit": "strokes"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "ts3",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Strks - Pump 3",
                "src_mnemonic": "MP3_STK",
                "src_unit": "strokes",
                "std_curve_description": "Pump 3 total strokes",
                "std_mnemonic_displayname": "PUMP 3 TOTAL STROKES",
                "std_unit": "strokes"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tt1_vol",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Trip Tank 1",
                "src_mnemonic": "TT1_VOL",
                "src_unit": "bbl",
                "std_curve_description": "Trip Tank 1 Volume",
                "std_mnemonic_displayname": "TTK1 VOLUME",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tt2_vol",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Trip Tank 2",
                "src_mnemonic": "TT2_VOL",
                "src_unit": "bbl",
                "std_curve_description": "Trip Tank 2 Volume",
                "std_mnemonic_displayname": "TTK2 VOLUME",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tt_vol",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Total Trip Volume",
                "src_mnemonic": "TT_VOL",
                "src_unit": "bbl",
                "std_curve_description": "Total TTK Volume",
                "std_mnemonic_displayname": "TOTAL TTK VOLUME",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tv1",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Pit Volume 1",
                "src_mnemonic": "TANK1_VOL",
                "src_unit": "bbl",
                "std_curve_description": "Mud Tank 1 Volume",
                "std_mnemonic_displayname": "MUD TANK 1 VOLUME",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tv10",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Pit Volume 10",
                "src_mnemonic": "TANK10_VOL",
                "src_unit": "bbl",
                "std_curve_description": "Mud Tank 10 Volume",
                "std_mnemonic_displayname": "MUD TANK 10 VOLUME",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tv11",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Pit Volume 11",
                "src_mnemonic": "Pit Volume 11",
                "src_unit": "bbl",
                "std_curve_description": "Mud Tank 11 Volume",
                "std_mnemonic_displayname": "MUD TANK 11 VOLUME",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tv12",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Pit Volume 12",
                "src_mnemonic": "Pit Volume 12",
                "src_unit": "bbl",
                "std_curve_description": "Mud Tank 12 Volume",
                "std_mnemonic_displayname": "MUD TANK 12 VOLUME",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tv13",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Pit Volume 13",
                "src_mnemonic": "Pit Volume 13",
                "src_unit": "bbl",
                "std_curve_description": "Mud Tank 13 Volume",
                "std_mnemonic_displayname": "MUD TANK 13 VOLUME",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tv14",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Pit Volume 14",
                "src_mnemonic": "Pit Volume 14",
                "src_unit": "bbl",
                "std_curve_description": "Mud Tank 14 Volume",
                "std_mnemonic_displayname": "MUD TANK 14 VOLUME",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tv2",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Pit Volume 2",
                "src_mnemonic": "TANK2_VOL",
                "src_unit": "bbl",
                "std_curve_description": "Mud Tank 2 Volume",
                "std_mnemonic_displayname": "MUD TANK 2 VOLUME",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tv3",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Pit Volume 3",
                "src_mnemonic": "TANK3_VOL",
                "src_unit": "bbl",
                "std_curve_description": "Mud Tank 3 Volume",
                "std_mnemonic_displayname": "MUD TANK 3 VOLUME",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tv4",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Pit Volume 4",
                "src_mnemonic": "TANK4_VOL",
                "src_unit": "bbl",
                "std_curve_description": "Mud Tank 4 Volume",
                "std_mnemonic_displayname": "MUD TANK 4 VOLUME",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tv5",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Pit Volume 5",
                "src_mnemonic": "TANK5_VOL",
                "src_unit": "bbl",
                "std_curve_description": "Mud Tank 5 Volume",
                "std_mnemonic_displayname": "MUD TANK 5 VOLUME",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tv6",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Pit Volume 6",
                "src_mnemonic": "TANK6_VOL",
                "src_unit": "bbl",
                "std_curve_description": "Mud Tank 6 Volume",
                "std_mnemonic_displayname": "MUD TANK 6 VOLUME",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tv7",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Pit Volume 7",
                "src_mnemonic": "TANK7_VOL",
                "src_unit": "bbl",
                "std_curve_description": "Mud Tank 7 Volume",
                "std_mnemonic_displayname": "MUD TANK 7 VOLUME",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tv8",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Pit Volume 8",
                "src_mnemonic": "TANK8_VOL",
                "src_unit": "bbl",
                "std_curve_description": "Mud Tank 8 Volume",
                "std_mnemonic_displayname": "MUD TANK 8 VOLUME",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tv9",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "Pit Volume 9",
                "src_mnemonic": "TANK9_VOL",
                "src_unit": "bbl",
                "std_curve_description": "Mud Tank 9 Volume",
                "std_mnemonic_displayname": "MUD TANK 9 VOLUME",
                "std_unit": "bbl"
            },
            {
                "well_id": "PNRG_Main_W_29",
                "wellbore_name": "TY RANCH WEST 2JM",
                "log": "depth",
                "std_mnemonic": "tvd - wits",
                "mnemonic_type": "PREFERRED",
                "src_curve_description": "TVD - WITS",
                "src_mnemonic": "TVD - WITS",
                "src_unit": "m",
                "std_curve_description": "TVD-Wits",
                "std_mnemonic_displayname": "TVD - WITS",
                "std_unit": "ft"
            }
        ]
    },
    "state": 6,
    "log_interval": null
}

export default data;