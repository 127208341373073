<template :id="'CtvdTrajectoryTrend' + this.widgetId">
  <div @contextmenu="this.setContextIdWidget">
    <div class="block tool_group" :style="{
      top: this.mouse_y - 25 + 'px',
      left: this.mouse_x + 'px',
    }" :id="'tooltip' + this.widgetId">
      <div v-if="isTvdTooltip" class="actc_tool text-white">
        <p :key="i" v-for="(msg, i) of tvdTooltipValues.split('</br>')">
          {{ msg }}
        </p>
        <!-- {{planTooltipValues | json}} -->
        <!-- <p :key="i" v-for="(msg, i) of planTooltipValues">
          {{ msg }}
        </p> -->
      </div>
    </div>
    <span class="hidden">{{ darkDark }}</span>
    <div :style="{ background: darkDark !== 'white'  ? (gridBackground || 'var(--widgetBg)') : (gridBackground || '#FFFFF7') }"
      :id="'tvdTrajectoryTrend' + this.widgetId"></div>
  </div>
</template>

<script>
import * as d3 from "d3";

var svg = "";
export default {
  name: "VerticalTrendWidget",
  components: {},
  props: {
    openConfig: Function,
    displayId: String,
    widgetHeight: {
      type: Number,
      default: 200,
    },
    widgetWidth: {
      type: Number,
      default: 200,
    },
    gridColor: {
      type: String,
      default: "",
    },

    gridBackground: {
      type: String,
      default: "",
    },
    xAxisLabel: {
      type: String,
      default: "",
    },
    yAxisLabel: {
      type: String,
      default: "",
    },
    xAxisLabelSize: {
      type: Number,
    },
    yAxisLabelSize: {
      type: Number,
    },
    backgroundColor: {
      type: String,
      default: "white",
    },
    widgetId: String,
    isSwitched: {
      type: Boolean,
      default: false,
    },
    actualColor: {
      type: String,
      default: "red",
    },
    planColor: {
      type: String,
      default: "steelblue",
    },
    displayIndex: [String, Number]
  },
  data() {
    return {
      isTvdTooltip: false,
      tvdTooltipValues: "",
      mouse_x: 0,
      mouse_y: 0,
      megaData: [],
      megaData1: [],
      width: this.widgetWidth,
      height: this.widgetHeight,
      graphData: {
        rows: [],
        rowsPlan: [],
        columns: [],
      },
      selectedTags: {
        x: "vsection",
        y: "tvd",
      },
    };
  },
  computed: {
    darkDark() {
      if (this.$store.state.rect.darkmode !== 'white') {

        this.$store.dispatch("rect/lineGraphYAxis", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: "white",
        });
        this.$store.dispatch("rect/lineGraphXAxis", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: "white",
        });

        // valueColor
      } else {
        this.$store.dispatch("rect/lineGraphYAxis", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: "black",
        });
        this.$store.dispatch("rect/lineGraphXAxis", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: "black",
        });
      }
      return this.$store.state.rect.darkmode;
    },
  },
  methods: {
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
      // e.stopPropagation();
    },
    async get3DGraphData(dtls) {
      try {

        this.graphData = this.$store.state.disp.rects[this.displayIndex]?.daysVsDepth;

        this.buildSVG()
      } catch (error) {

      }

    //   await this.set3DGraphData();
    },

    set3DGraphData() {
      this.megaData = this.graphData.rows.map((ele) => {
        return {
          x: ele[this.selectedTags.x],
          y: ele[this.selectedTags.y],
        };
      });

      this.megaData1 = this.graphData.rowsPlan.map((ele) => {
        return {
          x: ele[this.selectedTags.x],
          y: ele[this.selectedTags.y],
        };
      });

      this.megaData = this.megaData.filter((ele) => {
        return (
          (ele.x != null || ele.x != undefined) &&
          (ele.y != null || ele.y != undefined)
        );
      });
      this.megaData1 = this.megaData1.filter((ele) => {
        return (
          (ele.x != null || ele.x != undefined) &&
          (ele.y != null || ele.y != undefined)
        );
      });
      //
      //
    },
    async clearSvgHandler() {
      return new Promise((res, rej) => {
        //
        let gauge = d3.select("#tvdTrajectoryTrend" + this.widgetId);
        gauge.selectAll("*").remove();
        svg = "";
        res();
      });
    },
    async buildSVG() {

      var margin = { top: 10, right: 10, bottom: 50, left: 55 },
        width = this.width - margin.left - margin.right,
        height = this.height - margin.top - margin.bottom;

      var maxX = 0;
      var maxY = 0;
      var minX = 0;
      var minY = 0;

      let labelFontSize = 12;
      let tooltipFontSize = 10;

      //create base svg
      svg = d3.select("#tvdTrajectoryTrend" + this.widgetId);
      if (svg) {
        svg.selectAll("*").remove();
        svg = "";
      } else {
        let gauge = document.createElement("div");
        gauge.setAttribute("id", "tvdTrajectoryTrend" + this.widgetId);
      }
      svg = d3
        .select("#tvdTrajectoryTrend" + this.widgetId)
        .append("svg")
        .attr("width", width + margin.left + margin.right - 20)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

      //structure plane trajectory

      for(let i in this.graphData){

        var planData = this.graphData[i]
        break;
      }
      // var planData = this.megaData1.map(function (d) {
      //   let obj = {
      //     y: parseFloat(d["y"]),
      //     x: parseFloat(d["x"]),
      //     name: "PT",
      //   };
      //   maxX = maxX < obj.x ? obj.x : maxX;
      //   minX = minX < obj.x ? minX : obj.x;
      //   maxY = maxY < obj.y ? obj.y : maxY;
      //   minY = minY < obj.y ? minY : obj.y;
      //   return obj;
      // });
      //structure current trajectory
      var actualData = this.megaData.map(function (d) {
        let obj = {
          y: parseFloat(d["y"]),
          x: parseFloat(d["x"]),
          name: "CT",
        };
        maxX = maxX < obj.x ? obj.x : maxX;
        minX = minX < obj.x ? minX : obj.x;
        maxY = maxY < obj.y ? obj.y : maxY;
        minY = minY < obj.y ? minY : obj.y;
        return obj;
      });

      //create axis
      var x = d3
        .scaleLinear()
        .domain([planData.min_max.time.min, planData.min_max.time.max + 50])
        .range([0, width]);
      var y = d3
        .scaleLinear()
        .domain([planData.min_max.dmea.max, planData.min_max.dmea.min + 150])
        .range([height, 0]);
      const xAxis = d3.axisBottom(x).ticks();
      const yAxis = d3.axisLeft(y).ticks();

      //create grid axis
      const xAxisGrid = d3
        .axisBottom(x)
        .tickSize(-height)
        .tickFormat("")
        .ticks();
      const yAxisGrid = d3.axisLeft(y).tickSize(-width).tickFormat("").ticks();

      // Create grids.
      svg
        .append("g")
        .attr("class", "x axis-grid")
        .attr("transform", "translate(0," + height + ")")
        .style("color", this.gridColor)
        .style("fill", this.gridBackground)
        .call(xAxisGrid);

      svg
        .append("g")
        .attr("class", "y axis-grid")
        .style("color", this.gridColor)
        .call(yAxisGrid);

      // Create axes.
      svg
        .append("g")
        .attr("class", "xAxis")
        .attr("transform", "translate(0," + height + ")")
        .style("font-size", this.xAxisLabelSize)
        .call(xAxis);
      svg.selectAll(".xAxis").selectAll("text").attr("fill", this.yAxisLabel);
      svg
        .append("g")
        .attr("class", "yAxis")
        .style("font-size", this.yAxisLabelSize)
        .call(yAxis);
      svg.selectAll(".yAxis").selectAll("text").attr("fill", this.yAxisLabel);

      svg
        .append("rect")
        .attr("class", "x label")
        .attr("text-anchor", "end")
        // .style("Lato', sans-serif")
        .attr("x", width - 80)
        .attr("y", height + 22)
        .attr("width", 10)
        .attr("height", 10)
        .style("fill", this.actualColor);
      svg
        .append("text")
        .attr("class", "x label")
        .attr("text-anchor", "end")
        // .style("font-family", "'Lato', sans-serif")
        .attr("x", width - 20)
        .attr("y", height + 30)
        .style("fill", this.xAxisLabel)
        .style("font-size", labelFontSize)
        .text("Actual");

      svg
        .append("rect")
        .attr("class", "x label")
        .attr("text-anchor", "end")
        // .style("font-family", "'Lato', sans-serif")
        .attr("x", width - 80)
        .attr("y", height + 38)
        .attr("width", 10)
        .attr("height", 10)
        .style("fill", this.planColor);
      svg
        .append("text")
        .attr("class", "x label")
        .attr("text-anchor", "end")
        // .style("font-family", "'Lato', sans-serif")
        .attr("x", width - 20)
        .attr("y", height + 48)
        .style("fill", this.xAxisLabel)
        .style("font-size", labelFontSize)
        .text("Plan");

      svg
        .append("text")
        .attr("class", "x label")
        .attr("text-anchor", "end")
        .attr("x", width / 2)
        .attr("y", height + 40)
        .style("fill", this.xAxisLabel)
        .style("font-size", labelFontSize)
        .text("DAYS");

      svg
        .append("text")
        .attr("class", "y label")
        .attr("text-anchor", "end")
        // .attr("x", height/2)
        .attr("dy", "-3.5em")
        .attr("dx", -height / 2)
        .attr("transform", "rotate(-90)")
        .style("fill", this.xAxisLabel)
        .style("font-size", labelFontSize)
        .text("DEPTH");

      let realTimeTooltip = (event, data, isShow) => {
        //
        this.mouse_x = event.clientX;
        this.mouse_y = event.clientY;
        let text = '';
        text = `${this.selectedTags.x.toUpperCase()} : ${data[0]['x'].toFixed(2)} </br>${this.selectedTags.y.toUpperCase()} : ${data[0]['y'].toFixed(2)} </br>`;

        this.tvdTooltipValues = text;
        //
        this.isTvdTooltip = isShow;

      };
      let hideRealTimeTooltip = (event, text, isShow) => {
        if (this.tooltipTimeOut) {
          clearTimeout(this.tooltipTimeOut);
        }
        this.tooltipTimeOut = setTimeout(() => {
          this.mouse_x = 0;
          this.mouse_y = 0;
          this.tvdTooltipValues = '';
          this.isTvdTooltip = isShow;
        }, 1000);
      };

      //create planned line chart
      let another_line = (params)=> {
            return d3.line()
              .x(function (d, i) {
                return planData['time'][i] ? x(planData['time'][i]) : x(0);
              })
              .y(function (d, i) {
                return y(planData['depth'][i]);
              })
              (Array(planData['time'].length));
      }
      svg
        .append("path")
        // .datum(planData)
        .attr("fill", "none")
        .attr("stroke", this.planColor)
        .attr("stroke-width", 2)
        .style("stroke-dasharray", "3, 3")
        .attr(
          "d",
           another_line()
        ).on('mouseover', function (e) {
          let data = d3.select(this).data()[0];
          var bisectDate = d3.bisector(function (d) {
            return d.y;
          }).left; // **
          let x0 = y.invert(d3.pointer(e)[1]);

          let i = bisectDate(data, x0, 1);
          let d0 = data[i - 1];
          let d1 = data[i];
          let d = d1;
          //
          realTimeTooltip(e, [d0], true);
        })
        .on('mousemove', function (e) {
          let data = d3.select(this).data()[0];

          var bisectDate = d3.bisector(function (d) {
            return d.y;
          }).left; // **
          let x0 = y.invert(d3.pointer(e)[1]);

          let i = bisectDate(data, x0, 1);
          let d0 = data[i - 1];
          let d1 = data[i];
          let d = d1;
          //
          realTimeTooltip(e, [d0], true);
        })
        .on('mouseout', function (e) {
          hideRealTimeTooltip(e, '', false);
        });

      //create actual line chart
      svg
        .append("path")
        .datum(actualData)
        .attr("fill", "none")
        .attr("stroke", this.actualColor)
        .attr("stroke-width", 2)
        .attr(
          "d",
          d3
            .line()
            .x(function (d) {
              return x(d.x);
            })
            .y(function (d) {
              return y(d.y);
            })
        ).on('mouseover', function (e) {
          let data = d3.select(this).data()[0];
          var bisectDate = d3.bisector(function (d) {
            return d.y;
          }).left; // **
          let x0 = y.invert(d3.pointer(e)[1]);

          let i = bisectDate(data, x0, 1);
          let d0 = data[i - 1];
          let d1 = data[i];
          let d = d1;
          //
          realTimeTooltip(e, [d0], true);
        })
        .on('mousemove', function (e) {
          let data = d3.select(this).data()[0];

          var bisectDate = d3.bisector(function (d) {
            return d.y;
          }).left; // **
          let x0 = y.invert(d3.pointer(e)[1]);

          let i = bisectDate(data, x0, 1);
          let d0 = data[i - 1];
          let d1 = data[i];
          let d = d1;
          //
          realTimeTooltip(e, [d0], true);
        })
        .on('mouseout', function (e) {
          hideRealTimeTooltip(e, '', false);
        });

      // Set the gradient
      svg
        .append("linearGradient")
        .attr("id", "line-gradient")
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", 0)
        .attr("y1", y(0))
        .attr("x2", 0)
        .attr("y2", y(maxY))
        .selectAll("stop")
        .data([
          { offset: "0%", color: "blue" },
          { offset: "10%", color: "green" },
          { offset: "40%", color: "brown" },
          { offset: "70%", color: this.planColor },
          { offset: "100%", color: this.actualColor },
        ])
        .enter()
        .append("stop")
        .attr("offset", function (d) {
          return d.offset;
        })
        .attr("stop-color", function (d) {
          return d.color;
        });
    },
    dark() {
      this.$emit("dark");
    },

    light() {
      this.$emit("light");
    },
  },
  watch: {
    actualColor: async function (newVal, oldVal) {
      this.buildSVG();
    },
    planColor: async function (newVal, oldVal) {
      this.buildSVG();
    },
    isSwitched: async function (newVal, oldVal) {
      try {
        let prevTags = { ...this.selectedTags };
        this.selectedTags.x = prevTags.y;
        this.selectedTags.y = prevTags.x;
        this.set3DGraphData();
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    selectedTags: {
      handler(val) {
        this.set3DGraphData();
        this.buildSVG();
      },
      deep: true,
    },
    gridColor: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    gridBackground: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    xAxisLabel: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    yAxisLabel: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    xAxisLabelSize: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    yAxisLabelSize: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    widgetWidth: async function (newVal, oldVal) {
      try {
        // await this.clearSvgHandler();
        this.width = newVal;
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    widgetHeight: async function (newVal, oldVal) {
      try {
        // await this.clearSvgHandler();
        this.height = newVal;
        this.buildSVG();
      } catch (error) {
        //
      }
    },
  },
  async mounted() {
    let dtls = this.$store.state.disp.displays[this.displayId];
    if (dtls) {
      this.wellId = dtls.wellId;
      this.wellboreId = dtls.wellboreId;
      this.logId = dtls.trajectoryLogId;
      this.wellboreState = dtls.wellboreState;
      this.logType = dtls.logType;
      this.table = dtls.table;
    }
    this.$store.subscribe(async (mutation, state) => {
      if (mutation.type == "rect/LINE_GRAPH_MNEMONIC_CHANGE") {
        let rects = this.$store.state.rect.rects;
        if (typeof rects != "undefined" && rects.length > 0) {
          for (let i = 0; i < rects.length; i++) {
            if (
              rects[i].widgetId == this.widgetId &&
              rects[i].displayId == this.displayId
            ) {
              this.selectedTags = {
                x: this.$store.state.rect.rects[i].mnemonicTag1 || "dispew",
                y: this.$store.state.rect.rects[i].mnemonicTag2 || "tvd",
              };
            }
          }
        }
      }

      if (mutation.type == "disp/daysVsDepthToggle") {
        if (mutation.payload.displayId == this.displayId && mutation.payload.value) {

          await this.get3DGraphData(mutation.payload);
        //   this.buildSVG();
        }
      }
    });

    // await this.get3DGraphData(dtls);
    // this.buildSVG();
  },
};
</script>

<style scoped>
.overlay {
  fill: none;
  pointer-events: all;
}

.focus circle {
  fill: rgb(0, 0, 0);
}

.focus text {
  font-size: 14px;
}

.tooltip {
  fill: white;
  stroke: rgb(255, 249, 249);
}

.tooltip-date,
.tooltip-likes {
  font-weight: bold;
}

.tool_group {
  position: fixed;
  display: inline-grid;
  /* left: 221px; */
  right: 162px;
  z-index: 99999;
}

.actc_tool {
  font-size: 10px;
  background: #000000;
  width: auto;
  position: absolute;
  padding: 2px 5px;
  /* border-radius: 20px; */
}
</style>