import { ref,reactive } from "vue";

const tooltipData = ref(null);
const tooltipShow = ref(-1);
const isZoomClicked = ref({value:false,ctx:0});

export function useWellcorelationTrend() {
  function setTooltip(val) {
    tooltipData.value=val;
  }
  function hideTooltip(val) {
    tooltipShow.value=val;
  }
  function setZoomClicked(val) {
    isZoomClicked.value.value = val;
    isZoomClicked.value.ctx = Math.random();
  }
  return {
    setTooltip,
    tooltipData,
    hideTooltip,
    tooltipShow,
    isZoomClicked,
    setZoomClicked
  };
}
